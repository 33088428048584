import './Modal.css'
import React, { useState, ReactNode } from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'

interface ModalProps {
    children: ReactNode
    onClick?: () => void
    presentInModal?: boolean
    zIndex?: number
}

const Modal = (props: ModalProps) => {

    let { children, onClick, presentInModal = true, zIndex = 5 } = props

    let className = presentInModal ? `model-content-card ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}` : 'model-content'
    return (
        <div
            className='modal-backdrop'
            style={{ zIndex: zIndex }}
            onClick={onClick}>
            <div className={`${className}`}
                onClick={(event) => {
                    event.stopPropagation()
                }}>
                {children}
            </div>
        </div>
    )
}

export default Modal
