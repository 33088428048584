import './LucidInventory.css'
import LucidInventoryTable from '../Tables/LucidInventoryTable'
import React, { useRef, useEffect, useState } from 'react'
import SlidingPanel from 'react-sliding-side-panel'
import Table, { TableSortOrder, useResize, TableRenderers } from '../Tables/Table'
import { FaRedoAlt } from 'react-icons/fa'
import { LucidSurveyTemplateProxy } from '../../../sharedModels/LucidSurveyTemplateProxy'
import { LucidSurveyTemplateState, LucidSurveyTemplateQualQuestion } from '../../../sharedModels/LucidSurveyTemplateProxy'
import { Spinner, InputGroup, FormControl, Button, Modal, Toast, Row, Col } from 'react-bootstrap'
import { useLucidInventoryHook } from './Hook'

interface LucidLiveInventoryProps {
}

export interface LucidLiveInventoryState {
    isLoading: boolean
    lucidSurveyTemplates: LucidSurveyTemplateProxy[],
    englishQualifications?: LucidSurveyTemplateQualQuestion[]
}

export let LucidLiveInventory: React.FC<LucidLiveInventoryProps> = (props) => {
    let { state, reloadCount, setReloadCount, setLaunchSurvey, setExpireSurvey, englishQualificationsForSurvey, setEnglishQualificationsForSurvey } = useLucidInventoryHook(LucidSurveyTemplateState.Live)
    let { isLoading, lucidSurveyTemplates, englishQualifications } = state
    const componentRef = useRef(null)
    const { width, height } = useResize(componentRef)

    let [query, setQuery] = useState('')
    let [filter, setSetFilter] = useState('')
    let [filteredLucidSurveys, setFilteredLucidSurveys] = useState<LucidSurveyTemplateProxy[]>(lucidSurveyTemplates)

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setSetFilter(query)
        }, 150)
        return () => clearTimeout(timeOutId)
    }, [query])


    useEffect(() => {

        if (filter === '') {
            setFilteredLucidSurveys(lucidSurveyTemplates)
            return
        }
        let renderedRows = lucidSurveyTemplates.filter(lucidSurvey => {
            let stringify = JSON.stringify(lucidSurvey).toLocaleLowerCase()
            if (stringify.includes(filter.toLocaleLowerCase())) {
                return true
            }
            return false
        })

        setFilteredLucidSurveys(renderedRows)
    }, [filter, lucidSurveyTemplates])

    if (isLoading) {
        return (
            <div className='lucid-inventory-container' ref={componentRef}>
                <Spinner animation='border' variant='primary' />
            </div>
        )
    }

    return (
        <div className='lucid-inventory-container'>

            <div className='lucid-inventory-search'>
                <InputGroup className='mb-3' size='lg'>
                    <Button className='lucid-inventory-reload' onClick={() => {
                        setReloadCount(reloadCount + 1)
                    }}><FaRedoAlt /></Button>
                    <FormControl
                        value={query}
                        placeholder='Search...'
                        aria-describedby='basic-addon2'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setQuery(event.target.value)
                        }}
                    />
                </InputGroup>
            </div>
            <div className='lucid-table-search' ref={componentRef}>
                <LucidInventoryTable lucidSurveyTemplates={filteredLucidSurveys} setLaunchSurvey={setLaunchSurvey} setExpireSurvey={setExpireSurvey} setEnglishQualificationsForSurvey={setEnglishQualificationsForSurvey} width={width} height={height} />
            </div>
            <SlidingPanel
                type={'left'}
                isOpen={englishQualifications !== undefined}
                size={40}
                noBackdrop={true}
            >
                <div className='lucid-table-english-quals-container'>
                    <p className='lucid-table-english-quals-title'>Survey Number: {englishQualificationsForSurvey}</p>
                    {(englishQualifications ? englishQualifications : []).map(englishQualification => {
                        return <div className='lucid-table-english-quals'>
                            <p className='lucid-table-english-quals-question'>{englishQualification.questionText} ({englishQualification.questionId})</p>
                            {Object.keys(englishQualification.qualifyingResponsesMap).sort((a, b) => {
                                return parseInt(a) - parseInt(b)
                            }).map(function (key) {
                                return <p className='lucid-table-english-quals-precode'>{englishQualification.qualifyingResponsesMap[key]} ({key})</p>
                            })}
                        </div>
                    })}
                    <Button onClick={() => {
                        setEnglishQualificationsForSurvey(0)
                    }} variant='success'>Close</Button>
                </div>
            </SlidingPanel>
        </div >
    )
}

export default LucidLiveInventory
