import './AdminDashboard.css'
import AdminSideBar from './AdminSideBar'
import Cashouts from '../Cashouts/Cashouts'
import Completes from '../Completes/Completes'
import Fraud from '../Fraud/Fraud'
import LucidLiveInventory from '../LucidInventory/LucidLiveInventory'
import LucidTestingInventory from '../LucidInventory/LucidTestingInventory'
import LucidSweepstakeSurvey from '../LucidSweepstake/LucidSweepstakeSurvey'
import React from 'react'
import Terminates from '../Terminates/Terminates'
import UserExits from '../UserExits/UserExits'
import UserLookup from '../UserLookup/UserLookup'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { useSession } from '../../../Authentication/Hook'

interface AdminDashboardProps extends RouteComponentProps { }

interface AdminDashboardState { }

type Props = AdminDashboardProps

export let AdminDashboard: React.FC<AdminDashboardProps> = (props) => {
    let { location } = props
    
    const { eurekaUser, firebaseAuthUser, eurekaUserInitializing, firebaseAuthUserInitializing } = useSession()
    if (eurekaUserInitializing || firebaseAuthUserInitializing) {
        return (
            <div>...</div>
        )
    } else if (eurekaUser === undefined) {
        console.log('Redirect to /login')
        return <Redirect to='/login' />
    } else if (eurekaUser.isAdmin === false) {
        console.log('Redirect to /login')
        return <Redirect to='/surveys' />
    }

    let showAllTools = false
    if (eurekaUser !== undefined) {
        showAllTools = eurekaUser.userId != '6NagTuezjEWrWHR4hkc7ya2K1lW2'
    }

    return (
        <div className='admin-dashboard-container'>
            <div className='admin-dashboard-side-pane'>
                <AdminSideBar showAllTools={showAllTools} />
            </div>
            <div className='admin-dashboard-side-conent'>
                <Switch>
                    <Route path='/admin/cashouts/' render={() => <Cashouts />} />
                    <Route path='/admin/completes/' render={() => <Completes />} />
                    <Route path='/admin/terminates/' render={() => <Terminates />} />
                    <Route path='/admin/fraud/' render={() => <Fraud />} />
                    <Route path='/admin/user-exits/' render={() => <UserExits />} />
                    <Route path='/admin/lucid-inventory-testing/' render={() => <LucidTestingInventory />} />
                    <Route path='/admin/lucid-inventory-live/' render={() => <LucidLiveInventory />} />
                    <Route path='/admin/lucid-surveys/' render={() => <LucidSweepstakeSurvey />} />
                    <Route path='/admin/completes/' render={() => <Completes />} />
                    <Route path='/admin' render={() => <UserLookup path={location.search} />} />
                    <Route path='*' render={() => <Redirect to='/admin' />} />
                </Switch>
            </div>
        </div>
    )
}
