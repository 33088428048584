import { CashoutRequestProxy } from '../../../sharedModels/CashoutRequestsProxy'

// Define all actions that can occur on the UserLookup Component
export const CASHOUTS_REQUEST = 'CASHOUTS_REQUEST'
export const CASHOUTS_SNAPSHOT_UPDATE = 'CASHOUTS_SNAPSHOT_UPDATE'
export const CASHOUTS_SEND_PAYMENT = 'CASHOUTS_SEND_PAYMENT'
export const CASHOUTS_MARK_AS_SKIP = 'CASHOUTS_MARK_AS_SKIP'
export const CASHOUTS_MARK_AS_MANUAL = 'CASHOUTS_MARK_AS_MANUAL'

export interface CashoutsRequest {
    type: typeof CASHOUTS_REQUEST,
}
export interface CashoutsSnapshotUpdate {
    type: typeof CASHOUTS_SNAPSHOT_UPDATE,
    cashouts: CashoutRequestProxy[]
}

export interface CashoutsSendPayment {
    type: typeof CASHOUTS_SEND_PAYMENT,
}

export interface CashoutsSendMarkAsSkip {
    type: typeof CASHOUTS_MARK_AS_SKIP,
}

export interface CashoutsSendMarkAsManual {
    type: typeof CASHOUTS_MARK_AS_MANUAL,
}

export type CashoutsActions = CashoutsRequest | CashoutsSnapshotUpdate | CashoutsSendPayment | CashoutsSendMarkAsSkip | CashoutsSendMarkAsManual