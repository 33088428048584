import './UserProfile.css'
import React from 'react'
import moment from 'moment'
import { Button, ButtonGroup } from 'react-bootstrap'
import { UserProxy } from '../../../sharedModels/UserProxy'
import firebase from 'firebase'
const Timestamp = firebase.firestore.Timestamp

interface UserProfileProps {
    user?: UserProxy,
    setAmountCents: React.Dispatch<React.SetStateAction<number>>
}

const UserProfile: React.FC<UserProfileProps> = (props) => {

    let { user, setAmountCents } = props

    if (!user) {
        return (<div>Please search</div>)
    }
    let cellProps = mapUserToCellProps(user)
    return (
        <div>
            {cellProps.map((cellProp, idx) => {
                return <UserProfileCell key={idx} attribute={cellProp.attribute} value={cellProp.value} isBreak={cellProp.isBreak} />
            })}
            <div className='userprofile-break'></div>
            <p className='userprofile-attribute-text'>Send user cash</p>
            <ButtonGroup>
                <Button variant='success' onClick={() => setAmountCents(10)}>10¢</Button>
                <Button variant='success' onClick={() => setAmountCents(20)}>20¢</Button>
                <Button variant='success' onClick={() => setAmountCents(25)}>25¢</Button>
                <Button variant='success' onClick={() => setAmountCents(50)}>50¢</Button>
                <Button variant='success' onClick={() => setAmountCents(100)}>$1</Button>
            </ButtonGroup>
        </div >
    )
}

const mapUserToCellProps = (user: UserProxy): UserProfileCellProps[] => {
    let { lockedTimestamp } = user
    let lockedTime = (lockedTimestamp === undefined || lockedTimestamp <= Timestamp.now().seconds) ? 'unlocked' : moment.unix(lockedTimestamp).fromNow()
    return [
        {
            attribute: 'UserId',
            value: user.userId,
            isBreak: false
        },
        {
            attribute: 'Email',
            value: user.email,
            isBreak: false
        },
        {
            attribute: '',
            value: ' ',
            isBreak: true
        },
        // Location
        {
            attribute: 'Device Location',
            value: user.deviceLocationCountry || '?',
            isBreak: false
        },
        {
            attribute: 'IP Location',
            value: user.ipCountry || '?',
            isBreak: false
        },
        {
            attribute: 'Fraud Score (User banned if above 600)',
            value: `${user.fraudScore}`,
            isBreak: false
        },
        {
            attribute: 'Cool Down Score (Locked if above 100. Resets daily.)',
            value: `${user.cooldownScore}`,
            isBreak: false
        },
        {
            attribute: 'Locked until',
            value: `${lockedTime}`,
            isBreak: false
        },
        {
            attribute: 'Account created',
            value: user.creationDate || user.createdHumanReadableDate,
            isBreak: false
        },
        {
            attribute: 'Client Version',
            value: user.clientVersion,
            isBreak: false
        },
        {
            attribute: 'Sleek Nux',
            value: `${user.sleekNux}`,
            isBreak: false
        },
        {
            attribute: '',
            value: ' ',
            isBreak: true
        },
        // Earnings
        {
            attribute: 'Current Earnings',
            value: `$${(user.currentEarningsCents / 100).toFixed(2)}`,
            isBreak: false
        },
        {
            attribute: 'Lifetime Earnings',
            value: `$${(user.lifetimeEarningsCents / 100).toFixed(2)}`,
            isBreak: false
        },
        {
            attribute: 'Revenue Generated',
            value: `$${((user.grlLifetimeRevenueCents + user.lucidLifetimeRevenueCents) / 100).toFixed(2)}`,
            isBreak: false
        },
        {
            attribute: 'Sweepstake Surveys Complete',
            value: `${user.grlSurveyCompletes + user.lucidSurveyCompletes}`,
            isBreak: false
        },
        {
            attribute: 'Sweepstake Surveys Attempts',
            value: `${user.grlSurveyAttempts + user.lucidSurveyRequests}`,
            isBreak: false
        },
        {
            attribute: 'Sweepstake Surveys Completion Rate',
            value: `${(100.0 * (user.grlSurveyCompletes + user.lucidSurveyCompletes) / (user.grlSurveyAttempts + user.lucidSurveyRequests)).toFixed(1)}%`,
            isBreak: false
        }
    ]
}
export default UserProfile


interface UserProfileCellProps {
    attribute: string,
    value: string,
    isBreak: boolean
}
const UserProfileCell: React.FC<UserProfileCellProps> = (props) => {

    let { attribute, value, isBreak } = props

    return (
        <div>
            <p className='userprofile-attribute-text'>{attribute}</p>
            <p className='userprofile-attribute-value-text'>{value}</p>
            {isBreak ? <div className='userprofile-break'></div> : null}
        </div >
    )
}