import './AdminSideBar.css'
import React from 'react'
import firebase from 'firebase'
import { Button } from 'react-bootstrap'
import { FaSearch, FaMoneyBillWave, FaMoneyCheckAlt, FaSkull, FaTimes, FaServer, FaBalanceScaleLeft, FaArrowLeft, FaLaptop, FaCrosshairs } from 'react-icons/fa'
import { Link } from 'react-router-dom'

interface AdminSideBarProps {
    showAllTools: boolean
}

function logout() {
    firebase.auth().signOut()
}

const AdminSideBar = (props: AdminSideBarProps) => {

    let { showAllTools } = props
    return (
        <div className='admin-sidebar-container'>
            <div className='admin-sidebar-link-container'><FaSearch /><Link className='admin-sidebar-link' to='/admin/'>User Lookup</Link></div>
            {showAllTools && <div className='admin-sidebar-link-container'><FaMoneyBillWave /><Link className='admin-sidebar-link' to='/admin/cashouts/'>Cashouts</Link></div>}
            {showAllTools && <div className='admin-sidebar-link-container'><FaMoneyCheckAlt /><Link className='admin-sidebar-link' to='/admin/completes/'>Completes</Link></div>}
            {showAllTools && <div className='admin-sidebar-link-container'><FaTimes /><Link className='admin-sidebar-link' to='/admin/terminates/'>Terminates</Link></div>}
            {<div className='admin-sidebar-link-container'><FaSkull /><Link className='admin-sidebar-link' to='/admin/fraud/'>Fraud</Link></div>}
            {<div className='admin-sidebar-link-container'><FaArrowLeft /><Link className='admin-sidebar-link' to='/admin/user-exits/'>User Exits</Link></div>}
            {showAllTools && <div className='admin-sidebar-link-container'><FaBalanceScaleLeft /><Link className='admin-sidebar-link' to='/admin/lucid-inventory-testing/'>Lucid Testing</Link></div>}
            {showAllTools && <div className='admin-sidebar-link-container'><FaServer /><Link className='admin-sidebar-link' to='/admin/lucid-inventory-live/'>Lucid Live</Link></div>}
            {showAllTools && <div className='admin-sidebar-link-container'><FaCrosshairs /><Link className='admin-sidebar-link' to='/admin/lucid-surveys/'>Lucid Surveys</Link></div>}
            {showAllTools && <div className='admin-sidebar-link-container'><FaLaptop /><Link className='admin-sidebar-link' to='/surveys'>Surveys</Link></div>}
            <div className='admin-sidebar-filler'></div>
            <Button onClick={logout} variant='secondary'>Sign Out</Button>
            <p className='admin-sidebar-version'>v0.0.5</p>
        </div >
    )
}

export default AdminSideBar