import './Tables.css'
import React, { useState } from 'react'
import Table, { TableRendererTypes, TableSortOrder, TableRenderers } from './Table'
import moment from 'moment'
import { Button, Spinner } from 'react-bootstrap'
import { CASHOUTS_SEND_PAYMENT, CASHOUTS_MARK_AS_MANUAL, CASHOUTS_REQUEST, CASHOUTS_MARK_AS_SKIP } from '../Cashouts/Actions'
import { CashoutRequestProxy, PayPalStatus } from '../../../sharedModels/CashoutRequestsProxy'
import { Column } from 'react-base-table'

interface CashoutsTableProps {
    cashouts: CashoutRequestProxy[],
    width: number,
    height: number
    setShouldSendFirestoreId?: React.Dispatch<React.SetStateAction<string>>,
    setShouldManualFirestoreId?: React.Dispatch<React.SetStateAction<string>>,
    setShouldSkipFirestoreId?: React.Dispatch<React.SetStateAction<string>>,
    allowButtons: boolean
}

interface LoadingButton {
    buttonTitle: string
    firestoreId: string
}

const CashoutsTable: React.FC<CashoutsTableProps> = (props) => {

    let { cashouts, width, height, allowButtons } = props
    let [loadingButtons, setLoadingButtons] = useState<LoadingButton[]>([])

    if (cashouts === undefined || cashouts.length === 0) {
        return <div>No cashouts</div>
    }

    const setLoadingButtonsFunction = (newButtons: LoadingButton[]) => {
        setLoadingButtons(newButtons)
    }

    let coluns = generateColumns(props, loadingButtons, setLoadingButtonsFunction, allowButtons)
    return (
        <div>
            <Table rows={cashouts} width={width} height={height} columns={coluns} data={generateData(cashouts)} />
        </div>
    )
}

const generateData = (cashouts: CashoutRequestProxy[]): Column[] => {
    return cashouts.map((complete, idx) => {
        return {
            ...complete,
            index: idx + 1,
            id: complete.firestoreId,
            parentId: null,
            width: 150,
        }
    })
}

const generateColumns = (props: CashoutsTableProps, loadingButtons: LoadingButton[], setLoadingButtonsFunction: (newButtons: LoadingButton[]) => void, allowButtons: boolean): Column[] => {
    let columns: Column[] = [
        {
            key: 'index',
            dataKey: 'index',
            title: 'Idx',
            width: 75,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let { index } = rowData
                return TableRenderers.default(index)
            })
        },
        {
            key: 'createdTimestamp',
            dataKey: 'createdTimestamp',
            title: 'Created',
            width: 140,
            sortable: true,
            order: TableSortOrder.Descending,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { createdTimestamp } = cashout
                return TableRenderers.relativeDateRender(createdTimestamp)
            })
        },
        {
            key: 'userId',
            dataKey: 'userId',
            title: 'UserId',
            width: 250,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { userId } = cashout
                return TableRenderers.userIdRender(userId)
            })
        },
        {
            key: 'currentEarningsCents',
            dataKey: 'currentEarningsCents',
            title: 'User Earnings',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { currentEarningsCents } = cashout
                return TableRenderers.centsToDollarRender(currentEarningsCents)
            })
        },
        {
            key: 'currentRevenueCents',
            dataKey: 'currentRevenueCents',
            title: 'Generated Revenue',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { currentRevenueCents } = cashout
                return TableRenderers.centsToDollarRender(currentRevenueCents)
            })
        },
        {
            key: 'profitMargin',
            dataKey: 'currentRevenueCents.currentEarningsCents',
            title: 'Margin',
            width: 80,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { currentRevenueCents, currentEarningsCents } = cashout
                return TableRenderers.profitMarginRender(currentRevenueCents, currentEarningsCents)
            })
        },
        {
            key: 'lifetimeRevenueCents',
            dataKey: 'lifetimeRevenueCents',
            title: 'Lifetime Generated Revenue',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { lifetimeRevenueCents } = cashout
                return TableRenderers.centsToDollarRender(lifetimeRevenueCents)
            })
        },
        {
            key: 'sweepstakesComplete',
            dataKey: 'sweepstakesComplete',
            title: 'Sweepstakes Complete',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { grlSurveyCompletes, lucidSurveyCompletes } = cashout
                let totalComletes = grlSurveyCompletes + lucidSurveyCompletes
                return TableRenderers.default(`${totalComletes}`)
            })
        },
        {
            key: 'sweepstakesAttempted',
            dataKey: 'sweepstakesAttempted',
            title: 'Sweepstakes Attempted',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { grlSurveyAttempts, lucidSurveyRequests } = cashout
                let totalAttempts = grlSurveyAttempts + lucidSurveyRequests
                return TableRenderers.default(`${totalAttempts}`)
            })
        },
        {
            key: 'completionRate',
            dataKey: 'completionRate',
            title: 'Completion Rate',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { grlSurveyCompletes, lucidSurveyCompletes, grlSurveyAttempts, lucidSurveyRequests } = cashout
                let totalComletes = grlSurveyCompletes + lucidSurveyCompletes
                let totalAttempts = grlSurveyAttempts + lucidSurveyRequests
                let completionRate = `${(100 * (totalComletes / totalAttempts)).toFixed(1)}%`
                return TableRenderers.default(completionRate)
            })
        },
        {
            key: 'userCreatedHumanReadableDate',
            dataKey: 'userCreatedHumanReadableDate',
            title: 'User Created',
            width: 150,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { userCreatedHumanReadableDate } = cashout
                let date = new Date(userCreatedHumanReadableDate)
                let relative = moment(date).fromNow()
                return TableRenderers.default(relative)
            })
        },
        {
            key: 'paypal_batch_status',
            dataKey: 'paypal_batch_status',
            title: 'Status',
            width: 120,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { paypal_batch_status } = cashout
                let className = ''
                if (paypal_batch_status === PayPalStatus.Success || paypal_batch_status === PayPalStatus.Skipped || paypal_batch_status === PayPalStatus.Manual) {
                    className = 'tables-green-font'
                } else if (paypal_batch_status === PayPalStatus.Uninitiated) {
                    className = 'tables-black-font'
                } else {
                    className = 'tables-red-font'
                }
                return <p className={className}>{paypal_batch_status}</p>
            })
        },
        {
            key: 'location',
            dataKey: 'deviceLocationCountry',
            title: 'Location',
            width: 170,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { deviceLocationCountry, ipCountry } = cashout
                return TableRenderers.default(`${deviceLocationCountry} (${ipCountry})`)
            })
        },
        {
            key: 'email',
            dataKey: 'email',
            title: 'Email',
            width: 250,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { email } = cashout
                return TableRenderers.default(email)
            })
        }
    ]
    let buttons = [
        {
            key: 'sendPayment',
            dataKey: 'sendPayment',
            title: 'Send',
            width: 100,
            frozen: Column.FrozenDirection.RIGHT,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { paypal_batch_status, firestoreId } = cashout

                let isLoading = loadingButtons.filter((item: LoadingButton) => {
                    return item.firestoreId === firestoreId && item.buttonTitle === CASHOUTS_SEND_PAYMENT
                }).length === 1

                if (paypal_batch_status !== PayPalStatus.Uninitiated) {
                    return <Button variant='success' disabled>Sent!</Button>
                } else if (isLoading) {
                    return <Button variant='success' disabled>
                        <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                        /></Button>
                } else if (paypal_batch_status === PayPalStatus.Uninitiated) {
                    return <Button onClick={() => {
                        let { setShouldSendFirestoreId } = props
                        console.log('Sending!')
                        loadingButtons.push({
                            firestoreId: firestoreId,
                            buttonTitle: CASHOUTS_SEND_PAYMENT
                        })
                        let newButtons = loadingButtons
                        setLoadingButtonsFunction(newButtons)
                        setShouldSendFirestoreId!(firestoreId)
                    }} variant='success'>Send</Button>
                }
            })
        },
        {
            key: 'skip',
            dataKey: 'skip',
            title: 'Skip',
            width: 100,
            frozen: Column.FrozenDirection.RIGHT,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { paypal_batch_status, firestoreId } = cashout
                let isLoading = loadingButtons.filter((item: LoadingButton) => {
                    return item.firestoreId === firestoreId && item.buttonTitle === CASHOUTS_MARK_AS_SKIP
                }).length === 1

                if (paypal_batch_status !== PayPalStatus.Uninitiated) {
                    return <Button variant='warning' disabled>Skipped!</Button>
                } else if (isLoading) {
                    return <Button variant='warning' disabled>
                        <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                        /></Button>
                } else if (paypal_batch_status === PayPalStatus.Uninitiated) {
                    return <Button onClick={() => {
                        let { setShouldSkipFirestoreId } = props
                        console.log('Skipping!')
                        loadingButtons.push({
                            firestoreId: firestoreId,
                            buttonTitle: CASHOUTS_MARK_AS_SKIP
                        })
                        setLoadingButtonsFunction(loadingButtons)
                        setShouldSkipFirestoreId!(firestoreId)
                    }} variant='warning'>Skip</Button>
                }
            })
        },
        {
            key: 'manual',
            dataKey: 'manual',
            title: 'Manual',
            width: 100,
            frozen: Column.FrozenDirection.RIGHT,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: CashoutRequestProxy = rowData
                let { paypal_batch_status, firestoreId } = cashout
                let isLoading = loadingButtons.filter((item: LoadingButton) => {
                    return item.firestoreId === firestoreId && item.buttonTitle === CASHOUTS_MARK_AS_MANUAL
                }).length === 1

                if (paypal_batch_status !== PayPalStatus.Uninitiated) {
                    return <Button variant='secondary' disabled>Manual</Button>
                } else if (isLoading) {
                    return <Button variant='secondary' disabled>
                        <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                        /></Button>
                } else if (paypal_batch_status === PayPalStatus.Uninitiated) {
                    return <Button onClick={() => {
                        let { setShouldManualFirestoreId } = props
                        console.log('Manual!')
                        loadingButtons.push({
                            firestoreId: firestoreId,
                            buttonTitle: CASHOUTS_MARK_AS_MANUAL
                        })
                        setLoadingButtonsFunction(loadingButtons)
                        setShouldManualFirestoreId!(firestoreId)
                    }} variant='secondary'>Manual</Button>
                }
            })
        },
    ]
    if (allowButtons) {
        columns = columns.concat(buttons)
    }
    return columns
}

export default CashoutsTable
