import React, { Component } from 'react'

import './Legal.css'

function PrivacyPolicy() {
    return (
        <div className='container'>
            <div className='textContainer'>
                {/* <h1 className='title'>Privacy Policy</h1>

                    <p className='sectionTitle'>Section Title</p>
                    <p className='subsectionTitle'>Subsection Title</p>
                    <p className='subsectionText'>Subsection text</p>
                    <ul className='subsectionText'>
                        <li>Item1</li>
                        <li>Item2</li>
                    </ul>

                    <p className='subsectionText'>==========================</p> */}


                <h1 className='title'>Privacy Policy</h1>

                <p className='subsectionText'>This Privacy Policy, provided by SocialLoop LLC ('SocialLoop', 'we', 'our', or 'us'), effective as of March 20, 2020, informs you ('you' or 'your') how we gather and use, and the circumstances under which we may disclose, information we collect from users through our website (www.socialloop.io) and our app Eureka – Cash for Feedback (collectively, the 'Site') and other related software, interactive features, and/or services operated by us that post a link to this Privacy Policy (collectively, the 'Service'). This Privacy Policy does not apply to our information collection activities outside of the Service or to third-party websites (unless otherwise stated below or at the time of collection).
                By using the Service or otherwise accessing the Site, you agree to our Terms of Service, and to our collection, use and disclosure practices, and other activities as described in this Privacy Policy. If you do not agree and consent, discontinue use of the Service.
</p>

                <p className='sectionTitle'>COLLECTION OF INFORMATION</p>
                <p className='subsectionTitle'>Information You Provide</p>
                <p className='subsectionText'>The information your provide as part of your use of the Service and that we collect may include information that identifies you personally (whether alone or in combination), such as your name, address, phone number, email address, age, and gender ('Personal Information').  To properly match you with any third-party survey providers or others as part of the Service, we may ask you to provide additional information, including but not limited to your relationship status, household information and income, product preferences, shopping habits, household income, political affiliation, and job title. You may choose to voluntarily submit certain other information to us through the Service or to the third-party survey providers. We use this information to find you eligible surveys as part of the Service.  Any information provided to third-party survey providers is not covered by this Privacy Policy and we encourage you to review the privacy policy and terms of service of each third-party survey provider you interact with.
                We may collect, use, transfer, and disclose information that has been 'de-identified' for any purpose. Personal Information once de-identified is not subject to this Privacy Policy and we and our service providers may treat it as non-Personal Information and use it without obligation to you except as prohibited by applicable law.
                SocialLoop will retain your Personal Information and any other data collected for as long as is necessary to provide the Service, maintain business records to satisfy tax, legal and other regulatory requirements, and protect and defend against potential legal claims.
</p>

                <p className='subsectionTitle'>Information Collected Automatically</p>
                <p className='subsectionText'>We and our third-party service providers may use a variety of technologies, now and hereafter devised, that automatically record certain usage information about you ('Usage Information'). Usage Information collected is more thoroughly described below.  We use this information for a variety of purposes, including to help personalize our Service for you. Your Internet Protocol Address and other items automatically collected may be considered Personal Information.
                The methods that may be used on the Service to collect Usage Information include:
</p>
                <ul className='subsectionText'>
                    <li>Log Information: Log information is data about your use of the Service, such as IP address, browser type, internet service provider, referring/exit pages, operating system, date/time stamps, and related data, which is stored in log files.</li>
                    <li>Information Collected by Tracking Technologies: We and our third-party service providers may automatically collect information about your interactions with the Service or emails using various tracking technologies, including cookies, web beacons (also known as 'tracking pixels'), embedded scripts, location-identifying technologies, device fingerprinting, in-app tracking methods, and other tracking technologies now and hereafter developed ('Tracking Technologies').</li>
                    <li>Cookies: A cookie is a small data file transferred to your computer (or other device) when it is used to access our Service. Cookies may be used for many purposes, including to enable certain features of our Service, to better understand how you interact with our Service and to monitor aggregate usage by visitors and online traffic routing. Cookies may remain on your device for extended periods of time.  See our Cookie Policy for additional information.</li>
                    <li>Web Beacons (“Tracking Pixels”): Web beacons are small graphic images, also known as 'internet tags' or 'clear gifs,' embedded in web pages and email messages. Web beacons may be used to count the number of visitors to the Service, to monitor how users navigate the Service, and to count content views.</li>
                    <li>Embedded Scripts: An embedded script is programming code designed to collect information about your interactions with the Service temporarily downloaded onto your device from our web server or a third party with whom we work, active only while you are connected to the Service, and deleted or otherwise deactivated when you are no longer connected to the Service.</li>
                    <li>Location-identifying Technologies: GPS (global positioning systems) software, geo-filtering, and other location-aware technologies locate (sometimes precisely) you for purposes such as verifying your location and delivering or restricting relevant content based on your location.</li>
                    <li>Device Fingerprinting: Device fingerprinting is the process of analyzing and combining sets of information elements from your device’s browser, such as JavaScript objects and installed fonts, in order to create a “fingerprint” of your device and uniquely identify your device and applications.</li>
                    <li>In-App Tracking Methods: There are a variety of tracking technologies that may be included in mobile apps, and these are not browser-based like cookies and cannot be controlled by browser settings. Some use device identifier, or other identifiers such as “Ad IDs” to associate app user activity to a particular app and to track user activity across apps.</li>
                </ul>
                <p className='subsectionText'>Some information about your use of the Service and certain Third Party Services (defined below) may be collected using Tracking Technologies across time and services and used by us and third parties for purposes such as to associate different devices you use, and deliver relevant ads and/or other content to you on the Service and certain Third Party Services.</p>
                <p className='subsectionText'>For further information on Tracking Technologies and your choices regarding them, please see “Third Party Services, Features, and Devices” and “Your Choices” below.</p>

                <p className='subsectionTitle'>Information from Other Sources</p>
                <p className='subsectionText'>We may obtain information about you from other sources, including service providers and third parties, and combine such information with information we have collected about you. To the extent we combine such third party sourced information with Personal Information we have collected about you on the Service, we will treat the combined information as Personal Information under this Privacy Policy. We are not responsible for the accuracy of any information provided by third parties or third party policies or practices.  This Privacy Policy does not govern your use of any third-party website or service accessed through the Site or the Service and we encourage you to review any relevant privacy policies of third-parties.</p>


                <p className='sectionTitle'>USE OF INFORMATION</p>
                <p className='subsectionText'>SocialLoop may use your Personal Information and Usage Information alone or in combination with other information, for any purposes consistent with our Privacy Policy, including so that we may provide, enhance and personalize our Service and related content, products, offers, and marketing efforts. The following is a non-exhaustive list of ways that we may use your information:</p>
                <ul className='subsectionText'>
                    <li>to effectuate and manage our Service, identify you as a user of the Service, and help authenticate any account you create with us</li>
                    <li>to communicate with you regarding the Service, updates, offers, promotions or surveys available to you (please see “Your Choices” below for additional information on how to manage these communications)</li>
                    <li>to help us develop, customize, deliver, administer, support and improve our Service, products, survey offerings, and advertising and to provide you with the best user experience possible</li>
                    <li>to prevent fraud and other prohibited or illegal activities and to protect the rights, property and security of SocialLoop and our users</li>
                    <li>to respond to your comments, questions, and requests, and provide customer service</li>
                    <li>to send you technical notices, updates, security alerts, information regarding changes to our policies, and support and administrative messages</li>
                    <li>for our internal purposes (such as auditing, data analysis, and research to improve our services, and communications) and</li>
                    <li>for any other purpose as may be disclosed to you at the point of collection or otherwise with your consent.</li>
                </ul>

                <p className='sectionTitle'>SHARING OF INFORMATION</p>
                <p className='subsectionText'>We will not share your Personal Information with other companies or individuals outside of SocialLoop, its third party survey providers and its affiliates, unless we have received your consent and as described in this Privacy Policy. We may share your information as follows:</p>

                <ul className='subsectionText'>
                    <li>With Our Service Providers (i.e., hosting and storage services, software maintenance services, email notifications, database management, analytics, fulfilment services, and others) – these third parties may have access to your Personal Information to perform certain tasks on our behalf but they are not authorized by us to otherwise use or disclose your Personal Information.</li>
                    <li>With Our Third Party Survey Providers – if you choose to submit any information or materials to us including Personal Information and Usage Information, without limitation, we may share same, if any, with our third party survey providers. These third party survey providers may ask you for more information.</li>
                    <li>To Comply with Laws, Legal Process and Authorities and Protect Rights – we may disclose Personal Information or other information about you to government or law enforcement officials, in connection with subpoenas or judicial process, or to any other third party to protect the property and rights of us or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or may pose a risk of being, illegal, unethical or legally actionable.</li>
                    <li>For Business Transfers – in connection with a merger, acquisition, or other change in control, joint venture, reorganization or sale of assets or in the event of bankruptcy or liquidation proceeding of SocialLoop, we reserve the right to transfer or otherwise disclose your information, including Personal Information. </li>
                    <li>With Our Affiliates for Internal Business Purposes – we may disclose information about you, including Personal Information, with our affiliates for internal business purposes.</li>
                    <li>For Direct Marketing Purposes – we may disclose information about you, including Personal Information, with our affiliates, business partners, and other third parties including, survey partners, for their own business purposes, including direct marketing purposes (California residents have certain rights set forth in “Your California Privacy Rights” below).</li>
                    <li>With Sponsors and Other Third Parties for Sweepstakes, Contests, Surveys, or Promotions – if you voluntarily participate in a sweepstakes, contest, rewards program, survey or other promotion through our Site or Service, we may share your information, including Personal Information, with the participating sponsors or with third parties who assist us in using the content or other information you have posted or shared with us. By participating, you agree to the official rules that govern that promotion, and may, except where prohibited by applicable law, allow the sponsor and/or other parties to use your name, voice and/or likeness in advertising or marketing materials.</li>
                    <li>With your Consent – we may disclose information about you, including Personal Information, with your consent or at your direction, or for purposes disclosed at the time you provide the information.</li>
                </ul>
                <p className='subsectionText'>In SocialLoop’s sole discretion, without limitation, we may share aggregated information (which does not identify you) or de-identified information about you with third parties or affiliates for any purpose.</p>

                <p className='sectionTitle'>THIRD PARTY SERVICES, FEATURES, AND DEVICES</p>
                <p className='subsectionText'>The Service contains content from and hyperlinks to websites and services operated and owned by third parties (“Third Party Services”), and the Service contains features that allow you to interact with, connect to, or access the Service through certain Third Party Services and third party devices (“Third Party Features”). For example, you interact with a Third Party Service when you “like” or “share” content over social media through our Service or log into an account that you already have with a third party social networking platform. We recommend that you adjust your privacy settings with each social networking platform to control what Personal Information is shared with SocialLoop as a result of these interactions. </p>
                <p className='subsectionText'>The Service also contains Tracking Technologies operated by third parties, including to help us analyze your use of the Service, compile statistic reports on the Service’s activity, and provide other services relating to Service activity and internet usage.  The Site utilizes Google Analytics and Mixpanel for such services.  Ad services, advertisers, and other third parties may use Tracking Technologies on our Service and Third Party Services to track your activities and tailor ads to you based on same, including sending you an ad on a Third Party Service or third party website after you have left the Service (“Interest-based Advertising”).</p>
                <p className='subsectionText'>This Privacy Policy does not apply to third party sites to which we may link or to the products or services of our business partners who may host and/or deliver our data to third party survey providers. The information collected and stored by third parties, whether through our Service, a Third Party Service, or a third party device, remains subject to their own policies and practices. We are not responsible for and make no representations regarding third party policies and practices, and encourage you to familiarize yourself with and consult their privacy policies and terms of use. Any links to a third party service, website or content is not an endorsement, authorization or representation of our affiliation with any of those third parties, nor is it an endorsement of their privacy or information security policies or practices. Any information we receive from third parties that may be combined with Personal Information we collect directly from you will be treated as Personal Information under this Privacy Policy.  </p>
                <p className='subsectionText'>For further information on Tracking Technologies and your choices regarding them, please see “Information Collected Automatically” above and “Your Choices” below.</p>

                <p className='sectionTitle'>YOUR CHOICES</p>
                <p className='subsectionTitle'>Changing or Deleting Your Information</p>
                <p className='subsectionText'>To view and change Personal Information that you have directly provided to SocialLoop through the Service, you can contact SocialLoop by sending an email to support@eurekarewards.com or by or sending us a letter to SocialLoop LLC, 417 3rd Ave, New York City, New York 10016. Requests must include your name, street address, city, state, and ZIP code.  We are not required to respond to requests made by means other than through the provided email address or mail address.  Information may also be accessible via the “Policy” section of our Site. We may require additional information from you to allow us to confirm your identity. </p>
                <p className='subsectionText'>Please note we may retain server/backup copies of all such data, and that we may decline to process change or deletion requests that we deem to be unreasonable, require disproportionate technical effort, jeopardize the privacy of others, or are not required by law, among other reasons.</p>

                <p className='subsectionTitle'>Tracking Technologies Generally</p>
                <p className='subsectionText'>You may be able to instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the online services you visit. Please check with your internet browser for further details. To stop all collection of information via an app, you can uninstall the app. You may be able to utilize other privacy choices by adjusting the permissions in your mobile device, including enabling or disabling certain location-based services. Disabling these technologies, however, may result in your inability to use all or portions of the Site or Service.</p>
                <p className='subsectionText'>We also use cookies and similar technologies (“Cookies”) to gather information. For more information on our use of Cookies, see our Cookies Policy [Link].</p>

                <p className='subsectionTitle'>Analytics and Advertising Tracking Technologies</p>
                <p className='subsectionText'>You may exercise choices regarding the use of cookies from Google Analytics by going to https://tools.google.com/dlpage/gaoptout or downloading the Google Analytics Opt-out Browser Add-on. You may choose whether to receive Interest-based Advertising by submitting opt outs, although such opt outs are not guaranteed.</p>
                <p className='subsectionText'>SocialLoop is not responsible for the effectiveness of, or compliance with, any third-parties’ opt out options or programs or the accuracy of their statements regarding their programs.  Some opt out programs include but are not limited to the Digital Advertising Alliance ('DAA') Self-Regulatory Program for Online Behavioral Advertising (http://www.aboutads.info/choices and http://www.aboutads.info/appchoices) and the Network Advertising Initiative (“NAI”) (http://www.networkadvertising.org/choices/).</p>

                <p className='subsectionTitle'>Communications Opt-Out</p>
                <p className='subsectionText'>To opt-out of receiving certain promotional communications (emails, text messages, and push notifications) from us at any time by: (i) for emails, following the instructions as provided in emails to click on the unsubscribe link, or contacting us at support@eurekarewards.com with the word UNSUBSCRIBE in the subject field of the email (ii) for text messages, texting “STOP” in response to any text message you receive or contacting us at support@eurekarewards.com and specifying you want to opt-out of text messages and (iii) for push notifications or in-app messages, adjusting the permissions in your mobile device. Please note that your opt-out is limited to the email address, phone number, or device used and will not affect subsequent subscriptions or, for emails, non-promotional communications, such as those about your account, surveys, transactions, servicing, or our ongoing business relations. You understand that we may send you a text message confirming any opt-out by you. </p>

                <p className='sectionTitle'>CALIFORNIA RESIDENTS’ PRIVACY RIGHTS</p>
                <p className='subsectionText'>California’s “Shine the Light” law and the Consumer Privacy Act of 2018 (“CCPA”) defines Personal Information as applicable to California residents.  SocialLoop may share such Personal Information, as defined, with third parties and/or affiliates for such third parties’ and affiliates’ own direct marketing purposes and as otherwise set forth in this Privacy Policy.  Subject to certain exceptions and limitations, California’s “Shine the Light” law and CCPA permit consumers in California the right:</p>
                <ul className='subsectionText'>
                    <li>to request a list of what Personal Information (if any) SocialLoop disclosed to third-parties for their own direct marketing purposes in the preceding calendar year and the names and addresses of those third parties</li>
                    <li>to know, from the prior twelve (12) month period, the categories of Personal Information SocialLoop collected, used, disclosed and sold about you (if any), the categories of sources from which this Personal Information was gathered about you, SocialLoop’s business purpose for collecting or selling your Personal Information, the categories of your Personal Information that SocialLoop has either sold or disclosed for a business purpose (if any), and the categories of third parties with whom SocialLoop has shared your Personal Information</li>
                    <li>to request that SocialLoop delete the Personal Information it has collected from you or maintains about you</li>
                    <li>to opt-out of the sale of your Personal information to third parties (if any) and </li>
                    <li>to not receive discriminatory treatment for the exercise of the privacy rights provided by the CCPA. </li>
                </ul>

                <p className='subsectionText'>If you are a California resident and wish to obtain information about our compliance with California’s laws pertaining to privacy, please (1) email us at support@eurekarewards.com (2) submit a request through the Site at www.socialloop.io/ccpa [have to build a separate online form for consumers to submit request for information without requiring that they become members or create accounts] or (3) send us a letter to SocialLoop LLC, 417 3rd Ave, New York City, New York 10016. Requests must include “California Privacy Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code. Please note that we are not required to respond to requests made by means other than through the provided email address, website, or mail address.</p>
                <p className='subsectionText'>SocialLoop will respond to a verifiable consumer request (as defined by the CCPA) in accordance with applicable privacy laws.  We have the right to require authentication of the consumer due to the nature of the personal information requested.</p>
                <p className='subsectionText'>Under the CCPA, Personal Information does not include (i) information that is lawfully made available from federal, state or local government records, (ii) de-identified or aggregated data or (iii) information excluded from the scope of the CCPA.  Any disclosures we provide will only cover the 12-month period preceding our receipt of your request (and will not be made more than twice in a 12-month period). If we cannot comply with all or part of a request, the response we provide will also explain the reason(s) why we cannot comply.</p>

                <p className='sectionTitle'>EU RESIDENTS’ PRIVACY RIGHTS</p>
                <p className='subsectionText'>You have certain rights granted to you by and through the EU General Data Protection Regulation (GDPR), among other regulations.  </p>
                <p className='subsectionText'>You have the right to make a “subject access request” by sending us an email at support@eurekarewards.com and SocialLoop is obligated to provide you with a description and copy of such Personal Information collected and to tell you why we have collected and maintained that information.</p>
                <p className='subsectionText'>You may have a right to have your Personal Information rectified or erased, to object to its processing, or have its processing restricted.  You have the right to be given the information provided to SocialLoop by consent in machine readable format for transmitting to another data controller.  </p>
                <p className='subsectionText'>You have the right to withdraw consent for any information provided to SocialLoop by consent. Any withdrawal of consent will not affect the lawfulness of any information processed prior to such withdrawal.</p>

                <p className='sectionTitle'>DATA SECURITY</p>
                <p className='subsectionText'>We have undertaken commercially reasonable efforts to prevent unauthorized use or access to your information retained in our servers or transmitted to us appropriate to the nature of the information you provide to SocialLoop and designed to protect your information, however, due to the inherent open nature of the internet, we cannot ensure, guarantee or warrant the security of any information you transmit to us and you do so at your own risk.  As such, we will not be liable for disclosures of your personal information or any resulting misuse of your personal information due to errors in transmission or unauthorized acts of third parties, including intrusion, access or manipulation during transmission or other breaches of our servers, the Site, the Service, the internet or any computer network.  </p>

                <p className='sectionTitle'>OTHER GENERAL INFORMATION</p>
                <p className='subsectionTitle'>Data Transfers</p>
                <p className='subsectionText'>SocialLoop processes information on our servers in the United States of America. Your information, including Personal Information, may be transferred to, and used by, entities located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. Such export is a requirement of providing your information to SocialLoop, and you consent to such transfer and use in all respects. </p>

                <p className='subsectionTitle'>Children</p>
                <p className='subsectionText'>To utilize the Site and the Service, you must be over the age of 16.  We do not knowingly collect personal information as defined by the U.S. Children’s Privacy Protection Act (“COPPA”) from children under 13 in a manner that is not permitted by COPPA, and do not target our online services to children under 13. If we discover that someone under the age of 13 has provided personal information, we will delete such information from our Site and Service to the extent required by COPPA. We encourage parents and guardians to prevent children from submitting information or data on our Service, and to contact us at support@eurekarewards.com if they believe we have collected personal information from children under 13.</p>

                <p className='subsectionTitle'>Changes to this Privacy Policy</p>
                <p className='subsectionText'>We may update this Privacy Policy from time to time at our discretion and will notify you of any material changes by posting an announcement notice on the Site or via email. Any changes will be effective immediately upon posting of the revised Privacy Policy. Your continued use of our Service indicates your consent to the Privacy Policy then posted. We encourage you to revisit this Privacy Policy.</p>

                <p className='subsectionTitle'>Contact Us</p>
                <p className='subsectionText'>If you have any questions about this Privacy Policy, please feel free to contact us by sending an email to support@eurekarewards.com.</p>
            </div>

            {/* <p className='sectionTitle'>SectionTitle</p>
                <p className='subsectionTitle'>title</p>
                <p className='subsectionText'>text</p>
                <ul className='subsectionText'>
                    <li>Item1</li>
                    <li>Item2</li>
                </ul> */}
        </div>
    )
}


export default PrivacyPolicy
