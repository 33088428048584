import './Tables.css'
import React, { useRef } from 'react'
import Table, { TableSortOrder, useResize, TableRenderers } from './Table'
import { Column } from 'react-base-table'
import memoize from 'memoize-one'
import moment from 'moment'
import { LucidSurveyTemplateProxy } from '../../../sharedModels/LucidSurveyTemplateProxy'
import { ProgressBar, Button } from 'react-bootstrap'

interface LucidInventoryTableProps {
    lucidSurveyTemplates: LucidSurveyTemplateProxy[],
    setLaunchSurvey: React.Dispatch<React.SetStateAction<number>>,
    setExpireSurvey: React.Dispatch<React.SetStateAction<number>>,
    setEnglishQualificationsForSurvey: React.Dispatch<React.SetStateAction<number>>,
    width: number
    height: number
}

const LucidInventoryTable: React.FC<LucidInventoryTableProps> = ({ lucidSurveyTemplates, setLaunchSurvey, setExpireSurvey, setEnglishQualificationsForSurvey, width, height }) => {

    if (lucidSurveyTemplates === undefined || lucidSurveyTemplates.length === 0) {
        return <div>Loading...</div>
    } else if (lucidSurveyTemplates.length === 0) {
        return <div>No Lucid Inventory</div>
    }

    const setLaunchSurveyFunction = (surveyNumber: number) => {
        setLaunchSurvey(surveyNumber)
    }

    const setExpireSurveyFunction = (surveyNumber: number) => {
        setExpireSurvey(surveyNumber)
    }

    const setEnglishQualificationsForSurveyFunction = (surveyNumber: number) => {
        setEnglishQualificationsForSurvey(surveyNumber)
    }

    let coluns = generateColumns(lucidSurveyTemplates, setLaunchSurveyFunction, setExpireSurveyFunction, setEnglishQualificationsForSurveyFunction)

    return (
        <div>
            <Table rows={lucidSurveyTemplates} width={width} height={height} columns={coluns} data={generateData(lucidSurveyTemplates)} />
        </div>
    )
}

const generateData = (lucidSurveyTemplates: LucidSurveyTemplateProxy[]): Column[] => {
    return lucidSurveyTemplates.map((lucidSurveyTemplate, idx) => {
        return {
            ...lucidSurveyTemplate,
            index: idx + 1,
            id: lucidSurveyTemplate.firestoreId,
            parentId: null,
            width: 150,
        }
    })
}

const generateColumns = (lucidSurveyTemplates: LucidSurveyTemplateProxy[], setLaunchSurveyFunction: (surveyNumber: number) => void, setExpireSurveyFunction: (surveyNumber: number) => void, setEnglishQualificationsForSurveyFunction: (surveyNumber: number) => void): Column[] => {
    let columns: Column[] = [
        {
            key: 'index',
            dataKey: 'index',
            title: 'Idx',
            width: 75,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let { index } = rowData
                return TableRenderers.default(index)
            })
        },
        {
            key: 'surveyNumber',
            dataKey: 'surveyNumber',
            title: 'Survey Number',
            width: 100,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { surveyNumber } = lucidSurvey
                return TableRenderers.default(`${surveyNumber}`)
            })
        },
        {
            key: 'accountName',
            dataKey: 'accountName',
            title: 'Account',
            width: 130,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { accountName } = lucidSurvey
                return TableRenderers.default(`${accountName}`)
            })
        },
        {
            key: 'createdTimestamp',
            dataKey: 'createdTimestamp',
            title: 'Created',
            width: 140,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { createdTimestamp } = lucidSurvey
                return TableRenderers.relativeDateRender(createdTimestamp)
            })
        },
        {
            key: 'lastUpdateTimestamp',
            dataKey: 'lastUpdateTimestamp',
            title: 'Updated',
            order: TableSortOrder.Descending,
            width: 140,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lastUpdateTimestamp } = lucidSurvey
                return TableRenderers.relativeDateRender(lastUpdateTimestamp)
            })
        },
        {
            key: 'clientState',
            dataKey: 'clientState',
            title: 'Client State',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { clientState } = lucidSurvey
                return TableRenderers.default(`${clientState}`)
            })
        },
        {
            key: 'revenueCents',
            dataKey: 'revenueCents',
            title: 'Revenue',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { revenueCents } = lucidSurvey
                return TableRenderers.centsToDollarRender(revenueCents)
            })
        },
        {
            key: 'conversion',
            dataKey: 'conversion',
            title: 'Conversion',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { conversion } = lucidSurvey
                let conversionString = `${(conversion * 100).toFixed(1)}%`
                return TableRenderers.default(conversionString)
            })
        },
        {
            key: 'mobileConversion',
            dataKey: 'mobileConversion',
            title: 'Mobile Conversion',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { mobileConversion } = lucidSurvey
                if (mobileConversion === undefined) {
                    return TableRenderers.default('n/a')
                }
                let conversionString = `${mobileConversion.toFixed(1)}%`
                return TableRenderers.default(conversionString)
            })
        },
        {
            key: 'userEstimatedDurationSeconds',
            dataKey: 'userEstimatedDurationSeconds',
            title: 'LOI',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { userEstimatedDurationSeconds } = lucidSurvey
                return TableRenderers.secondsToMinutesRender(userEstimatedDurationSeconds)
            })
        },
        {
            key: 'terminationLoiSeconds',
            dataKey: 'terminationLoiSeconds',
            title: 'Terminate LOI',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { terminationLoiSeconds } = lucidSurvey
                return TableRenderers.secondsToMinutesRender(terminationLoiSeconds)
            })
        },
        // {
        //     key: 'totalSupply',
        //     dataKey: 'totalSupply',
        //     title: 'Total Supply',
        //     width: 80,
        //     sortable: true,
        //     renderer: (row => {
        //         let { rowData } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { totalSupply } = lucidSurvey
        //         return TableRenderers.default(`${totalSupply}`)
        //     })
        // },
        // {
        //     key: 'totalRevenueCents',
        //     dataKey: 'totalRevenueCents',
        //     title: 'Total Revenue',
        //     width: 80,
        //     sortable: true,
        //     renderer: (row => {
        //         let { rowData } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { totalRevenueCents } = lucidSurvey
        //         return TableRenderers.centsToDollarRender(totalRevenueCents)
        //     })
        // },
        // {
        //     key: 'totalCompleted',
        //     dataKey: 'totalCompleted',
        //     title: 'Total Completed',
        //     width: 80,
        //     sortable: true,
        //     renderer: (row => {
        //         let { rowData } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { totalCompleted } = lucidSurvey
        //         return TableRenderers.default(`${totalCompleted}`)
        //     })
        // },
        {
            key: 'allocationRemaining',
            dataKey: 'allocationRemaining',
            title: 'Remaining',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { allocationRemaining, hedgeRemaining } = lucidSurvey
                if (allocationRemaining === undefined || hedgeRemaining === undefined) {
                    return TableRenderers.default('n/a')
                }
                return TableRenderers.default(`${allocationRemaining + hedgeRemaining}`)
            })
        },
        // {
        //     key: 'progress',
        //     dataKey: 'progress',
        //     title: 'Progress',
        //     width: 150,
        //     renderer: (row => {
        //         let { rowData, isScrolling } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { totalCompleted, totalSupply } = lucidSurvey
        //         let progress = (totalCompleted / totalSupply) * 100
        //         if (isScrolling) return <p></p>
        //         return <div className='lucid-inventory-progress'>
        //             <ProgressBar striped variant='success' now={progress} />
        //         </div>
        //     })
        // },
        // {
        //     key: 'liveLink',
        //     dataKey: 'liveLink',
        //     title: 'Entry Links',
        //     width: 110,
        //     sortable: true,
        //     renderer: (row => {
        //         let { rowData } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { liveLink, testLink } = lucidSurvey
        //         return TableRenderers.entryLinks(liveLink, testLink)
        //     })
        // },
        {
            key: 'lucidSurveysConversion',
            dataKey: 'lucidSurveysConversion',
            title: 'Eureka Conversion',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysConversion } = lucidSurvey
                lucidSurveysConversion = lucidSurveysConversion || 0
                return TableRenderers.default(`${(lucidSurveysConversion * 100).toFixed(1)}%`)
            })
        },
        {
            key: 'lucidSurveysStarted',
            dataKey: 'lucidSurveysStarted',
            title: 'Started',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysStarted } = lucidSurvey
                lucidSurveysStarted = lucidSurveysStarted || 0
                return TableRenderers.default(`${lucidSurveysStarted}`)
            })
        },
        {
            key: 'lucidSurveysCompleted',
            dataKey: 'lucidSurveysCompleted',
            title: 'Completed',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysCompleted } = lucidSurvey
                lucidSurveysCompleted = lucidSurveysCompleted || 0
                return TableRenderers.default(`${lucidSurveysCompleted}`)
            })
        },
        {
            key: 'lucidSurveysTerminated',
            dataKey: 'lucidSurveysTerminated',
            title: 'Terminated',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysTerminated } = lucidSurvey
                lucidSurveysTerminated = lucidSurveysTerminated || 0
                return TableRenderers.default(`${lucidSurveysTerminated}`)
            })
        },
        {
            key: 'lucidSurveysOverQuoted',
            dataKey: 'lucidSurveysOverQuoted',
            title: 'Overquoted',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysOverQuoted } = lucidSurvey
                lucidSurveysOverQuoted = lucidSurveysOverQuoted || 0
                return TableRenderers.default(`${lucidSurveysOverQuoted}`)
            })
        },
        {
            key: 'lucidSurveysQualityTerminated',
            dataKey: 'lucidSurveysQualityTerminated',
            title: 'Qual Terminated',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysQualityTerminated } = lucidSurvey
                lucidSurveysQualityTerminated = lucidSurveysQualityTerminated || 0
                return TableRenderers.default(`${lucidSurveysQualityTerminated}`)
            })
        },
        {
            key: 'lucidSurveysFailedScreener',
            dataKey: 'lucidSurveysFailedScreener',
            title: 'Failed Screener',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysFailedScreener } = lucidSurvey
                lucidSurveysFailedScreener = lucidSurveysFailedScreener || 0
                return TableRenderers.default(`${lucidSurveysFailedScreener}`)
            })
        },
        {
            key: 'lucidSurveysFailedScreenerQuota',
            dataKey: 'lucidSurveysFailedScreenerQuota',
            title: 'Quota Screener',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysFailedScreenerQuota } = lucidSurvey
                lucidSurveysFailedScreenerQuota = lucidSurveysFailedScreenerQuota || 0
                return TableRenderers.default(`${lucidSurveysFailedScreenerQuota}`)
            })
        },
        {
            key: 'lucidSurveysUserExited',
            dataKey: 'lucidSurveysUserExited',
            title: 'User Exit',
            width: 80,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysUserExited } = lucidSurvey
                lucidSurveysUserExited = lucidSurveysUserExited || 0
                return TableRenderers.default(`${lucidSurveysUserExited}`)
            })
        },
        {
            key: 'lucidLinkSurveysCompleted',
            dataKey: 'lucidLinkSurveysCompleted',
            title: 'Link Completes',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidLinkSurveysCompleted } = lucidSurvey
                lucidLinkSurveysCompleted = lucidLinkSurveysCompleted || 0
                return TableRenderers.default(`${lucidLinkSurveysCompleted}`)
            })
        },
        // {
        //     key: 'epcCents',
        //     dataKey: 'epcCents',
        //     title: 'EPC',
        //     width: 110,
        //     sortable: true,
        //     renderer: (row => {
        //         let { rowData } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { epcCents } = lucidSurvey
        //         return TableRenderers.centsToDollarRender(epcCents)
        //     })
        // },
        // {
        //     key: 'epcmCents',
        //     dataKey: 'epcmCents',
        //     title: 'EPCM',
        //     width: 110,
        //     sortable: true,
        //     renderer: (row => {
        //         let { rowData } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { epcmCents } = lucidSurvey
        //         epcmCents = epcmCents || 0
        //         return TableRenderers.default(`${epcmCents.toFixed(3)}¢`)
        //     })
        // },
        // {
        //     key: 'completesPerMinute',
        //     dataKey: 'completesPerMinute',
        //     title: 'Completes / min',
        //     width: 110,
        //     sortable: true,
        //     renderer: (row => {
        //         let { rowData } = row
        //         let lucidSurvey: LucidSurveyTemplateProxy = rowData
        //         let { completesPerMinute } = lucidSurvey
        //         if (completesPerMinute === undefined) {
        //             return TableRenderers.default('n/a')
        //         }
        //         let completesPerMinuteString = `${completesPerMinute.toFixed(2)}`
        //         return TableRenderers.default(completesPerMinuteString)
        //     })
        // },
        {
            key: 'score',
            dataKey: 'score',
            title: 'Score',
            width: 75,
            sortable: true,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { score } = lucidSurvey
                score = score || 0
                return TableRenderers.default(`${score.toFixed(2)}`)
            })
        },
        {
            key: 'totalEntrants',
            dataKey: 'totalEntrants',
            title: 'Entrants',
            width: 90,
            sortable: true,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { totalEntrants } = lucidSurvey
                totalEntrants = totalEntrants || 0
                return TableRenderers.default(`${totalEntrants}`)
            })
        },
        {
            key: 'lucidSurveysCreated',
            dataKey: 'lucidSurveysCreated',
            title: 'Created',
            width: 90,
            sortable: true,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysCreated } = lucidSurvey
                return TableRenderers.default(`${lucidSurveysCreated}`)
            })
        },
        {
            key: 'lucidSurveyScreenersCreated',
            dataKey: 'lucidSurveyScreenersCreated',
            title: 'Screeners Created',
            width: 90,
            sortable: true,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysScreenersCreated: lucidSurveyScreenersCreated } = lucidSurvey
                lucidSurveyScreenersCreated = lucidSurveyScreenersCreated || 0
                return TableRenderers.default(`${lucidSurveyScreenersCreated}`)
            })
        },
        {
            key: 'lucidSurveysExpiredQuota',
            dataKey: 'lucidSurveysExpiredQuota',
            title: 'Expired Quota',
            width: 90,
            sortable: true,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { lucidSurveysExpiredQuota } = lucidSurvey
                lucidSurveysExpiredQuota = lucidSurveysExpiredQuota || 0
                return TableRenderers.default(`${lucidSurveysExpiredQuota}`)
            })
        },
        {
            key: 'launch',
            dataKey: 'launch',
            title: 'Launch',
            width: 150,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { surveyNumber } = lucidSurvey
                return <Button onClick={() => {
                    setLaunchSurveyFunction(surveyNumber)
                }} variant='primary'>Launch</Button>
            })
        },
        {
            key: 'expire',
            dataKey: 'expire',
            title: 'Expire',
            width: 150,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { surveyNumber } = lucidSurvey
                return <Button onClick={() => {
                    setExpireSurveyFunction(surveyNumber)
                }} variant='danger'>Expire</Button>
            })
        },
        {
            key: 'englishQuals',
            dataKey: 'englishQuals',
            title: 'Quals',
            width: 80,
            frozen: Column.FrozenDirection.RIGHT,
            renderer: (row => {
                let { rowData } = row
                let lucidSurvey: LucidSurveyTemplateProxy = rowData
                let { surveyNumber } = lucidSurvey
                return <Button onClick={() => {
                    setEnglishQualificationsForSurveyFunction(surveyNumber)
                }} variant='primary'>Quals</Button>
            })
        },
    ]
    return columns
}

export default LucidInventoryTable
