import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

// Define all actions that can occur on the UserLookup Component
export const COMPLETES_REQUEST = 'COMPLETES_REQUEST'
export const COMPLETES_SNAPSHOT_UPDATE = 'COMPLETES_SNAPSHOT_UPDATE'


export interface CompletesRequest {
    type: typeof COMPLETES_REQUEST,
}
export interface CompletesSnapshotUpdate {
    type: typeof COMPLETES_SNAPSHOT_UPDATE,
    completes: SweepstakeSurveyProxy[]
}


export type CompletesActions = CompletesRequest | CompletesSnapshotUpdate 