import './SurveyOver.css'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'
import { isMobile } from 'react-device-detect';

interface CompleteProps {

}

const SurveySuccessScreen = (props: CompleteProps) => {
    let emoji = '🎉'
    let text = isMobile ? <span className={`survey-over-text ${StandardFonts.SmallText} ${StandardTextColors.Black}`}>Thank you for completing the survey. <span className={`survey-over-text ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>This tab will close automatically.</span> Your reward has already been recorded.</span>
        : <span className={`survey-over-text ${StandardFonts.SmallText} ${StandardTextColors.Black}`}>Thank you for completing the survey. <span className={`survey-over-text ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Please close this tab</span> to claim your reward.</span>

    return (
        <div className={`survey-container-root ${StandardBackgroundColors.BackgroundLightGrey}`}>
            <div className={`survey-over-container ${StandardBackgroundColors.White} ${StandardDropShadows.Normal} ${StandardCornerRadii.Normal}`}>
                <p className='survey-over-emoji'>{emoji}</p>
                {text}
            </div>
        </div>
    )
}

export default SurveySuccessScreen