import { TerminatesActions, TERMINATES_REQUEST, TERMINATES_SNAPSHOT_UPDATE } from './Actions'
import { TerminatesState } from './Terminates'

export const reducer = (state: TerminatesState, action: TerminatesActions): TerminatesState => {
    switch (action.type) {
        case TERMINATES_REQUEST:
            return {
                isLoading: true,
                terminates: []
            }
        case TERMINATES_SNAPSHOT_UPDATE:
            let uniqueSweepstakeSurveys = state.terminates.concat(action.terminates)
            uniqueSweepstakeSurveys = uniqueSweepstakeSurveys.filter((transaction, index, self) =>
                index === self.findIndex((v) => (
                    v.firestoreId === transaction.firestoreId
                ))
            )
            let terminates = uniqueSweepstakeSurveys.sort((a, b) => {
                let aTs = a.createdTimestamp || 0
                let bTs = b.createdTimestamp || 0
                return bTs - aTs
            })
            return {
                isLoading: false,
                terminates: terminates
            }
    }
}