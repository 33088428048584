import './CompletedSurveys.css'
import React from 'react'
import moment from 'moment'
import { CashoutRequestProxy, PayPalStatus } from '../../sharedModels/CashoutRequestsProxy'
import Spinner from '../Spinner/Spinner'
import { StandardDropShadows, StandardCornerRadii, StandardBackgroundColors, StandardFonts, StandardTextColors } from '../Styles/index'
import { Survey } from '../../sharedModels/SurveyProxy'
import { UserProxy } from '../../sharedModels/UserProxy'
import { useCompletedSurveysHook, CompletedSurveysHook } from './Hook'
import { SweepstakeSurveyProxy } from '../../sharedModels/SweepstakeSurveyProxy'

interface CompletedSurveysProps {
    eurekaUser: UserProxy
}

export interface CompletedSurveysState {
    isLoading: boolean,
    completedSurveys: Survey[],
    cashouts: CashoutRequestProxy[],
}

export let CompletedSurveys: React.FC<CompletedSurveysProps> = (props) => {
    let { eurekaUser } = props
    let { state }: CompletedSurveysHook = useCompletedSurveysHook(eurekaUser)
    let { completedSurveys, cashouts, isLoading } = state

    if (isLoading) {
        return <Spinner />
    } else if (cashouts.length === 0 && completedSurveys.length === 0) {
        return (
            <div className={`completed-surveys-container`}>
                <p className={`${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>You haven't completed any surveys yet! Once you complete them, they will appear here.</p>
            </div>
        )
    }

    return (
        <div className='completed-container'>
            {cashouts.length > 0 ? // Robin: Is it ok to place render logic in the final return?
                <div className={`completed-surveys-container`}>
                    <p className={`completed-surveys-title ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Cashouts</p>
                    <div className={`completed-surveys-content ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}`}>
                        {cashouts && cashouts.map(cashout => {
                            return <CashoutCell cashout={cashout} />
                        })}
                    </div>
                </div>
                : null}
            <div className={`completed-surveys-container`}>
                <p className={`completed-surveys-title ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Completed Surveys</p>
                <div className={`completed-surveys-content ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}`}>
                    {completedSurveys && completedSurveys.map(survey => {
                        return <CompletedSurveyCell completedSurvey={survey} eurekaUserId={eurekaUser.userId} />
                    })}
                </div>
            </div>
        </div >
    )
}

export default CompletedSurveys


interface CompletedSurveyCellProps {
    completedSurvey: Survey,
    eurekaUserId: string
}
const CompletedSurveyCell: React.FC<CompletedSurveyCellProps> = (props) => {
    let { completedSurvey, eurekaUserId } = props
    let { topic, userEarningsString, completedHumanReadableDate } = completedSurvey
    let text = `Completed on ${completedHumanReadableDate}`

    if (completedSurvey["source"] === "Eureka") {
        // no-op for quick surveys
    } else if (completedSurvey as SweepstakeSurveyProxy) {
        let sweepstakeSurvey = completedSurvey as SweepstakeSurveyProxy
        let { winnerData, userSweepstakesString, untilWinnerSeconds } = sweepstakeSurvey
        if (winnerData) {
            let { userId, age, gender, state } = winnerData
            if (userId === eurekaUserId) {
                text = `${userSweepstakesString} Sweepstake won by you!`
            } else {
                text = `${userSweepstakesString} Sweepstake won by ${age} ${gender[0]}, ${state}`
            }
        } else {
            untilWinnerSeconds = untilWinnerSeconds || 0
            let duration = `${Math.floor(untilWinnerSeconds / (3600 * 24))}D ${Math.floor((untilWinnerSeconds / 3600) % 24)}H ${Math.floor((untilWinnerSeconds / 60) % 60)}M`
            text = `${userSweepstakesString} Sweepstake in ${duration}`
        }
    }
    return (
        <div className='completed-survey-cell'>
            <div className='completed-survey-cell-info'>
                <p className={`completed-survey-cell-label ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>{topic}</p>
                <p className={`completed-survey-cell-date ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}>{text}</p>
            </div>
            <div className='completed-survey-cell-grow'></div>
            <div className='completed-survey-cell-amount'>
                <p className={`completed-survey-cell-amount-label ${StandardFonts.SmallTextBold} ${StandardTextColors.Green}`}>+{userEarningsString}</p>
            </div>
        </div >
    )
}

interface CashoutCellProps {
    cashout: CashoutRequestProxy
}
const CashoutCell: React.FC<CashoutCellProps> = (props) => {
    let { cashout } = props
    let { email, currentEarningsString, createdTimestamp, paypal_batch_status } = cashout
    createdTimestamp = createdTimestamp || 0

    let title = (paypal_batch_status === PayPalStatus.Success || paypal_batch_status === PayPalStatus.Manual) ? `Sent to ${email}` : `Processing for ${email}`
    return (
        <div className='completed-survey-cell'>
            <div className='completed-survey-cell-info'>
                <p className={`completed-survey-cell-label ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>{title}</p>
                <p className={`completed-survey-cell-date ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}>{moment.unix(createdTimestamp).format('LLL')}</p>
            </div>
            <div className='completed-survey-cell-grow'></div>
            <div className='completed-survey-cell-amount'>
                <p className={`completed-survey-cell-amount-label ${StandardFonts.SmallTextBold} ${StandardTextColors.Yellow}`}>+{currentEarningsString}</p>
            </div>
        </div >
    )
}