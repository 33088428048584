import firebase from 'firebase'
import { COMPLETES_SNAPSHOT_UPDATE } from './Actions'
import { CompletesState } from './Completes'
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { SweepstakeSurveyProxy, SweepstakeSurveyState } from '../../../sharedModels/SweepstakeSurveyProxy'
import { reducer } from './Reducer'
import { useReducer, useEffect } from 'react'

let initialState: CompletesState = {
    isLoading: true,
    completes: [],
}

export interface CompletesHook {
    state: CompletesState,
}

export const completesSnapshotOnUpdate = (observer: {
    next?: (snapshot: QuerySnapshot) => void
    error?: (error: Error) => void
    complete?: () => void
}) => {
    return firebase.firestore()
        .collection('sweepstakeSurvey')
        .where('clientState', '==', SweepstakeSurveyState.Completed)
        .orderBy('endTimestamp', 'desc')
        .limit(2)
        .onSnapshot(observer)
}

export const useCompletesHook = (): CompletesHook => {
    let [state, dispatch] = useReducer(reducer, initialState)

    // Snapshot listener
    useEffect(() => {
        const unsubscribe = completesSnapshotOnUpdate({
            next: querySnapshot => {
                const updatedCompletes = querySnapshot.docs.map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
                console.log(`Snapshot query just updated ${updatedCompletes.length} docs`)
                dispatch({ type: COMPLETES_SNAPSHOT_UPDATE, completes: updatedCompletes })
            }
        })

        return unsubscribe
    }, [])

    // Initial fetch
    useEffect(() => {
        let fetchData = async () => {
            console.log('Fired once!')
            let ref = await firebase.firestore()
                .collection('sweepstakeSurvey')
                .where('clientState', '==', SweepstakeSurveyState.Completed)
                .orderBy('endTimestamp', 'desc')
                .limit(300)
                .get()

            const updatedCompletes = ref.docs.map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
            console.log(`Updated ${updatedCompletes.length} docs`)
            dispatch({ type: COMPLETES_SNAPSHOT_UPDATE, completes: updatedCompletes })
        }

        fetchData()
    }, [])

    return {
        state: state,
    }
}
