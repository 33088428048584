import './Tables.css'
import 'react-base-table/styles.css'
import BaseTable, { Column } from 'react-base-table'
import React, { Component, useState, useEffect, useMemo } from 'react'
import firebase from 'firebase'
import moment from 'moment'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'
const Timestamp = firebase.firestore.Timestamp

interface TableProps {
    rows: any[],
    columns: any[],
    data: any[],
    width: number,
    height: number
}

interface TableSortOrderKey {
    key: string,
    order: string
}

export enum TableSortOrder {
    Ascending = 'asc',
    Descending = 'desc',
}

// Render types
export enum TableRendererTypes {
    default = 'default',
    userIdRender = 'userIdRender',
    centsToDollarRender = 'centsToDollarRender',
    relativeDateRender = 'relativeDateRender',
    profitRender = 'profitRender',
    profitMarginRender = 'profitMarginRender',
    secondsToMinutesRender = 'secondsToMinutesRender',
    sumValuesRender = 'sumValuesRender',
    percentageRender = 'percentageRender',
    deviceLocationRender = 'deviceLocationRender',
    entryLinks = 'entryLinks'
}

const defaultRender = (text: string) => {
    if (text === undefined) {
        text = ''
    }
    return <p className='tables-cell'>{text}</p>
}

const userIdRender = (userId: string) => {
    let random = userId[0].charCodeAt(0)
    let emojis = ['🧑🏼‍🌾', '👨🏻‍🎤', '🧑🏽‍🎤', '🧑🏿‍🏫', '👩🏽‍💼', '🧑🏼‍🏭', '👨🏻‍💻', '👩🏻‍🔬', '🧑🏾‍🚒', '🦸🏽', '🎅🏿', '🦹🏾']
    let emoji = emojis[Math.floor(random % emojis.length)]
    return (
        <span>
            <a href={`http://www.eurekarewards.com/admin?userId=${userId}`} target='_blank'>{emoji} </a><span className='tables-monospace'>{userId}</span>
        </span>
    )
}

const centsToDollarRender = (amountCents: number = 0) => {
    return <p className='tables-cell'>${numberWithCommas((amountCents / 100).toFixed(2))}</p>
}

function numberWithCommas(n: string): string {
    var parts = n.toString().split('.')
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '')
}

const relativeDateRender = (timestamp: number = 0) => {
    // let date = moment.unix(timestamp).fromNow()
    return <p className='tables-cell'>{timeSince(timestamp)}</p>
}

function timeSince(timestamp: number): string {

    var seconds = Math.floor((Timestamp.now().seconds - timestamp))

    var interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
        return interval + " years"
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
        return interval + " months"
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
        return interval + " days"
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
        return interval + " hours"
    }
    interval = Math.floor(seconds / 60)
    if (seconds <= 30) {
        return Math.floor(seconds) + " seconds";
    }
    if (interval >= 1) {
        return interval + " minutes"
    }
    return Math.floor(seconds) + " seconds";
}

const profitRender = (revenueCents: number, userEarningsCents: number) => {
    let diff = revenueCents - userEarningsCents
    let sign = revenueCents >= userEarningsCents ? '+' : '-'
    return <p className='tables-cell'>{`${sign}$${(Math.abs(diff) / 100).toFixed(2)}`}</p>
}

const profitMarginRender = (revenueCents: number = 0, userEarningsCents: number) => {
    let diff = revenueCents - userEarningsCents
    let sign = revenueCents >= userEarningsCents ? '+' : ''
    let className = revenueCents >= userEarningsCents ? 'tables-green-font' : 'tables-red-font'
    let margin = (100 * diff) / revenueCents
    return <p className={className}>{`${sign}${margin.toFixed(1)}%`}</p>
}

const secondsToMinutesRender = (seconds: number = 0) => {
    if (Math.floor(seconds % 60) === 0) {
        return <p className='tables-cell'>{Math.floor(seconds / 60)}m</p>
    }
    return <p className='tables-cell'>{Math.floor(seconds / 60)}m {Math.floor(seconds % 60)}s</p>
}

const entryLinksRenderer = (liveLink: string = '', testLink: string = '') => {
    return (
        <div>
            <div><a href={liveLink}>Live</a></div>
            <div><a href={testLink}>Test</a></div>
        </div>
    )
}

export const TableRenderers = {
    [TableRendererTypes.default]: defaultRender,
    [TableRendererTypes.userIdRender]: userIdRender,
    [TableRendererTypes.relativeDateRender]: relativeDateRender,
    [TableRendererTypes.centsToDollarRender]: centsToDollarRender,
    [TableRendererTypes.profitRender]: profitRender,
    [TableRendererTypes.profitMarginRender]: profitMarginRender,
    [TableRendererTypes.secondsToMinutesRender]: secondsToMinutesRender,
    [TableRendererTypes.entryLinks]: entryLinksRenderer
}


// Users to Table must implement resize
export const useResize = (myRef) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            if (myRef.current === null) { return }
            setWidth(myRef.current.offsetWidth)
            setHeight(myRef.current.offsetHeight)
        }
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [myRef])

    return { width, height }
}

const Table: React.FC<TableProps> = ({ rows, width, height, columns, data }) => {

    let [sortKey, setSortKey] = useState<TableSortOrderKey>()
    let [filteredRows, setFilteredRows] = useState<any[]>(rows)

    useEffect(() => {
        let renderedRows = rows
        if (sortKey !== undefined) {
            let { order, key } = sortKey
            let sortOrder = order === TableSortOrder.Descending ? -1 : 1
            renderedRows = renderedRows.sort((a, b) => {
                if (b[key] === undefined) {
                    return 1 * sortOrder
                } else if (a[key] === undefined) {
                    return -1 * sortOrder
                }
                if (sortOrder > 0) {
                    return b[key] > a[key] ? -1 : 1
                } else {
                    return b[key] < a[key] ? -1 : 1
                }
            })
        }
        renderedRows = renderedRows.map((rowdata, idx) => {
            rowdata.index = idx + 1
            return rowdata
        })
        setFilteredRows(renderedRows)
    }, [sortKey, rows])

    const Cell = cellProps => {
        return cellProps.column.renderer(cellProps)
    }

    const components = {
        TableCell: Cell,
    }

    const onColumnSort = sortBy => {
        let { order } = sortBy
        let newOrder = (order !== TableSortOrder.Descending) ? TableSortOrder.Ascending : TableSortOrder.Descending
        setSortKey({
            ...sortBy,
            order: newOrder
        })
    }

    let table = useMemo(() =>
        <BaseTable
            className='new-table'
            fixed
            useIsScrolling
            columns={columns}
            data={filteredRows}
            components={components}
            width={width}
            height={height}
            sortBy={sortKey}
            onColumnSort={onColumnSort}>
        </BaseTable>,
        [filteredRows, sortKey, rows])

    if (rows === undefined || rows.length === 0) {
        return <div>No data</div>
    }

    return (
        <div className='admin-completes-container'>
            {filteredRows.length === 0 ? <div>No results</div> : table}
        </div >
    )
}
export default Table
