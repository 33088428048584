import './Offerwall.css'
import QuickSurveyOfferwall from './QuickSurveyOfferwall'
import React from 'react'
import Spinner from '../Spinner/Spinner'
import SweepstakeSurveyOfferwall from './SweepstakeSurveyOfferwall'
import { DailyPollProxy } from '../../sharedModels/DailyPollProxy'
import { QuickSurveyTemplateProxy } from '../../sharedModels/QuickSurveyProxy'
import { SweepstakeSurveyProxy, SweepstakeSurveyState } from '../../sharedModels/SweepstakeSurveyProxy'
import { UserProxy } from '../../sharedModels/UserProxy'
import { useLiveSurveyHook, OfferwallHook } from './Hook'
import { StandardMargin } from '../Styles/index'

interface OfferwalProps {
    eurekaUser: UserProxy
    setAnimateUserBalance: React.Dispatch<React.SetStateAction<boolean>>
}

export interface OfferwallState {
    isLoading: boolean,
    quickSurveys: QuickSurveyTemplateProxy[],
    activeQuickSurvey?: QuickSurveyTemplateProxy,
    sweepstakeSurveys: SweepstakeSurveyProxy[],
    activeSweepstakeSurvey?: SweepstakeSurveyProxy
}

export let Offerwall: React.FC<OfferwalProps> = (props) => {
    let { eurekaUser, setAnimateUserBalance } = props
    let { state, setReload, setSweepstakeSurvey, setActiveQuickSurvey }: OfferwallHook = useLiveSurveyHook(eurekaUser)
    let { sweepstakeSurveys, quickSurveys, isLoading, activeQuickSurvey, activeSweepstakeSurvey } = state

    if (isLoading) {
        return <Spinner />
    }

    return (
        <div className={`live-surveys-container ${StandardMargin.NormalAll}`}>
            <QuickSurveyOfferwall
                quickSurveys={quickSurveys}
                activeQuickSurvey={activeQuickSurvey}
                setActiveQuickSurvey={setActiveQuickSurvey}
                setReload={setReload}
                setAnimateUserBalance={setAnimateUserBalance} />
            <SweepstakeSurveyOfferwall
                sweepstakeSurveys={sweepstakeSurveys}
                activeSweepstakeSurvey={activeSweepstakeSurvey}
                setSweepstakeSurvey={setSweepstakeSurvey}
                setReload={setReload}
                setAnimateUserBalance={setAnimateUserBalance} />
        </div>
    )
}

export default Offerwall
