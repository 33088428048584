import { FraudActions, FRAUD_SNAPSHOT_UPDATE, FRAUD_REQUEST } from './Actions'
import { FraudState } from './Fraud'

export const reducer = (state: FraudState, action: FraudActions): FraudState => {
    switch (action.type) {
        case FRAUD_REQUEST:
            return {
                isLoading: true,
                users: []
            }
        case FRAUD_SNAPSHOT_UPDATE:

            return {
                isLoading: false,
                users: action.users
            }
    }
}