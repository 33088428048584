import './Ribbon.css'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'


interface RibbonProps {
    amountCentsString: String
}

const Ribbon = (props: RibbonProps) => {
    let { amountCentsString } = props
    return (
        <div className='ribbon-contianer'>
            <p className={`ribbon-title ${StandardFonts.LargeTextBold} ${StandardTextColors.Black}`}>Congrats, survey complete!</p>
            <div className='ribbon-image-container'>
                <div className='ribbon-image-label'>
                    <img className='ribbon-top-image' src='ribbon-top.svg'></img>
                    <p className={`ribbon-text`}>{amountCentsString}</p>
                </div>
                <img className='ribbon-bottom-image' src='ribbon-bottom.svg'></img>
            </div>
        </div>
    )
}

export default Ribbon