import firebase from 'firebase'
 import { UserProxy } from '../../../sharedModels/UserProxy'
import { CashoutRequestProxy } from '../../../sharedModels/CashoutRequestsProxy'
import { TransactionProxy } from '../../../sharedModels/TransactionProxy'
import { USER_LOOKUP_REQUEST, USER_LOOKUP_FAILURE, USER_LOOKUP_SUCCESS, USER_LOOKUP_INCREMENT_BALANCE } from './Actions'
import { UserLookupState } from './UserLookup'
import { oc } from 'ts-optchain'
import { reducer } from './Reducer'
import { useState, useReducer, useEffect } from 'react'

let initialState: UserLookupState = {
    isLoading: false,
    cashouts: [],
    transactions: []
}

// Interface that gets returned from the hook. Usually its in an array format, but that makes it harder to type! So that's
// why this exists.
export interface AdminLookUpUserHook {
    state: UserLookupState,
    setSearch: React.Dispatch<React.SetStateAction<string>>
    setAmountCents: React.Dispatch<React.SetStateAction<number>>
}

export const useLookUpUserHook = (): AdminLookUpUserHook => {

    let [search, setSearch] = useState('')
    let [amountCents, setAmountCents] = useState(0)
    let [state, dispatch] = useReducer(reducer, initialState)

    // Search for user
    useEffect(() => {
        let fetchData = async () => {

            dispatch({ type: USER_LOOKUP_REQUEST })

            let searchForUserAPI = firebase.functions().httpsCallable('admin-functions-searchUser')
            let results = await searchForUserAPI({ searchText: search })

            if (results.data === undefined || results.data === null) {
                dispatch({
                    type: USER_LOOKUP_FAILURE,
                    error: `Could not find user for ${search}`
                })
            } else {
                let d = results.data as { user: UserProxy, cashouts: CashoutRequestProxy[], transactions: TransactionProxy[] }
                dispatch({
                    type: USER_LOOKUP_SUCCESS,
                    user: d.user,
                    cashouts: d.cashouts,
                    transactions: d.transactions
                })
            }
        }

        if (search.length > 0) {
            fetchData()
        }

    }, [search])


    // Increment user balance
    useEffect(() => {
        let fetchData = async () => {
            let userId = oc(state.user).userId('No user id')
            dispatch({ type: USER_LOOKUP_REQUEST })

            let incrementBalanceAPI = firebase.functions().httpsCallable('admin-functions-creditUser')
            let results = await incrementBalanceAPI({ amountCents: amountCents, userId: userId })

            if (results.data === undefined || results.data === null) {
                dispatch({
                    type: USER_LOOKUP_FAILURE,
                    error: `Could not find user for ${search}`
                })
            } else {
                let d = results.data as { user: UserProxy, cashouts: CashoutRequestProxy[], transactions: TransactionProxy[] }
                dispatch({
                    type: USER_LOOKUP_SUCCESS,
                    user: d.user,
                    cashouts: d.cashouts,
                    transactions: d.transactions
                })
            }
        }

        if (amountCents > 0) {
            fetchData()
        }

    }, [amountCents])

    return { state, setSearch, setAmountCents }
}
