import './StagingCard.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'
import { SweepstakeSurveyProxy } from '../../sharedModels/SweepstakeSurveyProxy'

interface TerminatedCardProps {
    sweepstakeSurvey: SweepstakeSurveyProxy
    onClick: () => void
}

const TerminatedCard = (props: TerminatedCardProps) => {
    let { sweepstakeSurvey, onClick } = props
    let { userEarningsTerminateString } = sweepstakeSurvey
    return (
        <div className='staging-card-container'>
            <p className={`staging-card-title-text ${StandardFonts.LargeTextBold} ${StandardTextColors.Purple}`}>Finished</p>
            <p className={`staging-card-subtitle-text ${StandardFonts.Legal} ${StandardTextColors.Black}`}>You deserve 2¢ for your time.</p>
            <div className='staging-card-content-pre'>
                <div className='staging-card-image-row'>
                    <img className='staging-card-detail-image' src='surveyTerminated.svg' />
                </div>
                <div className='staging-card-complete-content'>
                    <p className={`staging-card-info-text-completed ${StandardFonts.Legal} ${StandardTextColors.Black}`}>You earned a partial reward because this survey ended early. This happens when a Brand is looking for respondents with specific experiences with a product or service.</p>
                    <p className={`staging-card-info-text-completed ${StandardFonts.Legal} ${StandardTextColors.Black}`}>Please make sure your profile  is filled out completely. We’ll find you another survey.</p>
                    <div className='staging-card-button-row'>
                        <Button className='staging-card-button-padding'
                            title={`Claim ${userEarningsTerminateString}`}
                            textAlignment={ButtonTextAlignment.Center}
                            type={ButtonType.Yellow}
                            onClick={onClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TerminatedCard