import firebase from 'firebase'
import { LogInState } from "./Login"
import { reducer } from './Reducer'
import { useState, useReducer, useEffect } from 'react'
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN_SUBMIT, LOG_IN_FORGOT_PASSWORD_SHOW, LOG_IN_FORGOT_PASSWORD_SEND } from './Actions'

export interface LogInHook {
    state: LogInState,
    setShowForgotPassword: React.Dispatch<React.SetStateAction<boolean | undefined>>
    setForgotPasswordEmail: React.Dispatch<React.SetStateAction<string | undefined>>
}

let initialState: LogInState = {
    isLoading: false,
    showForgotPassword: undefined,
    forgotPasswordEmail: undefined
}

export const useLoginHook = (): LogInHook => {

    let [showForgotPassword, setShowForgotPassword] = useState<boolean>()
    let [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>()

    let [state, dispatch] = useReducer(reducer, initialState)

    // Attempt log in
    useEffect(() => {
        console.log('Snd forgot password to ' + forgotPasswordEmail)
        let fetchData = async () => {
            if (forgotPasswordEmail === undefined) return
            console.log('Forgot password')
            console.log(`${forgotPasswordEmail}`)
            let authResult = await firebase.auth().sendPasswordResetEmail(forgotPasswordEmail)
            dispatch({ type: LOG_IN_FORGOT_PASSWORD_SEND })
        }

        if (forgotPasswordEmail !== undefined) {
            fetchData()
        }
    }, [forgotPasswordEmail])


    // Attempt log in
    useEffect(() => {
        console.log('Showing forgot password ' + showForgotPassword)
        if (showForgotPassword === undefined) { return }
        dispatch({
            type: LOG_IN_FORGOT_PASSWORD_SHOW,
            show: showForgotPassword
        })
    }, [showForgotPassword])

    return {
        state: state,
        setShowForgotPassword: setShowForgotPassword,
        setForgotPasswordEmail: setForgotPasswordEmail
    }
}
