import { CashoutState } from './Cashouts'
import { CashoutsActions, CASHOUTS_MARK_AS_MANUAL, CASHOUTS_MARK_AS_SKIP, CASHOUTS_SNAPSHOT_UPDATE, CASHOUTS_REQUEST, CASHOUTS_SEND_PAYMENT } from './Actions'

export const reducer = (state: CashoutState, action: CashoutsActions): CashoutState => {
    switch (action.type) {
        case CASHOUTS_REQUEST:
            return {
                isLoading: true,
                cashouts: state.cashouts
            }
        case CASHOUTS_SNAPSHOT_UPDATE:
            return {
                isLoading: false,
                cashouts: action.cashouts
            }
        case CASHOUTS_SEND_PAYMENT:
            return {
                isLoading: false,
                cashouts: state.cashouts
            }
        case CASHOUTS_MARK_AS_SKIP:
            return {
                isLoading: false,
                cashouts: state.cashouts
            }
        case CASHOUTS_MARK_AS_MANUAL:
            return {
                isLoading: false,
                cashouts: state.cashouts
            }
    }
}