import DataTable, { IDataTableColumn } from 'react-data-table-component'
import React from 'react'
import moment from 'moment'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

interface UserExitsTableProps {
    sweepstakeSurveys: SweepstakeSurveyProxy[]
}


const UserExitsTable: React.FC<UserExitsTableProps> = ({ sweepstakeSurveys }) => {

    if (sweepstakeSurveys === undefined || sweepstakeSurveys.length === 0) {
        return <div>No transactions</div>
    }

    let options = {
        persistSelectedOnSort: false,
        persistSelectedOnPageChange: false
    }

    return (
        <div>
            <DataTable
                columns={transactionsColumns(sweepstakeSurveys)}
                data={sweepstakeSurveys}
                keyField='firestoreId'
                paginationServerOptions={options}
                fixedHeader={true}
                defaultSortField='endTimestamp'
                defaultSortAsc={false}
                noHeader={true}
            />
        </div >
    )
}

const transactionsColumns = (sweepstakeSurveys: SweepstakeSurveyProxy[]): IDataTableColumn<SweepstakeSurveyProxy>[] => {
    return [
        {
            name: 'Date',
            selector: 'endTimestamp',
            width: '150px',
            format: (row: SweepstakeSurveyProxy) => {
                var { endTimestamp } = row
                endTimestamp = endTimestamp || 0
                let date = moment.unix(endTimestamp).fromNow()
                return `${date}`
            }
        },
        {
            name: 'UserId',
            selector: 'userId',

            width: '250px',
            cell: (row: SweepstakeSurveyProxy) => {
                let userId = row.userId
                let emojis = ['🧑🏼‍🌾', '👨🏻‍🎤', '🧑🏽‍🎤', '🧑🏿‍🏫', '👩🏽‍💼', '🧑🏼‍🏭', '👨🏻‍💻', '👩🏻‍🔬', '🧑🏾‍🚒', '🦸🏽', '🎅🏿', '🦹🏾']
                var item = emojis[Math.floor(Math.random() * emojis.length)]

                return (
                    <span>
                        <a href={`http://www.eurekarewards.com/admin?userId=${userId}`} target='_blank'>{item}</a><span className='tables-monospace'>{userId}</span>
                    </span>
                )
            }
        },
        {
            name: 'Reason',
            selector: 'userExitReason',
            right: true,
            width: '200px',
        },
        {
            name: 'Description',
            selector: 'userExitDescription',
            wrap: true,
            width: '300px'
        },
        {
            name: 'Duration',
            selector: 'userDurationSeconds',
            right: true,
            width: '120px',
            format: (row: SweepstakeSurveyProxy) => {
                let { userDurationSeconds } = row
                userDurationSeconds = userDurationSeconds || 0
                return `${Math.floor(userDurationSeconds / 60)}m ${Math.floor(userDurationSeconds % 60)}s`
            }
        },
        {
            name: 'Source',
            selector: 'source',
            right: true,
            width: '100px'
        }
    ]
}

export default UserExitsTable
