import { CashoutActions, CASHOUT_FAILURE, CASHOUT_SUBMITTED, CASHOUT_SUCCESS } from './Actions'
import { CashoutState } from './Cashout'

export const reducer = (state: CashoutState, action: CashoutActions): CashoutState => {
    switch (action.type) {
        case CASHOUT_SUBMITTED:
            return {
                isLoading: true,
                success: false,
                error: undefined
            }
        case CASHOUT_SUCCESS:
            return {
                isLoading: false,
                success: true,
                error: undefined
            }
        case CASHOUT_FAILURE:
            return {
                isLoading: false,
                success: false,
                error: 'Opps something went wrong. Please contact support@eureakrewards.com for assistance'
            }
    }
}