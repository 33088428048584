import firebase from 'firebase'
import { COMPLETED_SURVEYS_REQUEST, COMPLETED_SURVEYS_SUCCESS } from './Actions'
import { CompletedSurveysState } from './CompletedSurveys'
import { Survey } from '../../sharedModels/SurveyProxy'
import { UserProxy } from '../../sharedModels/UserProxy'
import { reducer } from './Reducer'
import { useReducer, useEffect } from 'react'
import { CashoutRequestProxy } from '../../sharedModels/CashoutRequestsProxy'

let initialState: CompletedSurveysState = {
    isLoading: false,
    completedSurveys: [],
    cashouts: []
}

// Interface that gets returned from the hook. Usually its in an array format, but that makes it harder to type! So that's
// why this exists.
export interface CompletedSurveysHook {
    state: CompletedSurveysState,
}

export const useCompletedSurveysHook = (eurekaUser: UserProxy): CompletedSurveysHook => {

    let [state, dispatch] = useReducer(reducer, initialState)

    // Search for user
    useEffect(() => {
        let fetchData = async () => {

            dispatch({ type: COMPLETED_SURVEYS_REQUEST })

            let getCompletedSurveysAPI = firebase.functions().httpsCallable('web-functions-getCompletedSurveys')
            let { userId } = eurekaUser
            let results = await getCompletedSurveysAPI({ userId: userId })

            let d = results.data as { surveys: Survey[], cashouts: CashoutRequestProxy[] }

            dispatch({
                type: COMPLETED_SURVEYS_SUCCESS,
                completedSurveys: d.surveys,
                cashouts: d.cashouts
            })
        }

        fetchData()
    }, [])

    return { state }
}
