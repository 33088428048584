import firebase from 'firebase'
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { TERMINATES_SNAPSHOT_UPDATE } from './Actions'
import { TerminatesState } from './Terminates'
import { TransactionProxy, TransactionType } from '../../../sharedModels/TransactionProxy'
import { reducer } from './Reducer'
import { useReducer, useEffect } from 'react'
import { SweepstakeSurveyState, SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

let initialState: TerminatesState = {
    isLoading: true,
    terminates: [],
}

export interface TerminatesHook {
    state: TerminatesState,
}

export const terminatesSnapshotOnUpdate = (observer: {
    next?: (snapshot: QuerySnapshot) => void
    error?: (error: Error) => void
    complete?: () => void
}) => {
    return firebase.firestore()
        .collection('sweepstakeSurvey')
        .where('clientState', '==', SweepstakeSurveyState.Terminated)
        .orderBy('endTimestamp', 'desc')
        .limit(2)
        .onSnapshot(observer)
}

export const useTerminatesHook = (): TerminatesHook => {
    let [state, dispatch] = useReducer(reducer, initialState)

    // Snapshot listener
    useEffect(() => {
        const unsubscribe = terminatesSnapshotOnUpdate({
            next: querySnapshot => {
                const updatedTerminates = querySnapshot.docs
                    .map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
                console.log(`Snapshot query just updated ${updatedTerminates.length} docs`)
                dispatch({ type: TERMINATES_SNAPSHOT_UPDATE, terminates: updatedTerminates })
            }
        })

        return unsubscribe
    }, [])

    useEffect(() => {
        let fetchData = async () => {
            console.log('Fired once!')
            let terminateDocs = await firebase.firestore()
                .collection('sweepstakeSurvey')
                .where('clientState', '==', SweepstakeSurveyState.Terminated)
                .orderBy('endTimestamp', 'desc')
                .limit(200)
                .get()
            const updatedTerminates = terminateDocs.docs.map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
            dispatch({ type: TERMINATES_SNAPSHOT_UPDATE, terminates: updatedTerminates })
        }

        fetchData()
    }, [])

    return {
        state: state,
    }
}
