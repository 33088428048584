import firebase from 'firebase'
import { LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE } from './Actions'
import { LucidSweepstakeSurveysState } from './LucidSweepstakeSurvey'
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { SweepstakeSurveyProxy, SweepstakeSurveyState, SweepstakeSurveySource } from '../../../sharedModels/SweepstakeSurveyProxy'
import { reducer } from './Reducer'
import { useReducer, useEffect } from 'react'

let initialState: LucidSweepstakeSurveysState = {
    isLoading: true,
    sweepstakeSurveys: [],
}

export interface CompletesHook {
    state: LucidSweepstakeSurveysState,
}

export const completesSnapshotOnUpdate = (observer: {
    next?: (snapshot: QuerySnapshot) => void
    error?: (error: Error) => void
    complete?: () => void
}) => {
    return firebase.firestore()
        .collection('sweepstakeSurvey')
        .where('source', '==', SweepstakeSurveySource.Lucid)
        .orderBy('endTimestamp', 'desc')
        .limit(2)
        .onSnapshot(observer)
}

export const useSweepstakesHook = (): CompletesHook => {
    let [state, dispatch] = useReducer(reducer, initialState)

    // Snapshot listener
    useEffect(() => {
        const unsubscribe = completesSnapshotOnUpdate({
            next: querySnapshot => {
                const updatedSweepstakes = querySnapshot.docs.map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
                console.log(`Snapshot query just updated ${updatedSweepstakes.length} docs`)
                dispatch({ type: LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE, sweepstakes: updatedSweepstakes })
            }
        })

        return unsubscribe
    }, [])

    // Initial fetch
    useEffect(() => {
        let fetchData = async () => {
            console.log('Fired once!')
            let ref = await firebase.firestore()
                .collection('sweepstakeSurvey')
                .where('source', '==', SweepstakeSurveySource.Lucid)
                .orderBy('endTimestamp', 'desc')
                .limit(1000)
                .get()

            const updatedSweepstakes = ref.docs.map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
            console.log(`Updated ${updatedSweepstakes.length} docs`)
            dispatch({ type: LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE, sweepstakes: updatedSweepstakes })
        }

        fetchData()
    }, [])

    return {
        state: state,
    }
}
