import './StagingCard.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import React from 'react'
import Shelf, { ShelfItem } from '../Shelf/Shelf'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'
import { SweepstakeSurveyProxy } from '../../sharedModels/SweepstakeSurveyProxy'

interface CompletedCardProps {
    sweepstakeSurvey: SweepstakeSurveyProxy
    onClick: () => void

}

const CompletedCard = (props: CompletedCardProps) => {
    let { sweepstakeSurvey, onClick } = props

    let { userEarningsString, userSweepstakesString, topic } = sweepstakeSurvey
    let shelfItems: ShelfItem[] = [{
        text: '💰 Reward',
        value: userEarningsString
    },
    {
        text: '🎟 Sweepstake',
        value: userSweepstakesString
    }]

    return (
        <div className='staging-card-container'>
            <p className={`staging-card-title-text ${StandardFonts.LargeTextBold} ${StandardTextColors.Purple}`}>Congrats!</p>
            <p className={`staging-card-subtitle-text ${StandardFonts.Legal} ${StandardTextColors.Black}`}>{`${userSweepstakesString} Sweepstake entered`}</p>

            <div className='staging-card-content-pre'>
                <div className='staging-card-image-row'>
                    <img className='staging-card-detail-image-pre' src='sweepstakeStaging.png' />
                    <Shelf items={shelfItems} />
                </div>
                <div className='staging-card-complete-content'>
                    <p className={`staging-card-info-text-completed ${StandardFonts.Legal} ${StandardTextColors.Black}`}>{`The affiliate ${topic} appreciates your input! The winner of the cash sweepstakes will be announced in 4-7 days. Best luck!`}</p>
                    <div className='staging-card-button-row'>
                        <Button className='staging-card-button-padding'
                            title={`Claim ${userEarningsString}`}
                            textAlignment={ButtonTextAlignment.Center}
                            type={ButtonType.Yellow}
                            onClick={onClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletedCard