import firebase from 'firebase'
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { SweepstakeSurveyState, SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'
import { USER_EXITS_SNAPSHOT_UPDATE } from './Actions'
import { UserExitsState } from './UserExits'
import { reducer } from './Reducer'
import { useReducer, useEffect } from 'react'

let initialState: UserExitsState = {
    isLoading: true,
    sweepstakeSurveys: [],
}

export interface UserExitsHook {
    state: UserExitsState,
}

export const completesSnapshotOnUpdate = (observer: {
    next?: (snapshot: QuerySnapshot) => void
    error?: (error: Error) => void
    complete?: () => void
}) => {
    return firebase.firestore()
        .collection('sweepstakeSurvey')
        .where('clientState', '==', SweepstakeSurveyState.UserExited)
        .orderBy('endTimestamp', 'desc')
        .limit(2)
        .onSnapshot(observer)
}

export const useUserExitsHook = (): UserExitsHook => {
    let [state, dispatch] = useReducer(reducer, initialState)

    // Snapshot listener
    useEffect(() => {
        const unsubscribe = completesSnapshotOnUpdate({
            next: querySnapshot => {
                const updatedSweepstakeSurveys = querySnapshot.docs.map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
                console.log(`Snapshot query just updated ${updatedSweepstakeSurveys.length} docs`)
                dispatch({ type: USER_EXITS_SNAPSHOT_UPDATE, sweepstakeSurveys: updatedSweepstakeSurveys })
            }
        })
        return unsubscribe
    }, [])

    useEffect(() => {
        let fetchData = async () => {
            console.log('Fired once!')
            let docs = await firebase.firestore()
                .collection('sweepstakeSurvey')
                .where('clientState', '==', SweepstakeSurveyState.UserExited)
                .orderBy('endTimestamp', 'desc')
                .limit(250)
                .get()
            const updatedSweepstakeSurveys =
                docs.docs.map((docSnapshot: QueryDocumentSnapshot) => <SweepstakeSurveyProxy>docSnapshot.data())
            dispatch({ type: USER_EXITS_SNAPSHOT_UPDATE, sweepstakeSurveys: updatedSweepstakeSurveys })
        }

        fetchData()
    }, [])

    return {
        state: state,
    }
}
