import './UserLookup.css'
import CashoutsTable from '../Tables/CashoutsTable'
import React, { useState, useMemo, useRef, useReducer, useEffect, useCallback } from 'react'
import TransactionsTable from '../Tables/TransactionsTable'
import UserProfile from './UserProfile'
import { CashoutRequestProxy } from '../../../sharedModels/CashoutRequestsProxy'
import { InputGroup, FormControl, Button, Spinner } from 'react-bootstrap'
import { TransactionProxy } from '../../../sharedModels/TransactionProxy'
import { UserProxy } from '../../../sharedModels/UserProxy'
import { useLookUpUserHook, AdminLookUpUserHook } from './Hook'
import { useResize } from '../Tables/Table'

import url from 'url'
interface UserLookupProps {
    path: string
}

export interface UserLookupState {
    isLoading: boolean,
    user?: UserProxy,
    cashouts: CashoutRequestProxy[],
    transactions: TransactionProxy[],
    error?: string
}

export let UserLookup: React.FC<UserLookupProps> = (props) => {

    let [query, setQuery] = useState('')
    let { state, setSearch, setAmountCents }: AdminLookUpUserHook = useLookUpUserHook()
    let { isLoading, user, transactions, cashouts } = state
    const transactionTableComponentRef = useRef(null)
    const cashoutsTableComponentRef = useRef(null)

    const { width, height } = useResize(transactionTableComponentRef)

    let userProfile = useMemo(() => <UserProfile user={user} setAmountCents={setAmountCents} />, [state])
    let transactionsTable = useMemo(() => <TransactionsTable transactions={transactions} width={width} height={height} />, [state])
    let cashoutsTable = useMemo(() => <CashoutsTable cashouts={cashouts} allowButtons={false} width={width} height={height} />, [state])

    // onMount
    useEffect(() => {
        let queryParameters = url.parse(window.location.href, true).query
        let { userId } = queryParameters
        if (!userId) { return }
        let userIdString: string = userId.toString()
        if (userIdString && userIdString.length > 0) {
            setQuery(userIdString)
            setSearch(userIdString)
        }
    }, [])

    let keyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setSearch(query)
        }
    }

    return (
        <div className='userlookup-container'>
            <div className='userlookup-searchbar'>
                <InputGroup className='mb-3' size='lg'>
                    {isLoading ?
                        <InputGroup.Prepend>
                            <Spinner animation='border' variant='primary' />
                        </InputGroup.Prepend>
                        : null}
                    <FormControl
                        value={query}
                        placeholder='UserId or email'
                        aria-describedby='basic-addon2'
                        onKeyPress={keyPressed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setQuery(event.target.value)
                            if (event.target.value.length === 'eoeguT2Am9OzSUw2nmF9J1cLhwk1'.length) {
                                setSearch(event.target.value)
                            }
                        }}
                    />
                    <InputGroup.Append>
                        <Button className='userlookup-searchbar-button' onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            setSearch!(query)
                        }} variant='primary'>Search</Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
            <div className='userlookup-search-results-container'>
                <div className='userlookup-user'>
                    {userProfile}
                </div>
                <div className='userlookup-table-container'>
                    <p>Transactions</p>
                    <div className='userlookup-transaction-table-container' ref={transactionTableComponentRef}>
                        {transactionsTable}
                    </div>
                    <p>Cashouts</p>
                    <div className='userlookup-transaction-table-container' ref={cashoutsTableComponentRef}>
                        {cashoutsTable}
                    </div>
                </div>

            </div>
        </div >
    )
}

export default UserLookup