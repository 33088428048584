import './SignUp.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import React, { useState } from 'react'
import Select from 'react-select'
import Spinner from '../Spinner/Spinner'
import TextField from '../TextField/TextField'
import { Redirect } from 'react-router-dom'
import { StandardDropShadows, StandardCornerRadii, StandardBackgroundColors, StandardFonts, StandardTextColors } from '../Styles/index'
import { useAuth } from '../../Authentication/Hook'

interface SignUpProps { }

export interface SignUpState {
    isLoading: boolean
    success: boolean
    error?: string
}

export let SignUp: React.FC<SignUpProps> = (props) => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [gender, setGender] = useState<string>('Female')
    const [birthday, setBirthday] = useState<string>('')
    const [zipCode, setZipCode] = useState<string>('')
    const [showErrors, setShowErrors] = useState<boolean>(false)

    let { state, setSignUpCredentials } = useAuth()
    let { firebaseAuthUser, eurekaUser, signUpLoading, signUpError } = state

    console.log('Re-render')
    console.log(firebaseAuthUser)
    console.log(eurekaUser)
    console.log(signUpError)

    if (eurekaUser) {
        console.log('SUCCESS! REDIRECT')
        return <Redirect to='/surveys' />
    }

    let onChange = (selectedOption: string) => {
        setGender(selectedOption)
    }
    let selectedOptions = [gender].map(option => {
        return { value: option, label: option, color: `#2AD273` }
    })

    let options = ['Female', 'Male'].map(option => {
        return { value: option, label: option, color: `#FED51E` }
    })

    let sanityCheck = (): boolean => {
        return email.length > 0 &&
            password.length > 0 &&
            gender.length > 0 &&
            birthday.length > 0 &&
            zipCode.length > 0
    }

    return (
        <div className='sign-up-container'>
            <div className='sign-up-left'>
                {signUpLoading && <Spinner absolutePosition={true} />}
                <div className='sign-up-input-container'>
                    <p className={`sign-up-welcome-label ${StandardFonts.LargeTextBold} ${StandardTextColors.Black}`}>Welcome to Eureka</p>
                    <TextField
                        placeholder='Email'
                        showError={showErrors && email.length === 0}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(event.target.value)
                        }} />
                    <TextField
                        placeholder='Password'
                        isPassword={true}
                        showError={showErrors && password.length === 0}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value)
                        }} />

                    <div className='sign-up-gender-birthday'>
                        <div style={{ display: 'flex', flex: 1 }}>
                            <Select className={`sign-up-gender-picker ${StandardFonts.SmallText}`}
                                options={options}
                                defaultValue={selectedOptions}
                                onChange={(e => {
                                    onChange(e.value)
                                })}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#F1F6FD',
                                        primary: '#7B49FB',
                                    },
                                })} />
                        </div>
                        <div style={{ marginLeft: '20px', display: 'flex', flex: 1 }}>
                            <TextField
                                placeholder='Birthday'
                                isDate={true}
                                showError={showErrors && birthday.length === 0}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    console.log(`Text changed! ${event.target.value}`)
                                    let formattedBirthday = event.target.value.split('-').join('/')
                                    setBirthday(formattedBirthday)
                                }} />
                        </div>
                    </div>
                    <TextField
                        placeholder='Zip'
                        showError={showErrors && zipCode.length === 0}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            console.log(`Text changed! ${event.target.value}`)
                            setZipCode(event.target.value)
                        }} />

                    <p className={`sign-up-legal ${StandardFonts.Legal} ${StandardTextColors.TextGrey}`}>By signing up, your confirm that you accept our <a href='www.eurekarewards.com/terms' target='_blank'>Terms of Service</a> and <a href='www.eurekarewards.com/privacy-policy' target='_blank'>Privacy policy</a></p>

                    <Button
                        title='Sign Up'
                        type={ButtonType.Purple}
                        textAlignment={ButtonTextAlignment.Center}
                        onClick={() => {
                            setShowErrors(true)
                            let validInputs = sanityCheck()
                            if (validInputs) {
                                console.log(`Create Account!`)
                                setSignUpCredentials({
                                    email: email,
                                    password: password,
                                    zipCode: zipCode,
                                    birthday: birthday,
                                    gender: gender,
                                })
                            }
                        }} />

                    {signUpError && <p className={`sign-up-legal ${StandardFonts.Legal} ${StandardTextColors.Red}`}>{signUpError}</p>}
                </div>

            </div>
            <div className={`sign-up-right`}>
                <div className='sign-up-right-text-container'>
                    <img src='landing.svg'></img>
                    <p className={`${StandardFonts.H1} ${StandardTextColors.Black}`}>Surveys with cash payouts</p>
                    <p className={`${StandardFonts.LargeText} ${StandardTextColors.Black}`}>Earn cash rewards and enter sweepstakes for completing surveys</p>
                </div>
            </div>
        </div >
    )
}

export default SignUp
