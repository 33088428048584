import { LucidInventoryActions, LUCID_INVENTORY_SNAPSHOT_UPDATE, LUCID_INVENTORY_REQUEST, LUCID_INVENTORY_ENGLISH_QUALS, LUCID_INVENTORY_REQUEST_ENGLISH_QUALS, LUCID_INVENTORY_RESET_ENGLISH_QUALS } from './Actions'
import { LucidLiveInventoryState } from './LucidLiveInventory'

export const reducer = (state: LucidLiveInventoryState, action: LucidInventoryActions): LucidLiveInventoryState => {
    switch (action.type) {
        case LUCID_INVENTORY_REQUEST:
            return {
                isLoading: true,
                lucidSurveyTemplates: []
            }
        case LUCID_INVENTORY_SNAPSHOT_UPDATE:
            return {
                isLoading: false,
                lucidSurveyTemplates: action.lucidSurveyTemplates
            }
        case LUCID_INVENTORY_RESET_ENGLISH_QUALS:
        case LUCID_INVENTORY_REQUEST_ENGLISH_QUALS:
            return {
                isLoading: false,
                lucidSurveyTemplates: state.lucidSurveyTemplates,
                englishQualifications: undefined
            }
        case LUCID_INVENTORY_ENGLISH_QUALS:
            return {
                isLoading: false,
                lucidSurveyTemplates: state.lucidSurveyTemplates,
                englishQualifications: action.englishQualifications
            }
    }
}