import './Help.css'
import React from 'react'

function Help() {
    return (
        <div className='container'>
            <div className='textContainer'>
                <h1 className='title'>Help</h1>

                <p className='subsectionTitle'>What is Eureka?</p>
                <p className='subsectionText'>Eureka is a platform where users can earn money for taking surveys. Eureka is only available in the US.</p>

                <p className='subsectionTitle'>What is a Sweepstake Survey? </p>
                <p className='subsectionText'>Sweepstake Surveys are longer-form surveys that pay more and enter you into a cash sweepstake upon completion. Sweepstakes surveys are unlocked after completing 4 Quick surveys.</p>

                <p className='subsectionTitle'>Why am I not getting more surveys?</p>
                <p className='subsectionText'>The availability of surveys is constantly fluctuating. We’ll alert you when you qualify for new surveys, so be sure to fill out your profile and turn notifications on! Pro tip: Monday - Thursday are the best times to take surveys.</p>

                <p className='subsectionTitle'>Why am I getting fewer surveys when I am close to cashing out?</p>
                <p className='subsectionText'>Our survey matching algorithms are unaware of your current balance and what you're likely seeing is just a normal downtown in the availability of surveys.</p>

                <p className='subsectionTitle'>What is the minimum cashout amount?</p>
                <p className='subsectionText'>The minimum amount required to cash out is $10.</p>

                <p className='subsectionTitle'>How do I cashout? </p>
                <p className='subsectionText'>To cash out, simply press your account balance on the top left of the home screen, followed by the yellow "Cashout" button. Please note that cashout requests take 3 to 5 business days to process.</p>

                <p className='subsectionTitle'>What are the cashout options?</p>
                <p className='subsectionText'>PayPal is the only cashout option at the moment. </p>

                <p className='subsectionTitle'>How do I complete my Profile?</p>
                <p className='subsectionText'>To complete your profile, make sure to fill out all necessary information from Birthday to Political Views to Job Title in the Profile tab. Once filled out, each field will have a green checkmark next to it.</p>

                <p className='subsectionTitle'>My profile is encrypted and anonymous, what does that mean?</p>
                <p className='subsectionText'>Eureka user information is secure and confidential.</p>

                <p className='subsectionTitle'>Can I change my email address?</p>
                <p className='subsectionText'>Unfortunately we cannot change the email address associated with your Eureka account. Please note that we will ask you for a separate PayPal email address upon Cashout and that this email address may be different.</p>

                <p className='subsectionTitle'>Why was I disqualified from a survey?</p>
                <p className='subsectionText'>Brands, researchers, and academics are always looking for insights from you! However, sometimes they are looking for consumers who have certain qualifications or experiences. When you do not meet their criteria, you get disqualified from the survey. However, Eureka values your time and will pay you even when you get disqualified.</p>

                <p className='subsectionTitle'>Why is my account locked or flagged as fraud?</p>
                <p className='subsectionText'>Your account may be locked or flagged for fraud if you attempt too many surveys in a short period of time without being able to complete any. Please only enter surveys if you intend to complete them.</p>

                <p className='subsectionTitle'>Can I change my PayPal email for cashing out?</p>
                <p className='subsectionText'>Yes, you can change the PayPal email supplied at cashout if the cashout has not yet been processed. Please reach out to support@eurekarewards.com to update your PayPal email.</p>

                <p className='subsectionTitle'>I was not rewarded after completing a survey.</p>
                <p className='subsectionText'>Please reach out to support@eurekarewards.com if you believe you are missing a reward after completing a survey.</p>

                <p className='subsectionTitle'>A survey forced me to exit and forfeit my reward.</p>
                <p className='subsectionText'>Surveys are made by third-party providers and although we do our best to screen every survey for bugs, it looks like you've found one. Please reach out to support@eurekarewards.com if you continue to experience issues.</p>

            </div>
        </div>
    )
}

export default Help
