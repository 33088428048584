import './StagingCard.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import React, { useState } from 'react'
import firebase from 'firebase'
import moment from 'moment'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'
import { SweepstakeSurveyProxy } from '../../sharedModels/SweepstakeSurveyProxy'
const Timestamp = firebase.firestore.Timestamp

interface StartedCardProps {
    sweepstakeSurvey: SweepstakeSurveyProxy
    onClick: () => void
}

const StartedCard = (props: StartedCardProps) => {
    let { sweepstakeSurvey, onClick } = props
    let { startTimestamp } = sweepstakeSurvey
    let [duration, setDuration] = useState<number>(0)
    startTimestamp = startTimestamp || Timestamp.now().seconds

    let durationString = moment.utc(moment.duration(Timestamp.now().seconds - startTimestamp, "seconds").asMilliseconds()).format("mm:ss")
    setTimeout(() => {
        setDuration(duration + 1)
    }, 1000)

    return (
        <div className='staging-card-container'>
            <p className={`staging-card-title-text ${StandardFonts.LargeTextBold} ${StandardTextColors.Purple}`}>Survey In Progress</p>
            <p className={`staging-card-subtitle-text ${StandardFonts.Legal} ${StandardTextColors.Black}`}>{`${durationString}`}</p>

            <div className='staging-card-content-started'>
                <img className='staging-card-detail-image-started' src='sweepstakeStaging.png' />
                <p className={`staging-card-info-text ${StandardFonts.Legal} ${StandardTextColors.Black}`}>This survey is in progress. Once complete, you will be entered in the $16 Sweepstake as well as credited 34¢.</p>
                <p className={`staging-card-info-text ${StandardFonts.Legal} ${StandardTextColors.Black}`}>If for any reason you want to leave this survey, you may do so and we'll find you a new one to take.</p>
                <Button className='staging-card-button-padding'
                    title={`Leave Survey`}
                    type={ButtonType.Purple}
                    textAlignment={ButtonTextAlignment.Center}
                    onClick={onClick} />
            </div>
        </div>
    )
}

export default StartedCard