export interface Survey {
    backgroundImageUrl: string
    completedHumanReadableDate?: string // Sent by client
    createdTimestamp: number
    endTimestamp?: number // Set by server
    firestoreId: string
    revenueCents?: number // Set by callback
    startTimestamp?: number // Set by server
    topic: string
    userDurationSeconds?: number // Calculated
    userEarningsCents: number
    userEarningsString: string
    userEarningsTerminateCents: number
    userEarningsTerminateString: string
    userEstimatedDurationSeconds: number
    userEstimatedDurationString: string
    userId: string
}

export interface SurveyQuestion {
    format: SurveyQuestionFormat
    questionText: string
}

export enum SurveyQuestionFormat {
    Date = 'Date',
    Checkbox = 'Checkbox',
    CheckboxScreener = 'CheckboxScreener',
    Complete = 'Complete',
    FreeResponse = 'FreeResponse',
    Intro = 'Intro',
    MultipleChoice = 'MultipleChoice',
    MultipleChoiceScreener = 'MultipleChoiceScreener'
}

export interface SurveyCheckboxQuestion extends SurveyQuestion {
    randomize: boolean
    responseOptions: string[]
    responses?: string[] // Set when user answers questions
}

export interface SurveyCheckboxScreener extends SurveyQuestion {
    qualifyingPrecodesMap: { [key: string]: boolean }
    questionId: string
    randomize: boolean
    responseOptions: string[]
    responsePrecodeMap: { [key: string]: string }

    responses?: string[] // Set when user answers questions
}

export interface SurveyCompleteQuestion extends SurveyQuestion {
    userEarningsCents: number
    userEarningsString: string
    userFeedback?: string
}

export interface SurveyFreeResponseQuestion extends SurveyQuestion {
    minCharCount: number
    numerical: boolean
    response?: string // Set when user answers question
}

export interface SurveyIntroQuestion extends SurveyQuestion {
    topic: string,
    userEarningsString: string,
    waitDurationSeconds: number
}

export interface SurveyMultipleChoiceQuestion extends SurveyQuestion {
    randomize: boolean
    responseOptions: string[]
    response?: string // Set when user answers question
}

export interface SurveyMultipleChoiceScreener extends SurveyQuestion {
    qualifyingPrecodesMap: { [key: string]: boolean }
    questionId: string
    randomize: boolean
    responseOptions: string[]
    responsePrecodeMap: { [key: string]: string }

    response?: string // Set when user answers question
}
