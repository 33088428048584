import './Button.css'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'

interface ButtonProps {
    className?: string,
    title: string,
    type: ButtonType,
    textAlignment?: ButtonTextAlignment
    onClick: () => void
}

export enum ButtonTextAlignment {
    Left = 'Left',
    Center = 'Center'
}

export enum ButtonType {
    Yellow = 'Yellow',
    Grey = 'Grey',
    Green = 'Green',
    Purple = 'Purple',
    Red = 'Red',
    Locked = 'Locked',

    MultipleChoiceUnselected = 'MultipleChoiceUnselected',
    MultipleChoiceSelected = 'MultipleChoiceSelected',

    CheckboxUnselected = 'CheckboxUnselected',
    CheckboxSelected = 'checkboxSelected',
}

const Button = (props: ButtonProps) => {
    let { title, type, onClick, className, textAlignment = ButtonTextAlignment.Left } = props
    console.log(`${title} => ${textAlignment}`)
    className = className || ''
    let textClassName = ''
    textClassName += textAlignment === ButtonTextAlignment.Left ? 'button-text-left' : 'button-text-center'
    if (type === ButtonType.Yellow) {
        className += ` button-style-yellow ${StandardBackgroundColors.Yellow}`
        textClassName += ` ${StandardTextColors.Black} ${StandardFonts.SmallTextBold}`
    } else if (type === ButtonType.Grey) {
        className += ` button-style-grey ${StandardBackgroundColors.BackgroundGrey}`
        textClassName += ` ${StandardTextColors.Black} ${StandardFonts.SmallText}`
    } else if (type === ButtonType.Green) {
        className += ` button-style-green ${StandardBackgroundColors.Green}`
        textClassName += ` ${StandardTextColors.BackgroundGrey} ${StandardFonts.SmallText}`
    } else if (type === ButtonType.Purple) {
        className += ` button-style-purple ${StandardBackgroundColors.Purple}`
        textClassName += ` ${StandardTextColors.BackgroundGrey} ${StandardFonts.SmallTextBold}`
    } else if (type === ButtonType.Red) {
        className += ` button-style-red ${StandardBackgroundColors.Red}`
        textClassName += ` ${StandardTextColors.BackgroundGrey} ${StandardFonts.SmallTextBold}`
    } else if (type === ButtonType.MultipleChoiceUnselected) {
        className += ` button-style-multiple-choice-unselected ${StandardBackgroundColors.White}`
        textClassName += ` ${StandardTextColors.Black} ${StandardFonts.SmallText}`
    } else if (type === ButtonType.MultipleChoiceSelected) {
        className += ` button-style-multiple-choice-selected ${StandardBackgroundColors.Green}`
        textClassName += ` ${StandardTextColors.BackgroundLightGrey} ${StandardFonts.SmallTextBold}`
    } else if (type === ButtonType.CheckboxUnselected) {
        className += ` button-style-multiple-choice-unselected ${StandardBackgroundColors.White}`
        textClassName += ` ${StandardTextColors.Black} ${StandardFonts.SmallText}`
    } else if (type === ButtonType.CheckboxSelected) {
        className += ` button-style-multiple-choice-selected ${StandardBackgroundColors.Green}`
        textClassName += ` ${StandardTextColors.BackgroundGrey} ${StandardFonts.SmallTextBold}`
    }  else if (type === ButtonType.Locked) {
        className += ` button-style-locked ${StandardBackgroundColors.Black}`
        textClassName += ` ${StandardTextColors.BackgroundGrey} ${StandardFonts.SmallTextBold}`
    }
    return (
        <div className={`button-container ${className} ${StandardCornerRadii.Medium}`} onClick={onClick}>
            <p className={`${textClassName}`}>{title}</p>
        </div>
    )
}

export default Button