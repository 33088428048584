// import './Completes.css'
import React, { useRef } from 'react'
import Table, { TableSortOrder, useResize, TableRenderers } from '../Tables/Table'
import { Column } from 'react-base-table'
import { Spinner } from 'react-bootstrap'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'
import { useSweepstakesHook } from './Hook'

interface LucidSweepstakeSurveysProps {
}

export interface LucidSweepstakeSurveysState {
    isLoading: boolean
    sweepstakeSurveys: SweepstakeSurveyProxy[],
}

export let LucidSweepstakeSurveys: React.FC<LucidSweepstakeSurveysProps> = (props) => {

    let { state } = useSweepstakesHook()
    let { isLoading, sweepstakeSurveys } = state
    const componentRef = useRef(null)
    const { width, height } = useResize(componentRef)
    if (isLoading) {
        return (
            <div className='admin-cashouts-container' ref={componentRef}>
                <Spinner animation='border' variant='primary' />
            </div>
        )
    }
    return (
        <div className='admin-cashouts-container' ref={componentRef}>
            <Table rows={sweepstakeSurveys} width={width} height={height} columns={generateColumns()} data={generateData(sweepstakeSurveys)} />
        </div>
    )
}

const generateData = (completes: SweepstakeSurveyProxy[]): Column[] => {
    return completes.map((complete, idx) => {
        return {
            ...complete,
            index: idx + 1,
            id: complete.firestoreId,
            parentId: null,
            width: 150,
        }
    })
}

const generateColumns = (): Column[] => {
    let columns: Column[] = [
        {
            key: 'index',
            dataKey: 'index',
            title: 'Idx',
            width: 75,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let { index } = rowData
                return TableRenderers.default(index)
            })
        },
        {
            key: 'endTimestamp',
            dataKey: 'endTimestamp',
            title: 'End',
            width: 140,
            sortable: true,
            order: TableSortOrder.Descending,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { endTimestamp } = sweepstakeSurvey
                return TableRenderers.relativeDateRender(endTimestamp)
            })
        },
        {
            key: 'clientState',
            dataKey: 'clientState',
            title: 'Client State',
            width: 150,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { clientState } = sweepstakeSurvey
                return TableRenderers.default(`${clientState}`)
            })
        },
        {
            key: 'score',
            dataKey: 'score',
            title: 'Score',
            width: 75,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { score } = sweepstakeSurvey
                score = score || 0
                return TableRenderers.default(`${score.toFixed(2)}`)
            })
        },
        {
            key: 'userId',
            dataKey: 'userId',
            title: 'UserId',
            width: 250,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { userId } = sweepstakeSurvey
                return TableRenderers.userIdRender(userId)
            })
        },
        {
            key: 'revenueCents',
            dataKey: 'revenueCents',
            title: 'Revenue',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { revenueCents } = sweepstakeSurvey
                return TableRenderers.centsToDollarRender(revenueCents)
            })
        },
        {
            key: 'userDurationSeconds',
            dataKey: 'userDurationSeconds',
            title: 'User Duration',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { userDurationSeconds } = sweepstakeSurvey
                userDurationSeconds = userDurationSeconds || 0
                if (userDurationSeconds === 0) {
                    return TableRenderers.default('')
                }
                return TableRenderers.secondsToMinutesRender(userDurationSeconds)
            })
        },
        {
            key: 'lucidSurveyNumber',
            dataKey: 'lucidSurveyNumber',
            title: 'Lucid Survey Number',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { lucidSurveyNumber } = sweepstakeSurvey
                return TableRenderers.default(`${lucidSurveyNumber}`)
            })
        },
        {
            key: 'clientStatus',
            dataKey: 'clientStatus',
            title: 'Client Status',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { clientStatus } = sweepstakeSurvey
                clientStatus = clientStatus || ''
                return TableRenderers.default(`${clientStatus}`)
            })
        },
        {
            key: 'lucidStatus',
            dataKey: 'lucidStatus',
            title: 'Lucid Status',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { lucidStatus } = sweepstakeSurvey
                lucidStatus = lucidStatus || ''
                return TableRenderers.default(`${lucidStatus}`)
            })
        },
        {
            key: 'termedQualId',
            dataKey: 'termedQualId',
            title: 'Term Qual',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { termedQualId } = sweepstakeSurvey
                termedQualId = termedQualId || ''
                return TableRenderers.default(`${termedQualId}`)
            })
        },
        {
            key: 'termedQuotaId',
            dataKey: 'termedQuotaId',
            title: 'Term Quota',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { termedQuotaId } = sweepstakeSurvey
                termedQuotaId = termedQuotaId || ''
                return TableRenderers.default(`${termedQuotaId}`)
            })
        },
        {
            key: 'firestoreId',
            dataKey: 'firestoreId',
            title: 'FirestoreId',
            width: 200,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { firestoreId } = sweepstakeSurvey
                return TableRenderers.default(`${firestoreId}`)
            })
        },
        {
            key: 'quotas',
            dataKey: 'quotas',
            title: 'Quotas',
            width: 75,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { quotas } = sweepstakeSurvey
                quotas = quotas || {}
                return TableRenderers.default(`${Object.keys(quotas).length}`)
            })
        },
        {
            key: 'screeners',
            dataKey: 'screeners',
            title: 'Screeners',
            width: 75,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { screenerQuestions } = sweepstakeSurvey
                screenerQuestions = screenerQuestions || []
                return TableRenderers.default(`${screenerQuestions.length}`)
            })
        },
    ]
    return columns
}

export default LucidSweepstakeSurveys
