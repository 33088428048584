import { Survey, SurveyQuestion } from "./SurveyProxy"

export interface SweepstakeSurveyProxy extends Survey {
    // Required fields on create
    cardColor: string // Client card rendering
    clientState: SweepstakeSurveyState
    entryURL: string
    source: SweepstakeSurveySource
    userSweepstakesCents: number
    userSweepstakesString: string
    // Fields set on UserExited
    userExitDescription?: string // Sent by client
    userExitReason?: SweepstakeSurveyExitReason // Sent by client
    // Field set on Completed
    winnerSelectedTimestamp?: number
    // Field set on source Lucid
    termedQualId?: string
    termedQuotaId?: string
    clientStatus?: string
    lucidStatus?: string
    lucidSurveyNumber?: number
    quotas?: { [key: string]: boolean }
    score?: number
    // Field set on WinnerSelected
    winnerData?: SweepstakeSurveyWinnerData
    // Calculated field
    lockedText?: string
    untilWinnerSeconds?: number

    screenerQuestions?: SurveyQuestion[]
}

// Created: Live, Expired
// Live: Started, Abandoned
// Started: UserExited, Completed, Overquota, Terminated, Abandoned, Expired
// Completed: WinnerSelected
export enum SweepstakeSurveyState {
    Abandoned = 'Abandoned', // User left app
    Created = 'Created',
    Completed = 'Completed', // Survey received callback
    Expired = 'Expired', // Survey expired
    ExpiredQuota = 'ExpiredQuota', // Survey quota expired
    FailedScreener = 'FailedScreener', // User failed screener qualifications
    FailedScreenerQuota = 'FailedScreenerQuota', // User failed screener quotas
    Live = 'Live',
    OverQuoted = 'OverQuoted', // Survey received callback
    QualityTerminated = 'QualityTerminated', // Survey received callback
    Started = 'Started', // User started survey
    Terminated = 'Terminated', // Survey received callback
    UserExited = 'UserExited', // User left survey (receives callback)
    WinnerSelected = 'WinnerSelected' // Survey winner selected
}

export enum SweepstakeSurveySource {
    GRL = 'GRL',
    LucidLink = 'LucidLink',
    Lucid = 'Lucid',
    Legacy = 'Legacy'
}

export enum SweepstakeSurveyExitReason {
    Boring = 'Boring',
    NotGreatOnMobile = 'NotGreatOnMobile',
    Stuck = 'Stuck',
    TooLong = 'TooLong',
    Unspecified = 'Unspecified'
}

export interface SweepstakeSurveyWinnerData {
    age: number
    gender: string
    state: string
    userId: string
}