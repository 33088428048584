import { LucidSweepstakesActions, LUCID_SWEEPSTAKES_REQUEST, LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE } from './Actions'
import { LucidSweepstakeSurveysState } from './LucidSweepstakeSurvey'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

export const reducer = (state: LucidSweepstakeSurveysState, action: LucidSweepstakesActions): LucidSweepstakeSurveysState => {
    switch (action.type) {
        case LUCID_SWEEPSTAKES_REQUEST:
            return {
                isLoading: true,
                sweepstakeSurveys: []
            }
        case LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE:
            let uniqueSweepstakeSurveys = state.sweepstakeSurveys.concat(action.sweepstakes)
            uniqueSweepstakeSurveys = uniqueSweepstakeSurveys.filter((sweepstakeSurvey, index, self) =>
                index === self.findIndex((v) => (
                    v.firestoreId === sweepstakeSurvey.firestoreId
                ))
            )
            let sweepstakesSurveys = uniqueSweepstakeSurveys.sort((a, b) => {
                let aTs = a.endTimestamp || 0
                let bTs = b.endTimestamp || 0
                return bTs - aTs
            })
            return {
                isLoading: false,
                sweepstakeSurveys: sweepstakesSurveys
            }
    }
}