import { COMPLETED_SURVEYS_SUCCESS, COMPLETED_SURVEYS_REQUEST, CompletedSurveysActions } from './Actions'
import { CompletedSurveysState } from '../../Components/Completed/CompletedSurveys'

export const reducer = (state: CompletedSurveysState, action: CompletedSurveysActions): CompletedSurveysState => {
    switch (action.type) {
        case COMPLETED_SURVEYS_REQUEST:
            return {
                isLoading: true,
                completedSurveys: state.completedSurveys,
                cashouts: state.cashouts
            }
        case COMPLETED_SURVEYS_SUCCESS:
            return {
                isLoading: false,
                completedSurveys: action.completedSurveys,
                cashouts: action.cashouts
            }
    }
}