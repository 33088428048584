import './Shelf.css'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'

export interface ShelfItem {
    text: string,
    value: string
}
interface ShelfProps {
    items: ShelfItem[]
}

const Shelf = (props: ShelfProps) => {
    let { items } = props
    return (
        <div className={`shelf-container ${StandardBackgroundColors.LightPurple} ${StandardCornerRadii.Medium}`}>
            {items.map(item => {
                return (
                    <div className='shelf-row'>
                        <p className={`shelf-row-text ${StandardFonts.SmallText} ${StandardTextColors.Black}`}>{item.text}</p>
                        <div className='shelf-row-grow' />
                        <p className={`shelf-row-text ${StandardFonts.SmallTextBold} ${StandardTextColors.Purple}`}>{item.value}</p>
                    </div>
                )
            })}

        </div>
    )
}

export default Shelf