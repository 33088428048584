import { Survey, SurveyQuestion } from "./SurveyProxy"

export interface QuickSurveyTemplateProxy extends Survey {
    clientState: QuickSurveyState
    liveTimestamp?: number
    payout: number
    questions: SurveyQuestion[]
    quickSurveyTemplateId: string
    sleekNuxIndex?: number
    source: QuickSurveySource
}

export enum QuickSurveyState {
    Created = 'Created',
    Completed = 'Completed', // Survey received callback
    Live = 'Live',
    Terminated = 'Terminated', // Survey received callback
    Expired = 'Expired', // Survey expired
}

export enum QuickSurveySource {
    Eureka = 'Eureka'
}