import { UserLookUpActions, USER_LOOKUP_REQUEST, USER_LOOKUP_SUCCESS, USER_LOOKUP_FAILURE, USER_LOOKUP_INCREMENT_BALANCE } from './Actions'
import { UserLookupState } from './UserLookup'

export const reducer = (state: UserLookupState, action: UserLookUpActions): UserLookupState => {
    switch (action.type) {
        case USER_LOOKUP_REQUEST:
            return {
                isLoading: true,
                user: state.user,
                cashouts: state.cashouts,
                transactions: state.transactions
            }
        case USER_LOOKUP_SUCCESS:
            return {
                isLoading: false,
                user: action.user,
                cashouts: action.cashouts,
                transactions: action.transactions
            }
        case USER_LOOKUP_FAILURE:
            return {
                isLoading: false,
                error: `Could not find a user`,
                user: undefined,
                cashouts: [],
                transactions: []
            }
        case USER_LOOKUP_INCREMENT_BALANCE:
            return {
                isLoading: true,
                user: state.user,
                cashouts: state.cashouts,
                transactions: state.transactions
            }
    }
}