import firebase from 'firebase'
import { CashoutRequestProxy } from '../../../sharedModels/CashoutRequestsProxy'
import { CashoutState } from './Cashouts'
import { CashoutsActions, CASHOUTS_MARK_AS_MANUAL, CASHOUTS_MARK_AS_SKIP, CASHOUTS_REQUEST, CASHOUTS_SEND_PAYMENT, CASHOUTS_SNAPSHOT_UPDATE } from './Actions'
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { reducer } from './Reducer'
import { useReducer, useEffect, useState } from 'react'

let initialState: CashoutState = {
    isLoading: true,
    cashouts: [],
}

export interface CashoutsHook {
    state: CashoutState
    setShouldSendFirestoreId: React.Dispatch<React.SetStateAction<string>>
    setShouldSkipFirestoreId: React.Dispatch<React.SetStateAction<string>>
    setShouldManualFirestoreId: React.Dispatch<React.SetStateAction<string>>
}

export const cashoutsSnapshotOnUpdate = (observer: {
    next?: (snapshot: QuerySnapshot) => void
    error?: (error: Error) => void
    complete?: () => void
}) => {
    return firebase.firestore()
        .collection('cashoutRequests')
        .orderBy('createdTimestamp', 'desc')
        .limit(300)
        .onSnapshot(observer)
}

export const useCashoutsHook = (): CashoutsHook => {
    let [shouldSendFirestoreId, setShouldSendFirestoreId] = useState('')
    let [shouldSkipFirestoreId, setShouldSkipFirestoreId] = useState('')
    let [shouldManualFirestoreId, setShouldManualFirestoreId] = useState('')

    let [state, dispatch] = useReducer(reducer, initialState)

    // Snapshot listener to cashouts collection
    useEffect(() => {
        const unsubscribe = cashoutsSnapshotOnUpdate({
            next: querySnapshot => {
                const updatedCashouts = querySnapshot.docs.map((docSnapshot: QueryDocumentSnapshot) => <CashoutRequestProxy>docSnapshot.data())
                console.log(`Snapshot update with ${updatedCashouts.length} docs`)
                dispatch({ type: CASHOUTS_SNAPSHOT_UPDATE, cashouts: updatedCashouts })
            }
        })

        return unsubscribe
    }, [])

    // // Initial fetch
    // useEffect(() => {
    //     let fetchData = async () => {
    //         console.log('Fired once!')
    //         let ref = await firebase.firestore()
    //             .collection('cashoutRequests')
    //             .orderBy('createdTimestamp', 'desc')
    //             .limit(50)
    //             .get()
    //         const updatedCashouts = ref.docs.map((docSnapshot: QueryDocumentSnapshot) => <CashoutRequestProxy>docSnapshot.data())
    //         console.log(`Snapshot update with ${updatedCashouts.length} docs`)
    //         dispatch({ type: CASHOUTS_SNAPSHOT_UPDATE, cashouts: updatedCashouts })
    //     }

    //     fetchData()
    // }, [])

    // Send
    useEffect(() => {
        let fetchData = async () => {

            let sendCashoutAPI = firebase.functions().httpsCallable('admin-functions-sendCashout')

            let results = await sendCashoutAPI({ cashoutId: shouldSendFirestoreId })
            dispatch({
                type: CASHOUTS_SEND_PAYMENT
            })
        }

        if (shouldSendFirestoreId.length > 0) {
            fetchData()
        }

    }, [shouldSendFirestoreId])

    // Skip
    useEffect(() => {
        let fetchData = async () => {

            let markCashoutSkippedAPI = firebase.functions().httpsCallable('admin-functions-markCashoutSkipped')

            let results = await markCashoutSkippedAPI({ cashoutId: shouldSkipFirestoreId })
            console.log(results)
            dispatch({
                type: CASHOUTS_MARK_AS_SKIP
            })

        }

        if (shouldSkipFirestoreId.length > 0) {
            fetchData()
        }

    }, [shouldSkipFirestoreId])

    // Manual 
    useEffect(() => {
        let fetchData = async () => {

            let markCashoutManualAPI = firebase.functions().httpsCallable('admin-functions-markCashoutManual')

            let results = await markCashoutManualAPI({ cashoutId: shouldManualFirestoreId })
            console.log(results)
            dispatch({
                type: CASHOUTS_MARK_AS_MANUAL
            })

        }

        if (shouldManualFirestoreId.length > 0) {
            fetchData()
        }

    }, [shouldManualFirestoreId])


    return {
        state: state,
        setShouldSendFirestoreId: setShouldSendFirestoreId,
        setShouldSkipFirestoreId: setShouldSkipFirestoreId,
        setShouldManualFirestoreId: setShouldManualFirestoreId
    }
}
