import Landing from '../Components/Landing/LandingPage'
import LogIn from '../Components/Login/Login'
import PrivacyPolicy from '../Components/Legal/PrivacyPolicy'
import Help from '../Components/Help/Help'
import React from 'react'
import SignUp from '../Components/SignUp/SignUp'
import SurveySuccessScreen from '../Components/SurveyOver/SurveySuccessScreen'
import SurveyFailureScreen from '../Components/SurveyOver/SurveyFailureScreen'
import SurveyRouter from '../Components/Surveys/SurveyRouter'
import TermsOfService from '../Components/Legal/TermsOfService'
import { AdminDashboard } from '../Components/Admin/Dashboard/AdminDashboard'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { useSession } from '../Authentication/Hook'
const createHistory = require('history').createBrowserHistory
const history = createHistory()

const AppRouter = () => {

  const { eurekaUser, firebaseAuthUser, userIsLoggedIn } = useSession()

  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/signup' render={() => <SignUp />} />
        <Route exact path='/login' render={() => <LogIn />} />
        <Route path='/surveys' component={SurveyRouter} />
        <Route path='/admin' component={AdminDashboard} />
        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        <Route exact path='/terms' component={TermsOfService} />
        <Route exact path='/help' component={Help} />
        <Route exact path='/survey-complete' component={SurveySuccessScreen} />
        <Route exact path='/survey-over' component={SurveyFailureScreen} />
        <Route exact path='/' component={Landing} />
        <Route path='*' render={() => <Redirect to='/' />} />
      </Switch>
    </Router>
  )
}

export default AppRouter
