import firebase from 'firebase'
import moment from 'moment'
import { DocumentSnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { OFFERWALL_SURVEY_REQUEST, OFFERWALL_SURVEY_SUCCESS, OFFERWALL_SURVEY_DID_START, OFFERWALL_SURVEY_DID_UPDATE, OFFERWALL_SURVEY_DID_COMPLETE, OFFERWALL_SURVEY_DID_INCOMPLETE, OFFERWALL_QUICK_SURVEY_DID_COMPLETE, OFFERWALL_QUICK_SURVEY_DID_START, OFFERWALL_QUICK_SURVEY_DID_UPDATE } from './Actions'
import { OfferwallState } from './Offerwall'
import { SweepstakeSurveyProxy, SweepstakeSurveyState } from '../../sharedModels/SweepstakeSurveyProxy'
import { QuickSurveyTemplateProxy } from '../../sharedModels/QuickSurveyProxy'

import { UserProxy } from '../../sharedModels/UserProxy'
import { reducer } from './Reducer'
import { useState, useReducer, useEffect } from 'react'

let initialState: OfferwallState = {
    isLoading: false,
    quickSurveys: [],
    sweepstakeSurveys: [],
}

// Interface that gets returned from the hook. Usually its in an array format, but that makes it harder to type! So that's
// why this exists.
export interface OfferwallHook {
    state: OfferwallState,
    setReload: React.Dispatch<React.SetStateAction<number>>
    setSweepstakeSurvey: React.Dispatch<React.SetStateAction<SweepstakeSurveyProxy | undefined>>
    setActiveQuickSurvey: React.Dispatch<React.SetStateAction<QuickSurveyTemplateProxy | undefined>>
}

export const sweepstakeSnapshotListener = (firestoreId: string,
    observer: {
        next?: (snapshot: DocumentSnapshot) => void
        error?: (error: Error) => void
        complete?: () => void
    }) => {
    return firebase.firestore()
        .collection('sweepstakeSurvey')
        .doc(firestoreId)
        .onSnapshot(observer)
}

export const quickSurveySnapshotListener = (firestoreId: string,
    observer: {
        next?: (snapshot: DocumentSnapshot) => void
        error?: (error: Error) => void
        complete?: () => void
    }) => {
    return firebase.firestore()
        .collection('quickSurvey')
        .doc(firestoreId)
        .onSnapshot(observer)
}

export const useLiveSurveyHook = (eurekaUser: UserProxy): OfferwallHook => {

    let [sweepstakeSurvey, setSweepstakeSurvey] = useState<SweepstakeSurveyProxy>()
    let [activeQuickSurvey, setActiveQuickSurvey] = useState<QuickSurveyTemplateProxy>()

    let [reload, setReload] = useState<number>(0)
    let [state, dispatch] = useReducer(reducer, initialState)

    // Get Offerwall
    useEffect(() => {
        let fetchData = async () => {

            dispatch({
                type: OFFERWALL_SURVEY_REQUEST
            })

            let getLiveSurveysAPI = firebase.functions().httpsCallable('web-functions-getLiveSurveys')
            let { userId } = eurekaUser
            let results = await getLiveSurveysAPI({ userId: userId })

            let d = results.data as { sweepstakeSurveys: SweepstakeSurveyProxy[], quickSurveys: QuickSurveyTemplateProxy[] }
            dispatch({
                type: OFFERWALL_SURVEY_SUCCESS,
                sweepstakeSurveys: d.sweepstakeSurveys,
                quickSurveys: d.quickSurveys
            })
        }

        fetchData()
    }, [reload])

    // Snapshot listener
    useEffect(() => {
        if (activeQuickSurvey === undefined) { return }
        let { firestoreId } = activeQuickSurvey
        if (firestoreId === undefined) { return }
        console.log(`Adding listener to quick survey ${firestoreId}`)

        dispatch({
            type: OFFERWALL_QUICK_SURVEY_DID_START,
            quickSurvey: activeQuickSurvey
        })
        let startQuickSurveyAPI = firebase.functions().httpsCallable('web-functions-startQuickSurvey')
        startQuickSurveyAPI({
            quickSurveyId: firestoreId,
        })

        const unsubscribe = quickSurveySnapshotListener(firestoreId, {
            next: document => {
                let quickSurveyUpdate = document.data() as QuickSurveyTemplateProxy
                console.log(`Quick survey just updated`)
                console.log(quickSurveyUpdate)

                dispatch({
                    type: OFFERWALL_QUICK_SURVEY_DID_UPDATE,
                    quickSurvey: quickSurveyUpdate
                })
            }
        })

        return unsubscribe
    }, [activeQuickSurvey])

    // Snapshot listener
    useEffect(() => {
        if (sweepstakeSurvey === undefined) { return }
        let { firestoreId, lucidSurveyNumber } = sweepstakeSurvey
        if (firestoreId === undefined) { return }
        console.log(`Adding listener to ${firestoreId}`)

        dispatch({
            type: OFFERWALL_SURVEY_DID_START,
            sweepstakeSurvey: sweepstakeSurvey
        })
        let startSweepstakeSurveyAPI = firebase.functions().httpsCallable('web-functions-startSweepstakeSurvey')
        startSweepstakeSurveyAPI({
            sweepstakeSurveyId: firestoreId,
            completedHumanReadableDate: moment().format('LLLL')
        })

        const unsubscribe = sweepstakeSnapshotListener(firestoreId, {
            next: document => {
                let sweepstakeSurveyUpdate = document.data() as SweepstakeSurveyProxy
                console.log(`Snapshot query just updated`)
                console.log(sweepstakeSurveyUpdate)

                dispatch({
                    type: OFFERWALL_SURVEY_DID_UPDATE,
                    sweepstakeSurvey: sweepstakeSurveyUpdate
                })
            }
        })

        return unsubscribe
    }, [sweepstakeSurvey])

    return {
        state: state,
        setReload: setReload,
        setSweepstakeSurvey: setSweepstakeSurvey, // TODO: kirby name this setActiveSweepstakeSurvey
        setActiveQuickSurvey: setActiveQuickSurvey
    }
}
