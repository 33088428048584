import './Spinner.css'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'

interface SpinnerProps {
    text?: string
    absolutePosition?: boolean
}

const Spinner = (props: SpinnerProps) => {
    let { text = 'Loading...', absolutePosition = false } = props
    return (
        <div className={`${absolutePosition ? 'spinner-container-absolute' : 'spinner-container'} ${StandardBackgroundColors.White} ${StandardDropShadows.Normal} ${StandardCornerRadii.Normal}`}>
            <svg className='spinner-icon' width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1" fill="white">
                    <path d="M60 30C60 35.7783 58.3313 41.4336 55.1944 46.2863C52.0575 51.1389 47.586 54.9824 42.3172 57.3548C37.0484 59.7272 31.2066 60.5276 25.4939 59.6597C19.7812 58.7917 14.4407 56.2925 10.1142 52.4623L15.6114 46.2529C18.7419 49.0242 22.6061 50.8326 26.7396 51.4606C30.8731 52.0886 35.1 51.5095 38.9123 49.7929C42.7245 48.0763 45.96 45.2953 48.2297 41.7841C50.4994 38.2729 51.7068 34.1809 51.7068 30H60Z" />
                </mask>
                <path d="M60 30C60 35.7783 58.3313 41.4336 55.1944 46.2863C52.0575 51.1389 47.586 54.9824 42.3172 57.3548C37.0484 59.7272 31.2066 60.5276 25.4939 59.6597C19.7812 58.7917 14.4407 56.2925 10.1142 52.4623L15.6114 46.2529C18.7419 49.0242 22.6061 50.8326 26.7396 51.4606C30.8731 52.0886 35.1 51.5095 38.9123 49.7929C42.7245 48.0763 45.96 45.2953 48.2297 41.7841C50.4994 38.2729 51.7068 34.1809 51.7068 30H60Z" fill="#7B49FB" stroke="#7B49FB" strokeWidth="16.5" mask="url(#path-1-inside-1)" />
            </svg>
            <div className='spinner-text'>
                <p className={`${StandardFonts.Legal} ${StandardTextColors.Black}`}>{text}</p>
            </div>
        </div>
    )
}

export default Spinner