import './Cashout.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import Modal from '../Modal/Modal'
import React, { useState } from 'react'
import Spinner from '../Spinner/Spinner'
import TextField from '../TextField/TextField'
import { ProgressBar } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'
import { UserProxy } from '../../sharedModels/UserProxy'
import { useCashoutHook, CashoutHook } from './Hook'

interface CashoutProps {
    eurekaUser: UserProxy
}


export interface CashoutState {
    isLoading: boolean,
    success: boolean,
    error?: string
}

const Cashout = (props: CashoutProps) => {
    let { eurekaUser } = props

    let [showPayPalEmailModal, setShowPayPalEmailModal] = useState<boolean>(false)
    let [paypalEmail1, setPaypalEmail1] = useState<string>()
    let [paypalEmail2, setPaypalEmail2] = useState<string>()

    let { currentEarningsCents, cashoutThresholdCents, cashoutThresholdString } = eurekaUser
    let { state, setCashoutEmail }: CashoutHook = useCashoutHook()
    let { isLoading, success, error } = state


    let convertCentsToBalance = (cents): String => {
        console.log(cents)
        let fixed: number = (cents % 100 === 0) ? 0 : 2 // Truncate cents if $.00
        return cents >= 100 ? `$${(cents / 100).toFixed(fixed)}` : `${cents}¢`
    }

    function validateEmail(email): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    let cashoutButtonType = (paypalEmail1 && paypalEmail1 === paypalEmail2 && validateEmail(paypalEmail1)) ? ButtonType.Yellow : ButtonType.Grey

    if (isLoading) {
        return <Spinner text={`$$$`} />
    } else if (success) {
        return (
            <div className={`cashout-container ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}`}>

                <p>Congrats!</p>
                <p>You have money on the way! It takes 3-5 business days for cashouts to process. Have a great day!</p>

                <Button
                    className='cashout-button'
                    title={`Close`}
                    type={cashoutButtonType}
                    onClick={() => {
                        window.location.href = `/surveys`
                    }} />
            </div>
        )
    }

    return (
        <div className={`cashout-container ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}`}>

            <div className={`cashout-top-row`}>
                <img src='../paypal.png' className={`cashout-paypal-image`} />
                <p className={`${StandardFonts.H1} ${StandardTextColors.Green}`}>{convertCentsToBalance(currentEarningsCents)}</p>
            </div>

            <div className={`cashout-middle-row`}>
                <ProgressBar
                    className={`cashout-progress-bar`}
                    animated
                    now={currentEarningsCents / cashoutThresholdCents * 100}
                    variant={'success'} />
                <div className={`cashout-middle-row-labels`}>
                    <p className={`${StandardTextColors.Green} ${StandardFonts.SmallTextBold}`}>Balance</p>
                    <p className={`${StandardTextColors.TextGrey} ${StandardFonts.SmallTextBold}`}>{cashoutThresholdString}</p>
                </div>
            </div>
            {currentEarningsCents > cashoutThresholdCents ?
                <div className='cashout-bottom-section'>
                    <TextField
                        placeholder='PayPal Email'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPaypalEmail1(event.target.value)
                        }} />
                    <TextField
                        placeholder='Confirm PayPal Email'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPaypalEmail2(event.target.value)
                        }} />
                    <Button
                        className='cashout-button'
                        title={`Cashout`}
                        type={cashoutButtonType}
                        textAlignment={ButtonTextAlignment.Center}
                        onClick={() => {
                            if (paypalEmail1 && paypalEmail1 === paypalEmail2 && validateEmail(paypalEmail1)) {
                                setCashoutEmail(paypalEmail1)
                            }
                        }} />
                </div>
                :
                <div className='cashout-bottom-section'>
                    <p className={`${StandardFonts.SmallText}`}>{`To cashout, you need to have earned at least ${cashoutThresholdString}.`}</p>
                </div>
            }
        </div>
    )
}

export default Cashout