import './QuickSurveyOfferwall.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import Modal from '../Modal/Modal'
import Ribbon from '../Ribbon/Ribbon'
import React, { useState } from 'react'
import firebase from 'firebase'
import { QuickSurveyTemplateProxy, QuickSurveyState } from '../../sharedModels/QuickSurveyProxy'
import { StandardFonts, StandardTextColors, StandardBackgroundColors, StandardCornerRadii, StandardDropShadows, StandardMargin, Clickable } from '../Styles/index'
const Timestamp = firebase.firestore.Timestamp

type Optional<T> = T | undefined // TODO: Use this everywhere `?` is used

interface QuickSurveyOfferwallProps {
    quickSurveys: QuickSurveyTemplateProxy[]
    activeQuickSurvey: QuickSurveyTemplateProxy | undefined
    setActiveQuickSurvey: React.Dispatch<React.SetStateAction<QuickSurveyTemplateProxy | undefined>>
    setReload: React.Dispatch<React.SetStateAction<number>>
    setAnimateUserBalance: React.Dispatch<React.SetStateAction<boolean>>
}

const QuickSurveyOfferwall: React.FC<QuickSurveyOfferwallProps> = (props) => {

    let { quickSurveys, activeQuickSurvey, setActiveQuickSurvey, setReload, setAnimateUserBalance } = props
    if (quickSurveys.length === 0) { return <div /> }

    let card: Optional<JSX.Element> = undefined
    if (activeQuickSurvey !== undefined) {
        let { clientState, userEarningsString, userEarningsCents } = activeQuickSurvey
        if (clientState === QuickSurveyState.Completed) {
            card = <div>
                <Ribbon amountCentsString={userEarningsString} />
                <Button
                    className='quick-survey-claim-button'
                    title={`Claim ${userEarningsString}`}
                    type={ButtonType.Yellow}
                    textAlignment={ButtonTextAlignment.Center}
                    onClick={() => {
                        setAnimateUserBalance(true)
                        setTimeout(() => {
                            setReload(Timestamp.now().seconds)
                            setActiveQuickSurvey(undefined)
                        }, 3000)
                    }} />
            </div>
        }
    }

    return (
        <div className={`quick-surveys-container`}>
            {card && <Modal zIndex={12}>{card}</Modal>}
            <p className={`quick-surveys-title ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Quick Surveys</p>
            <div className='quick-surveys-cell-container'>
                {quickSurveys.map(quickSurvey => {
                    return <QuickSurveyCell
                        quickSurvey={quickSurvey}
                        setActiveQuickSurvey={setActiveQuickSurvey} />
                })}
            </div>
        </div >
    )
}

export default QuickSurveyOfferwall

interface QuickSurveyCellProps {
    quickSurvey: QuickSurveyTemplateProxy
    setActiveQuickSurvey: React.Dispatch<React.SetStateAction<QuickSurveyTemplateProxy | undefined>>
}

const QuickSurveyCell: React.FC<QuickSurveyCellProps> = (props) => {

    let { quickSurvey, setActiveQuickSurvey } = props
    let [imageUrl, setImageUrl] = useState('')
    let { topic, userEarningsString, userEstimatedDurationString, firestoreId, backgroundImageUrl } = quickSurvey

    let storage = firebase.storage()
    storage.ref(backgroundImageUrl).getDownloadURL().then(url => {
        setImageUrl(url)
    })

    return (
        <div className={`quick-survey-cell ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal} ${StandardMargin.NormalBottom} ${Clickable}`}
            onClick={() => {
                console.log(`Marking ${firestoreId} as started!`)
                setActiveQuickSurvey(quickSurvey)
                let url = `surveys/quick-survey/${firestoreId}`
                window.open(url, '_blank') //to open new page
            }}>
            <img className='quick-survey-cell-image' src={imageUrl} />

            <div className='quick-survey-cell-content'>
                <div className='quick-survey-cell-topic'>
                    <p className={`quick-survey-cell-topic-label ${StandardTextColors.Black} ${StandardFonts.SmallTextBold}`}>{topic}</p>
                </div>
                <div className='quick-survey-cell-stats'>
                    <div className='quick-survey-cell-stat-item'>
                        <p className={`${StandardTextColors.Green} ${StandardFonts.SmallTextBold}`}>{userEarningsString}</p>
                        <p className={`${StandardTextColors.Green} ${StandardFonts.SmallText}`}>reward</p>
                    </div>
                    <div className='quick-survey-cell-stat-item'>
                        <p className={`${StandardTextColors.Black} ${StandardFonts.SmallTextBold}`}>{userEstimatedDurationString}</p>
                        <p className={`${StandardTextColors.Black} ${StandardFonts.SmallText}`}>mins</p>
                    </div>
                </div>
            </div>
        </div >
    )
}
