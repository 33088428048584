import FraudUsersTable from '../Tables/FraudUsersTable'
import React, { useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import { UserProxy } from '../../../sharedModels/UserProxy'
import { useFraudHook } from './Hook'
import { useResize } from '../Tables/Table'

interface FraudProps {
}

export interface FraudState {
    isLoading: boolean
    users: UserProxy[],
}

export let Fraud: React.FC<FraudProps> = (props) => {

    let { state } = useFraudHook()
    let { isLoading, users } = state
    const componentRef = useRef(null)
    const { width, height } = useResize(componentRef)

    return (
        <div className='admin-cashouts-container' ref={componentRef}>
            {isLoading ?
                <Spinner animation='border' variant='primary' />
                : <FraudUsersTable users={users} width={width} height={height} />}
        </div>
    )
}

export default Fraud
