import './Completes.css'
import React, { useRef } from 'react'
import Table, { TableSortOrder, useResize, TableRenderers } from '../Tables/Table'
import { Column } from 'react-base-table'
import { Spinner } from 'react-bootstrap'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'
import { useCompletesHook } from './Hook'

interface CompletesProps {
}

export interface CompletesState {
    isLoading: boolean
    completes: SweepstakeSurveyProxy[],
}

export let Completes: React.FC<CompletesProps> = (props) => {

    let { state } = useCompletesHook()
    let { isLoading, completes } = state
    const componentRef = useRef(null)
    const { width, height } = useResize(componentRef)
    if (isLoading) {
        return (
            <div className='admin-cashouts-container' ref={componentRef}>
                <Spinner animation='border' variant='primary' />
            </div>
        )
    }
    return (
        <div className='admin-cashouts-container' ref={componentRef}>
            <Table rows={completes} width={width} height={height} columns={generateColumns()} data={generateData(completes)} />
        </div>
    )
}

const generateData = (completes: SweepstakeSurveyProxy[]): Column[] => {
    return completes.map((complete, idx) => {
        return {
            ...complete,
            index: idx + 1,
            id: complete.firestoreId,
            parentId: null,
            width: 150,
        }
    })
}

const generateColumns = (): Column[] => {
    let columns: Column[] = [
        {
            key: 'index',
            dataKey: 'index',
            title: 'Idx',
            width: 75,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let { index } = rowData
                return TableRenderers.default(index)
            })
        },
        {
            key: 'endTimestamp',
            dataKey: 'endTimestamp',
            title: 'Date',
            width: 140,
            sortable: true,
            order: TableSortOrder.Descending,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { endTimestamp } = sweepstakeSurvey
                return TableRenderers.relativeDateRender(endTimestamp)
            })
        },
        {
            key: 'userId',
            dataKey: 'userId',
            title: 'UserId',
            width: 250,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { userId } = sweepstakeSurvey
                return TableRenderers.userIdRender(userId)
            })
        },
        {
            key: 'revenueCents',
            dataKey: 'revenueCents',
            title: 'Revenue',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { revenueCents } = sweepstakeSurvey
                return TableRenderers.centsToDollarRender(revenueCents)
            })
        },
        {
            key: 'userEarningsCents',
            dataKey: 'userEarningsCents',
            title: 'User Earnings',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { userEarningsCents } = sweepstakeSurvey
                return TableRenderers.centsToDollarRender(userEarningsCents)
            })
        },
        {
            key: 'profit', // calculated
            dataKey: 'revenueCents.userEarningsCents',
            title: 'Profit',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { userEarningsCents, revenueCents } = sweepstakeSurvey
                let profit = (revenueCents || 0) - userEarningsCents
                return TableRenderers.centsToDollarRender(profit)
            })
        },
        {
            key: 'profitMargin', // calculated
            dataKey: 'revenueCents.userEarningsCents',
            title: 'Margin',
            width: 110,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { userEarningsCents, revenueCents } = sweepstakeSurvey
                return TableRenderers.profitMarginRender(revenueCents, userEarningsCents)
            })
        },
        {
            key: 'source',
            dataKey: 'source',
            title: 'Source',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { source } = sweepstakeSurvey
                return TableRenderers.default(`${source}`)
            })
        },
        {
            key: 'userDurationSeconds',
            dataKey: 'userDurationSeconds',
            title: 'User Duration',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { userDurationSeconds } = sweepstakeSurvey
                return TableRenderers.secondsToMinutesRender(userDurationSeconds)
            })
        },
        {
            key: 'lucidSurveyNumber',
            dataKey: 'lucidSurveyNumber',
            title: 'Lucid Survey Number',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { lucidSurveyNumber } = sweepstakeSurvey
                return TableRenderers.default(`${lucidSurveyNumber}`)
            })
        },
        {
            key: 'firestoreId',
            dataKey: 'firestoreId',
            title: 'FirestoreId',
            width: 170,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: SweepstakeSurveyProxy = rowData
                let { firestoreId } = sweepstakeSurvey
                return TableRenderers.default(`${firestoreId}`)
            })
        },
    ]
    return columns
}

export default Completes
