import firebase from 'firebase'
import { FRAUD_SNAPSHOT_UPDATE } from './Actions'
import { FraudState } from './Fraud'
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { UserProxy } from '../../../sharedModels/UserProxy'
import { reducer } from './Reducer'
import { useReducer, useEffect } from 'react'

let initialState: FraudState = {
    isLoading: true,
    users: [],
}

export interface FraudHook {
    state: FraudState,
}

export const useFraudHook = (): FraudHook => {
    let [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        let fetchData = async () => {
            console.log('Fired once!')
            let docs = await firebase.firestore()
                .collection('users')
                .orderBy('lockedTimestamp', 'desc')
                .limit(2500)
                .get()
            const updatedUsers = docs.docs.map((docSnapshot: QueryDocumentSnapshot) => <UserProxy>docSnapshot.data())
            dispatch({ type: FRAUD_SNAPSHOT_UPDATE, users: updatedUsers })
        }

        fetchData()
    }, [])

    return {
        state: state,
    }
}
