import { LucidSurveyTemplateProxy, LucidSurveyTemplateQualQuestion } from '../../../sharedModels/LucidSurveyTemplateProxy'

// Define all actions that can occur on the UserLookup Component
export const LUCID_INVENTORY_REQUEST = 'LUCID_INVENTORY_REQUEST'
export const LUCID_INVENTORY_SNAPSHOT_UPDATE = 'LUCID_INVENTORY_SNAPSHOT_UPDATE'
export const LUCID_INVENTORY_REQUEST_ENGLISH_QUALS = 'LUCID_INVENTORY_REQUEST_ENGLISH_QUALS'
export const LUCID_INVENTORY_ENGLISH_QUALS = 'LUCID_INVENTORY_ENGLISH_QUALS'
export const LUCID_INVENTORY_RESET_ENGLISH_QUALS = 'LUCID_INVENTORY_RESET_ENGLISH_QUALS'


export interface LucidInventoryRequest {
    type: typeof LUCID_INVENTORY_REQUEST,
}

export interface LucidInventorySnapshotUpdate {
    type: typeof LUCID_INVENTORY_SNAPSHOT_UPDATE,
    lucidSurveyTemplates: LucidSurveyTemplateProxy[]
}

export interface LucidInventoryRequestEnglishQuals {
    type: typeof LUCID_INVENTORY_REQUEST_ENGLISH_QUALS,
}

export interface LucidInventoryEnglishQuals {
    type: typeof LUCID_INVENTORY_ENGLISH_QUALS,
    englishQualifications: LucidSurveyTemplateQualQuestion[]
}

export interface LucidInventoryResetEnglishQuals {
    type: typeof LUCID_INVENTORY_RESET_ENGLISH_QUALS,
}

export type LucidInventoryActions = LucidInventoryRequest | LucidInventorySnapshotUpdate | LucidInventoryRequestEnglishQuals | LucidInventoryEnglishQuals | LucidInventoryResetEnglishQuals