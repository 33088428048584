import './NavBar.css'
import Modal from '../Modal/Modal'
import Cashout from '../Cashout/Cashout'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'
import { StandardFonts, StandardTextColors, StandardBackgroundColors, StandardDropShadows, StandardMargin, Clickable } from '../Styles/index'
import { UserProxy } from '../../sharedModels/UserProxy'
import { animated, useSpring } from "react-spring"
import * as easings from 'd3-ease'

interface NavBarProps {
    pathname: string
    eurekaUser: UserProxy
    animateUserBalance: boolean
    setAnimateUserBalance: React.Dispatch<React.SetStateAction<boolean>>
}

const NavBar = (props: NavBarProps) => {
    let { pathname, eurekaUser, animateUserBalance, setAnimateUserBalance } = props
    let { currentEarningsCents } = eurekaUser
    let [selectedSection, setSelectedSection] = useState<string>(pathname)
    let [showCashout, setShowCashout] = useState<boolean>(false)

    let [localCurrentEarningsCents, setLocalCurrentEarningsCents] = useState<number>(currentEarningsCents)

    if (animateUserBalance) {
        console.log(`Time to animate the user earnings from ${localCurrentEarningsCents} to ${currentEarningsCents}`)
        setTimeout(() => {
            console.log('turning off animation')
            setLocalCurrentEarningsCents(currentEarningsCents)
            setAnimateUserBalance(false)
        }, 1500)
    }

    const spring = useSpring({
        from: { val: localCurrentEarningsCents },
        to: { val: (animateUserBalance) ? currentEarningsCents : localCurrentEarningsCents },
        config: { duration: 1500, easing: easings.easeExpInOut }
    })

    let onClick = ((section: string) => {
        setSelectedSection(section)
    })

    let sectionClassName = ((section: string) => {
        if (section === selectedSection) {
            return `nav-bar-section ${StandardFonts.SmallTextBold} ${StandardTextColors.Black} ${Clickable}`
        } else {
            return `nav-bar-section ${StandardFonts.SmallText} ${StandardTextColors.TextGrey} ${Clickable}`
        }
    })

    let convertCentsToBalance = (cents): String => {
        return `$${(cents / 100).toFixed(2)}`
    }

    return (
        <Navbar className={`survey-router-nav-bar ${StandardBackgroundColors.White} ${StandardDropShadows.Normal}`}>
            {showCashout && <Modal
                onClick={() => { setShowCashout(false) }}
                presentInModal={false}>
                <Cashout eurekaUser={eurekaUser} />
            </Modal>}
            <div className={`nav-bar-container ${StandardMargin.NormalLeftRight}`}>
                <Link className={sectionClassName('/surveys')} to='/surveys' onClick={() => { onClick('/surveys') }}>Surveys</Link>
                <Link className={sectionClassName('/surveys/daily-poll')} to='/surveys/daily-poll' onClick={() => { onClick('/surveys/daily-poll') }}>Daily Poll</Link>
                <Link className={sectionClassName('/surveys/completed')} to='/surveys/completed' onClick={() => { onClick('/surveys/completed') }}>Completed</Link>
                <div className='survey-nav-bar-flex-grow'></div>
                <p className={`nav-bar-current-balance ${StandardFonts.Number} ${StandardTextColors.Green} ${Clickable}`}
                    onClick={() => {
                        setShowCashout(true)
                    }}>
                    <animated.div>
                        {spring['val'].interpolate(val => convertCentsToBalance(Math.floor(val)))}
                    </animated.div>
                </p>
                <div className='nav-bar-profile'>
                    <Link className={`profile-nav-item ${sectionClassName('/surveys/profile')}`} to='/surveys/profile' onClick={() => { onClick('/surveys/profile') }}>Profile</Link>
                    <img src='../femaleIcon.png' className={`nav-bar-image`}></img>
                </div>
            </div>
        </Navbar>
    )
}

export default NavBar
