import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
// import 'firebase/analytics'

// Replace this with your own config details

let config = {
    apiKey: 'AIzaSyBWMKFpeIh5jAXhXiVNqCgNyFIjbbwV6aI',
    authDomain: 'lancelot-fa22c.firebaseapp.com',
    databaseURL: 'https://lancelot-fa22c.firebaseio.com',
    projectId: 'lancelot-fa22c',
    storageBucket: 'lancelot-fa22c.appspot.com',
    messagingSenderId: '732619747753',
    appId: '1:732619747753:web:8af23211b98753c9',
    measurementId: 'G-DVN2S7YFVC'
}
firebase.initializeApp(config)
firebase.functions()
firebase.storage()

// let analytics = firebase.analytics()
firebase.firestore().settings({ timestampsInSnapshots: true })
export default firebase
