export interface CashoutRequestProxy {
    appStoreRatingSent: boolean
    completedTimestamp?: number
    createdTimestamp: number
    currentEarningsCents: number
    currentEarningsString: string
    currentRevenueCents: number
    deviceLocationCountry: string
    email: string // PayPal email
    firestoreId: string
    fraudScore: number
    grlLifetimeRevenueCents: number
    grlSurveyAttempts: number
    grlSurveyCompletes: number
    grlSurveyIncompletes: number
    ipCountry: string
    lifetimeEarningsCents: number
    lifetimeRevenueCents: number
    lucidLifetimeRevenueCents: number
    lucidSurveyCompletes: number
    lucidSurveyRequests: number
    lucidSurveyTerminates: number
    paypal_batch_status: PayPalStatus
    userCreatedHumanReadableDate: string
    userId: string
}

export enum PayPalStatus {
    Canceled = 'CANCELED',
    Denied = 'DENIED',
    Manual = 'MANUAL', // Internal status,
    Skipped = 'SKIPPED', // Internal status
    Success = 'SUCCESS',
    Timeout = 'TIMEOUT', // Internal status
    Uninitiated = 'UNINITIATED'
}

export const adminCashoutRequestReducerDefaultState = <CashoutRequestProxy>{
    currentEarningsCents: -1,
    currentRevenueCents: -1,
    deviceLocationCountry: '',
    email: '',
    fraudScore: -1
}