import React, { useRef } from 'react'
import Table, { TableSortOrder, useResize, TableRenderers } from './Table'
import { Column } from 'react-base-table'
import { TransactionProxy } from '../../../sharedModels/TransactionProxy'

interface TransactionsTableProps {
    transactions: TransactionProxy[],
    width: number,
    height: number,
}

export let TransactionsTable: React.FC<TransactionsTableProps> = (props) => {

    let { transactions, width, height } = props
    return (
        <div className='admin-cashouts-container'>
            <Table rows={transactions} width={width} height={height} columns={generateColumns()} data={generateData(transactions)} />
        </div>
    )
}

const generateData = (transactions: TransactionProxy[]): Column[] => {
    return transactions.map((transaction, idx) => {
        return {
            ...transaction,
            index: idx + 1,
            id: transaction.firestoreId,
            parentId: null,
            width: 150,
        }
    })
}

const generateColumns = (): Column[] => {
    let columns: Column[] = [
        {
            key: 'endTimestamp',
            dataKey: 'endTimestamp',
            title: 'Date',
            width: 140,
            sortable: true,
            order: TableSortOrder.Descending,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { createdTimestamp } = transaction
                return TableRenderers.relativeDateRender(createdTimestamp)
            })
        },
        {
            key: 'amountCents',
            dataKey: 'amountCents',
            title: 'Amout',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { amountCents } = transaction
                return TableRenderers.centsToDollarRender(amountCents)
            })
        },
        {
            key: 'revenueCents',
            dataKey: 'revenueCents',
            title: 'Revenue',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { revenueCents } = transaction
                return TableRenderers.centsToDollarRender(revenueCents)
            })
        },
        {
            key: 'source',
            dataKey: 'source',
            title: 'Source',
            width: 200,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { source } = transaction
                return TableRenderers.default(`${source}`)
            })
        },
        {
            key: 'currentEarningsCents',
            dataKey: 'currentEarningsCents',
            title: 'User Earnings',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { currentEarningsCents } = transaction
                return TableRenderers.centsToDollarRender(currentEarningsCents)
            })
        },
        {
            key: 'currentRevenueCents',
            dataKey: 'currentRevenueCents',
            title: 'User Revenue',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { currentRevenueCents } = transaction
                return TableRenderers.centsToDollarRender(currentRevenueCents)
            })
        },
        {
            key: 'lifetimeRevenueCents',
            dataKey: 'lifetimeRevenueCents',
            title: 'Total Revenue',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { lifetimeRevenueCents } = transaction
                return TableRenderers.centsToDollarRender(lifetimeRevenueCents)
            })
        },
        {
            key: 'sweepstakeSurveyCompletes',
            dataKey: 'sweepstakeSurveyCompletes',
            title: 'Completes',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { sweepstakeSurveyCompletes } = transaction
                return TableRenderers.default(`${sweepstakeSurveyCompletes}`)
            })
        },
        {
            key: 'sweepstakeSurveyAttempts',
            dataKey: 'sweepstakeSurveyAttempts',
            title: 'Attempts',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { sweepstakeSurveyAttempts } = transaction
                return TableRenderers.default(`${sweepstakeSurveyAttempts}`)
            })
        },
        {
            key: 'sweepstakeSurveyCompletionRate',
            dataKey: 'sweepstakeSurveyCompletionRate',
            title: 'Completion Rate',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { sweepstakeSurveyCompletes, sweepstakeSurveyAttempts } = transaction
                let percentage = (100 * (sweepstakeSurveyCompletes / sweepstakeSurveyAttempts)).toFixed(2)
                return TableRenderers.default(`${percentage}%`)
            })
        },
        {
            key: 'fraudScore',
            dataKey: 'fraudScore',
            title: 'Fraud Score',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { fraudScore } = transaction
                let fraudScoreString = (fraudScore === undefined) ? '' : `${fraudScore}`
                return TableRenderers.default(fraudScoreString)
            })
        },
        {
            key: 'cooldownScore',
            dataKey: 'cooldownScore',
            title: 'Cool Down Score',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: TransactionProxy = rowData
                let { cooldownScore } = transaction
                let cooldownScoreString = (cooldownScore === undefined) ? '' : `${cooldownScore}`
                return TableRenderers.default(cooldownScoreString)
            })
        },
    ]
    return columns
}


export default TransactionsTable
