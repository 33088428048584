import UserExitsTable from '../Tables/UserExitsTable'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'
import { useUserExitsHook } from './Hook'

interface UserExitsProps {
}

export interface UserExitsState {
    isLoading: boolean
    sweepstakeSurveys: SweepstakeSurveyProxy[],
}

export let UserExits: React.FC<UserExitsProps> = (props) => {

    let { state } = useUserExitsHook()
    let { isLoading, sweepstakeSurveys } = state

    return (
        <div className='admin-cashouts-container'>
            {isLoading ?
                <Spinner animation='border' variant='primary' />
                : <UserExitsTable sweepstakeSurveys={sweepstakeSurveys} />}
        </div>
    )
}

export default UserExits
