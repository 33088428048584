import React from 'react'
import Table, { TableSortOrder, useResize, TableRenderers } from './Table'
import moment from 'moment'
import { Column } from 'react-base-table'
import { UserProxy } from '../../../sharedModels/UserProxy'

interface FraudUsersTableProps {
    users: UserProxy[],
    width: number,
    height: number
}

const FraudUsersTable: React.FC<FraudUsersTableProps> = ({ users, width, height }) => {

    if (users === undefined || users.length === 0) {
        return <div>No transactions</div>
    }

    return <Table rows={users} width={width} height={height} columns={generateColumns()} data={generateData(users)} />
}

const generateData = (completes: UserProxy[]): Column[] => {
    return completes.map((complete, idx) => {
        return {
            ...complete,
            index: idx + 1,
            id: complete.userId,
            parentId: null,
            width: 150,
        }
    })
}

const generateColumns = (): Column[] => {
    let columns: Column[] = [
        {
            key: 'index',
            dataKey: 'index',
            title: 'Idx',
            width: 75,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let { index } = rowData
                return TableRenderers.default(index)
            })
        },
        {
            key: 'userId',
            dataKey: 'userId',
            title: 'UserId',
            width: 250,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: UserProxy = rowData
                let { userId } = sweepstakeSurvey
                return TableRenderers.userIdRender(userId)
            })
        },
        {
            key: 'lockedTimestamp',
            dataKey: 'lockedTimestamp',
            title: 'Locked Until',
            width: 140,
            sortable: true,
            order: TableSortOrder.Descending,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: UserProxy = rowData
                let { lockedTimestamp } = sweepstakeSurvey
                return TableRenderers.relativeDateRender(lockedTimestamp)
            })
        },
        {
            key: 'fraudScore',
            dataKey: 'fraudScore',
            title: 'Fraud Score',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: UserProxy = rowData
                let { fraudScore } = transaction
                return TableRenderers.default(`${fraudScore}`)
            })
        },
        {
            key: 'cooldownScore',
            dataKey: 'cooldownScore',
            title: 'Cool Down Score',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let transaction: UserProxy = rowData
                let { cooldownScore } = transaction
                return TableRenderers.default(`${cooldownScore}`)
            })
        },
        {
            key: 'currentEarningsCents',
            dataKey: 'currentEarningsCents',
            title: 'Current Balance',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: UserProxy = rowData
                let { currentEarningsCents } = sweepstakeSurvey
                return TableRenderers.centsToDollarRender(currentEarningsCents)
            })
        },
        {
            key: 'currentRevenueCents',
            dataKey: 'currentRevenueCents',
            title: 'Revenue',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let sweepstakeSurvey: UserProxy = rowData
                let { currentRevenueCents } = sweepstakeSurvey
                return TableRenderers.centsToDollarRender(currentRevenueCents)
            })
        },
        {
            key: 'sweepstakesComplete',
            dataKey: 'sweepstakesComplete',
            title: 'Sweepstakes Complete',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: UserProxy = rowData
                let { grlSurveyCompletes, lucidSurveyCompletes } = cashout
                let totalComletes = grlSurveyCompletes + lucidSurveyCompletes
                return TableRenderers.default(`${totalComletes}`)
            })
        },
        {
            key: 'sweepstakesTerminated',
            dataKey: 'sweepstakesTerminated',
            title: 'Sweepstakes Complete',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: UserProxy = rowData
                let { grlSurveyIncompletes, lucidSurveyTerminates } = cashout
                let totalTerminates = grlSurveyIncompletes + lucidSurveyTerminates
                return TableRenderers.default(`${totalTerminates}`)
            })
        },
        {
            key: 'sweepstakesAttempted',
            dataKey: 'sweepstakesAttempted',
            title: 'Sweepstakes Attempted',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: UserProxy = rowData
                let { grlSurveyAttempts, lucidSurveyRequests } = cashout
                let totalAttempts = grlSurveyAttempts + lucidSurveyRequests
                return TableRenderers.default(`${totalAttempts}`)
            })
        },
        {
            key: 'completionRate',
            dataKey: 'completionRate',
            title: 'Completion Rate',
            width: 110,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: UserProxy = rowData
                let { grlSurveyCompletes, lucidSurveyCompletes, grlSurveyAttempts, lucidSurveyRequests } = cashout
                let totalComletes = grlSurveyCompletes + lucidSurveyCompletes
                let totalAttempts = grlSurveyAttempts + lucidSurveyRequests
                let completionRate = `${(100 * (totalComletes / totalAttempts)).toFixed(1)}%`
                return TableRenderers.default(completionRate)
            })
        },
        {
            key: 'location',
            dataKey: 'deviceLocationCountry',
            title: 'Location',
            width: 170,
            sortable: true,
            renderer: (row => {
                let { rowData } = row
                let cashout: UserProxy = rowData
                let { deviceLocationCountry, ipCountry } = cashout
                return TableRenderers.default(`${deviceLocationCountry} (${ipCountry})`)
            })
        },
    ]
    return columns
}


export default FraudUsersTable
