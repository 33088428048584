import './TextField.css'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'

interface TextFieldProps {
    placeholder: string,
    value?: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    isPassword?: boolean
    isDate?: boolean
    showError?: boolean
}


const TextField = (props: TextFieldProps) => {
    let { placeholder, onChange, isPassword = false, isDate = false, showError, value } = props

    let type = 'text'
    if (isPassword) {
        type = 'password'
    } else if (isDate) {
        type = 'date'
    }

    return (
        <input
            className={`${showError ? 'text-field-error' : 'text-field'} ${StandardTextColors.Black} ${StandardFonts.SmallText} ${StandardBackgroundColors.BackgroundGrey} ${StandardCornerRadii.Medium}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            type={type}
            min="1920-01-01"
            max="2005-01-01"
        >
        </input>
    )
}

export default TextField