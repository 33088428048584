import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import Fade from 'react-reveal/Fade'
import './Landing.css'

class SleekHeroSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            typing: true,
            loop: 0
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
        this.updateDimensions()
    }

    updateDimensions = () => {
        this.setState({ dim: this.state.dim + 1 })
    }

    render() {
        return (
            <div className='heroUltimate'>
                <div className='heroSection'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flex: 10,
                                flexDirection: 'column'
                            }}>
                            <div className='sleekHeroCopyContainer'>
                                <p className='sleekHeaderFont'>
                                    Cash for feedback
                                </p>
                                <p className='sleekSubheaderFont'>
                                    Join over <span className='sleekSubheaderBoldFont'>
                                        150,000
                                    </span> Millennial & Gen Z’s who earn money everyday by answering surveys honestly.
                                </p>
                                <div
                                    className='hideOnMobile'
                                    style={{
                                        height: '200px'
                                    }}></div>

                                <img
                                    className='hideOnWeb'
                                    src={`screenshot.png`}
                                    style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        padding: '15px',
                                        marginBottom: '50px'
                                    }}></img>

                                <p className='sleekSubheaderFont2'>
                                    Turn your 2 cents into 15¢
                                </p>

                                <div className='heroCTAContainer'>
                                    <div>
                                        <a
                                            href='https://apps.apple.com/us/app/eureka-cash-for-feedback/id1466346433'
                                            style={{ zIndex: 100 }}
                                            target='_blank'>
                                            <div
                                                className='heroColumn heroCTA primaryButton dropShadow grow'
                                                style={{
                                                    padding: '0px',
                                                    zIndex: 100
                                                }}>
                                                <span
                                                    className='navItem'
                                                    style={{
                                                        marginBottom: '0px',
                                                        height: '100%',
                                                        verticalAlign: 'middle',
                                                        margin: 'auto',
                                                        marginLeft: '20px',
                                                        marginRight: '60px'
                                                    }}>
                                                    DOWNLOAD TODAY
                                                </span>
                                                <img
                                                    src={`rightChevron.svg`}
                                                    style={{
                                                        objectFit: 'contain',
                                                        marginRight: '20px'
                                                    }}></img>
                                            </div>
                                        </a>
                                    </div>
                                    <div style={{ flex: 1 }}></div>
                                </div>
                            </div>
                        </div>
                        <div className='sleekHeroImage'>
                            <img
                                className='hideOnMobile unselectable'
                                src={`screenshot.png`}
                                style={{
                                    objectFit: 'contain',
                                    width: '100%'
                                }}></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SleekHeroSection
