import React, { Component } from 'react'
// import { Link as RouterLink } from 'react-router-dom'
// import CollapsableNavigationBar from './CollapsableNavigationBar'
// import NotConvinced from './NotConvinced'
import SleekHero from './SleekHero'
import SleekMobileFirst from './SleekMobileFirst'
// import SleekSocialProof from './SleekSocialProof'
// import TouchOfModern from './TouchOfModern'
// import Footer from './Footer'
// import 'react-sticky-header/styles.css'
// import StickyHeader from 'react-sticky-header'
import './Landing.css'

class LandingPage extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div
                style={{
                    position: 'relative'
                }}>
                <SleekHero />
                <SleekMobileFirst />
                {/* <div ref={this.samplesRef}></div> */}
                {/* <SleekReports /> */}

                {/* <SleekHowItWorks /> */}
                {/* <TouchOfModern /> */}
                {/* <NotConvinced />
                <Footer /> */}
                {/* <img
                    src='purpleBlob.svg'
                    style={{
                        position: 'absolute',
                        zIndex: -1,
                        width: '400px',
                        // top: '40%',
                        right: '0%',
                        bottom: '0px'
                    }}></img> */}
            </div>
        )
    }
}

export default LandingPage
