import '../Styles/index.css'
import './SurveyRouter.css'
import CompletedSurveys from '../Completed/CompletedSurveys'
import DailyPoll from '../DailyPoll/DailyPoll'
import NavBar from '../Nav/NavBar'
import Offerwall from './Offerwall'
import Profile from '../Profile/Profile'
import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Router, Link, Route, Switch, Redirect } from 'react-router-dom'
import { StandardBackgroundColors, StandardFonts, StandardTextColors } from '../Styles/index'
import { useSession } from '../../Authentication/Hook'
const QuickSurvey = React.lazy(() => import('../QuickSurvey/QuickSurvey'));
const Suspense = React.Suspense

interface SurveyRouterProps extends RouteComponentProps { }

interface SurveyRouterState { }

type Props = SurveyRouterProps

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

let SurveyRouter: React.FC<SurveyRouterProps> = (props) => {
    let { location } = props
    const { eurekaUser, firebaseAuthUser, eurekaUserInitializing, firebaseAuthUserInitializing } = useSession()
    let [animateUserBalance, setAnimateUserBalance] = useState<boolean>(false)
    const { height, width } = useWindowDimensions();

    console.log(`+++++++Survey router!`)
    console.log(`firebaseAuthUser is ${firebaseAuthUser}`)
    console.log(`eureka user is ${eurekaUser}`)
    console.log(`eurekaUserInitializing => ${eurekaUserInitializing}`)
    console.log(`firebaseAuthUserInitializing => ${firebaseAuthUserInitializing}`)
    console.log(`${height} x ${width}`)
    if (eurekaUserInitializing || firebaseAuthUserInitializing) {
        return <div />
    } else if (eurekaUser === undefined) {
        console.log('Redirect to /login')
        return <Redirect to='/login' />
    } else if (height <= 700 || width <= 700) {
        return <div>
            <p className={`${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Eureka is not supported on small screens. Please use a computer or download our iOS app. </p>
        </div>
    }

    return (
        <div className={`survey-router-container ${StandardBackgroundColors.BackgroundLightGrey}`}>
            <NavBar pathname={location.pathname} eurekaUser={eurekaUser} animateUserBalance={animateUserBalance} setAnimateUserBalance={setAnimateUserBalance} />
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route path='/surveys/quick-survey/:id' render={(props) => <QuickSurvey eurekaUser={eurekaUser} firestoreId={props.match.params.id} />} />
                    <Route exact path='/surveys/daily-poll' render={() => <DailyPoll eurekaUser={eurekaUser} setAnimateUserBalance={setAnimateUserBalance} />} />
                    <Route exact path='/surveys/completed' render={() => <CompletedSurveys eurekaUser={eurekaUser} />} />
                    <Route exact path='/surveys/profile' render={() => <Profile eurekaUser={eurekaUser} />} />
                    <Route exact path='/surveys' render={() => <Offerwall eurekaUser={eurekaUser} setAnimateUserBalance={setAnimateUserBalance} />} />
                    <Route path='*' render={() => <Redirect to='/surveys' />} />
                </Switch>
            </Suspense>
        </div>
    )
}

export default SurveyRouter