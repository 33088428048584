import firebase from 'firebase'
import { PROFILE_REQUEST, PROFILE_SET_ATTRIBUTE, PROFILE_SUCCESS } from './Actions'
import { ProfileState } from './Profile'
import { reducer } from './Reducer'
import { useState, useReducer, useEffect } from 'react'
import { UserProxy } from '../../sharedModels/UserProxy'
import { UserAttributesProxy } from '../../sharedModels/UserAttributesProxy'

let initialState: ProfileState = {
    isLoading: false,
    userAttributes: []
}

// Interface that gets returned from the hook. Usually its in an array format, but that makes it harder to type! So that's
// why this exists.
export interface ProfileHook {
    state: ProfileState,
    setUpdatedUserAttribute: React.Dispatch<React.SetStateAction<UserAttributesProxy | undefined>>
}

export const useProfileHook = (eurekaUser: UserProxy): ProfileHook => {

    let [updatedUserAttribute, setUpdatedUserAttribute] = useState<UserAttributesProxy>()
    let [state, dispatch] = useReducer(reducer, initialState)

    // Get user attributes
    useEffect(() => {
        let fetchData = async () => {

            dispatch({ type: PROFILE_REQUEST })

            let getUserAttributesAPI = firebase.functions().httpsCallable('web-functions-getUserAttributes')
            let { userId } = eurekaUser
            let results = await getUserAttributesAPI({ userId: userId })

            let d = results.data as UserAttributesProxy[]
            dispatch({
                type: PROFILE_SUCCESS,
                userAttributes: d
            })
        }

        fetchData()
    }, [])


    // Set user attribute
    useEffect(() => {
        let fetchData = async () => {
            let safeUpdatedUserAttribute = updatedUserAttribute as UserAttributesProxy
            let getUserAttributesAPI = firebase.functions().httpsCallable('web-functions-setUserAttributeById')
            let { firestoreId, responses } = safeUpdatedUserAttribute as UserAttributesProxy

            // Do not client block on this, optimistically update
            let results = getUserAttributesAPI({
                userAttributeId: firestoreId,
                responses: responses
            })
            console.log(results)
            dispatch({
                type: PROFILE_SET_ATTRIBUTE,
                updatedUserAttribute: safeUpdatedUserAttribute
            })
            setUpdatedUserAttribute(undefined)
        }

        if (updatedUserAttribute !== undefined) {
            console.log(`Updating ${updatedUserAttribute.attributeName} => ${updatedUserAttribute.responses}`)
            fetchData()
        }
    }, [updatedUserAttribute])

    return {
        state: state,
        setUpdatedUserAttribute: setUpdatedUserAttribute
    }
}
