import './DailyPoll.css'
import Button, { ButtonType } from '../Button/Button'
import Modal from '../Modal/Modal'
import React, { useState } from 'react'
import Spinner from '../Spinner/Spinner'
import useMeasure from 'react-use-measure'
import { DailyPollProxy, DailyPollResponseOption } from '../../sharedModels/DailyPollProxy'
import { StandardFonts, StandardTextColors, StandardBackgroundColors, StandardCornerRadii, StandardDropShadows, StandardMargin } from '../Styles/index'
import { UserProxy } from '../../sharedModels/UserProxy'
import { useDailyPollHook, DailyPollHook } from './Hook'
import { useSpring, animated } from 'react-spring'

type Optional<T> = T | undefined // TODO: Use this everywhere `?` is used

interface DailyPollProps {
    eurekaUser: UserProxy
    setAnimateUserBalance: React.Dispatch<React.SetStateAction<boolean>>
}

export interface DailyPollState {
    isLoading: boolean,
    dailyPoll: Optional<DailyPollProxy>
    incrementBalance: boolean
}

export let DailyPoll: React.FC<DailyPollProps> = (props) => {
    let { eurekaUser, setAnimateUserBalance } = props
    let { state, setResponseText }: DailyPollHook = useDailyPollHook(eurekaUser)
    let { dailyPoll, isLoading, incrementBalance } = state
    let [closeModal, setCloseModal] = useState<boolean>(false)

    if (isLoading) {
        return <Spinner />
    } else if (dailyPoll === undefined) { // Unwrap optional model
        return <div className='daily-poll-container'></div>
    }

    let { questionText, responseObjectsOrdered, responseText, roundedVoteCount, timeRemaining, userEarningsString } = dailyPoll
    timeRemaining = timeRemaining.split(" Remaining")[0]

    let card: Optional<JSX.Element> = undefined
    if (incrementBalance && closeModal === false) {
        card = <div>
            <p>Congrat, you earned {userEarningsString}</p>
            <Button
                title={`Claim ${userEarningsString}`}
                type={ButtonType.Yellow}
                onClick={() => {
                    setAnimateUserBalance(true)
                    setTimeout(() => {
                        setCloseModal(true)
                    }, 3000)
                }} />
        </div>
    }

    return (
        <div className={`daily-poll-container ${StandardMargin.NormalAll}`}>
            {card && <Modal>{card}</Modal>}
            <div className={`daily-poll-voting-container ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal} ${StandardBackgroundColors.White}`}>
                <p className={`${StandardFonts.Legal} ${StandardTextColors.Black}`}>A penny for your thoughts...</p>
                <p className={`${StandardFonts.SmallTextBold} ${StandardTextColors.Purple}`}>{questionText}</p>
                <div className='daily-poll-button-container'>
                    {responseObjectsOrdered.map(responseObject => {
                        return <DailyPollButton
                            text={responseObject.text}
                            percentage={responseObject.percentage}
                            userSelectedResponse={responseText === responseObject.text}
                            showProgress={responseText !== undefined}
                            onClick={() => {
                                if (responseText) {
                                    console.log(`Skipping vote!`)
                                } else {
                                    console.log(`Vote ${responseObject.text}`)
                                    setResponseText(responseObject.text)
                                }
                            }} />
                    })}
                </div>
                <div className='daily-poll-bottom-stats'>
                    <div className='daily-poll-line-container'>
                        <div className='daily-poll-line-top'></div>
                        <div className='daily-poll-line-bottom'></div>
                    </div>
                    <div className='daily-poll-stat-item'>
                        <p className={`${StandardTextColors.Black} ${StandardFonts.SmallTextBold}`}>{roundedVoteCount}</p>
                        <p className={`${StandardTextColors.TextGrey} ${StandardFonts.Legal}`}>votes</p>
                    </div>
                    <div className='daily-poll-stat-item'>
                        <p className={`${StandardTextColors.Black} ${StandardFonts.SmallTextBold}`}>{timeRemaining}</p>
                        <p className={`${StandardTextColors.TextGrey} ${StandardFonts.Legal}`}>remaining</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DailyPoll

interface DailyPollButtonProps {
    text: String,
    percentage: number,
    userSelectedResponse: boolean,
    showProgress: boolean,
    onClick: () => void
}
const DailyPollButton: React.FC<DailyPollButtonProps> = (props) => {

    let { text, percentage, userSelectedResponse, showProgress, onClick } = props
    const [ref, bounds] = useMeasure()
    let { width } = bounds

    let backgroundPadding = 0.20 * width
    let backgroundWidth = ((width - backgroundPadding) * (percentage / 100)) + backgroundPadding

    const springProps = useSpring({
        width: `${backgroundWidth}px`,
        from: { width: "0px" },
        delay: 1000,
    })

    let buttonClassName = ''
    let textClassName = ''
    let percentageClassName = ''
    let backgroundClassName = ''

    if (showProgress) {
        console.log(`Showing progress! `)
        if (userSelectedResponse) {
            buttonClassName = 'daily-poll-button-user-selected'
            textClassName = `${StandardTextColors.Green} ${StandardFonts.SmallTextBold}`
            percentageClassName = `${StandardTextColors.Green} ${StandardFonts.SmallTextBold}`
            backgroundClassName = `daily-poll-background-light-green`
        } else {
            buttonClassName = 'daily-poll-button-user-not-selected'
            textClassName = `${StandardTextColors.Black} ${StandardFonts.SmallText}`
            percentageClassName = `${StandardTextColors.TextGrey} ${StandardFonts.SmallTextBold}`
            backgroundClassName = `daily-poll-button-background-unselected`
        }
    } else {
        buttonClassName = 'daily-poll-button-unselected daily-poll-votable'
        textClassName = `${StandardTextColors.Black} ${StandardFonts.SmallText}`
    }

    return (
        <div ref={ref} className={`daily-poll-button ${buttonClassName} ${StandardCornerRadii.Medium}`} onClick={onClick}>
            {showProgress && <animated.div className={`daily-poll-background ${backgroundClassName}`} style={springProps}></animated.div>}
            <p className={textClassName}>{text}</p>
            <div className='daily-poll-flex-grow'></div>
            {showProgress && <p className={percentageClassName}>{percentage.toFixed(1)}%</p>}
        </div >
    )
}