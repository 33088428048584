import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

// Define all actions that can occur on the UserLookup Component
export const LUCID_SWEEPSTAKES_REQUEST = 'LUCID_SWEEPSTAKES_REQUEST'
export const LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE = 'LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE'


export interface LucidSweepstakesRequest {
    type: typeof LUCID_SWEEPSTAKES_REQUEST,
}
export interface LucidSweepstakesSnapshotUpdate {
    type: typeof LUCID_SWEEPSTAKES_SNAPSHOT_UPDATE,
    sweepstakes: SweepstakeSurveyProxy[]
}


export type LucidSweepstakesActions = LucidSweepstakesRequest | LucidSweepstakesSnapshotUpdate 