import './StagingCard.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import React from 'react'
import Shelf, { ShelfItem } from '../Shelf/Shelf'
import Tooltip from '@material-ui/core/Tooltip'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'
import { SweepstakeSurveyProxy } from '../../sharedModels/SweepstakeSurveyProxy'

interface StagingCardProps {
    sweepstakeSurvey: SweepstakeSurveyProxy
    onClick: () => void
}

const StagingCard = (props: StagingCardProps) => {

    let { sweepstakeSurvey, onClick } = props
    let { lockedText } = sweepstakeSurvey
    let shelfItems: ShelfItem[] = [{
        text: '💰 Reward',
        value: sweepstakeSurvey.userEarningsString
    },
    {
        text: '🎟 Sweepstake',
        value: sweepstakeSurvey.userSweepstakesString
    },
    {
        text: '⏱ Time',
        value: sweepstakeSurvey.userEstimatedDurationString
    }]

    if (lockedText) {
        return (
            <div className='staging-card-container'>
                <div className='staging-card-content-locked'>
                    <div className='image-container'>
                        <img className='staging-card-detail-image-pre' src='sweepstakeStaging.png' />
                    </div>
                    <p className={`staging-card-info-text-start ${StandardFonts.SmallText} ${StandardTextColors.Black}`}>{lockedText}</p>
                    <Button className='staging-card-button-locked' title={'Locked'} type={ButtonType.Locked} onClick={() => { }} />
                </div>
            </div>
        )
    } else {
        return (
            <div className='staging-card-container'>
                <div className='staging-card-content-pre'>
                    <div className='staging-card-image-row'>
                        <div className='image-container'>
                            <img className='staging-card-detail-image-pre' src='sweepstakeStaging.png' />
                        </div>
                        <Shelf items={shelfItems} />
                    </div>
                    <div className='staging-card-right-half'>
                        <p className={`staging-card-info-text-start ${StandardFonts.Legal} ${StandardTextColors.Black}`}>To quality for sweepstakes, you must match the profile brands are looking for and complete the survey.</p>
                        <p className={`staging-card-info-text-start ${StandardFonts.Legal} ${StandardTextColors.TextGrey}`}>Even though you are prequalified for this survey, you still may not be able to complete it. Brands look for respondents with specific experiences with a product or service. Don’t worry if a Brand disqualifies you. We’ll find you another survey!</p>
                        <div className='staging-card-button-row'>
                            <Button
                                className='staging-card-button-padding'
                                title={'Start'}
                                textAlignment={ButtonTextAlignment.Center}
                                type={ButtonType.Yellow}
                                onClick={onClick} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StagingCard