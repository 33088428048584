import './Profile.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import Modal from '../Modal/Modal'
import React, { useState, Fragment } from 'react'
import Select from 'react-select'
import Spinner from '../Spinner/Spinner'
import TextField from '../TextField/TextField'
import moment from 'moment'
import { DateInputComponent, DateFormats } from 'react-controlled-date-input'
import { StandardDropShadows, StandardCornerRadii, StandardBackgroundColors, StandardFonts, StandardTextColors, StandardMargin } from '../Styles/index'
import { SurveyQuestionFormat } from '../../sharedModels/SurveyProxy'
import { UserAttributesProxy } from '../../sharedModels/UserAttributesProxy'
import { UserProxy } from '../../sharedModels/UserProxy'
import { useAuth } from '../../Authentication/Hook'
import { useProfileHook, ProfileHook } from './Hook'

interface ProfileProps {
    eurekaUser: UserProxy
}

export interface ProfileState {
    isLoading: boolean,
    userAttributes: UserAttributesProxy[],
}

export let Profile: React.FC<ProfileProps> = (props) => {
    let { eurekaUser } = props
    let { state, setUpdatedUserAttribute }: ProfileHook = useProfileHook(eurekaUser)
    let { userAttributes, isLoading } = state

    let { state: authState, setLogOut } = useAuth()
    let { firebaseAuthUser, logOutLoading, logOutLoadingSuccessful, logOutError } = authState


    let [showDeleteAccountModal, setShowDeleteAccountModal] = useState<boolean>(false)
    let [showLogOutModal, setShowLogOutModal] = useState<boolean>(false)

    if (isLoading) {
        return <Spinner />
    }
    let { email, userId } = eurekaUser
    return (
        <div className={`user-attributes-container ${StandardMargin.NormalAll}`}>
            {showDeleteAccountModal && <Modal onClick={() => {
                setShowDeleteAccountModal(false)
            }}>
                <p>Are you sure you want to delete your account? This cannot be undone.</p>
                <Button
                    title='Delete Account'
                    type={ButtonType.Red}
                    textAlignment={ButtonTextAlignment.Center}
                    onClick={() => {
                    }}
                />
            </Modal>}
            {showLogOutModal && <Modal onClick={() => {
                setShowLogOutModal(false)
            }}>
                <p>Are you sure you want to log out of your account?</p>
                <Button
                    title='Log Out'
                    type={ButtonType.Red}
                    textAlignment={ButtonTextAlignment.Center}
                    onClick={() => {
                        setLogOut(true)
                    }}
                />
            </Modal>}
            <div className={`user-attributes-item-left`}>
                <p className={`user-attributes-title ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Account</p>
                <div className={`user-attributes-item-left-content ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}`}>
                    <p className={`${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>{email}</p>
                    <div className='profile-left-panel-items'>
                        <p className={`profile-left-panel-item ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}
                            onClick={() => {
                                let heplURL = `www.eurekarewards.com/help`
                                window.open(heplURL, '_blank') //to open new page
                            }}>Help</p>
                        <p className={`profile-left-panel-item ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}
                            onClick={() => {
                                let mailTo = `mailto:support@eurekarewards.com?subject=Support&body=\n\n\nSupport Ticket: ${userId}`
                                window.open(mailTo, '_blank') //to open new page
                            }}>Contact Support</p>
                        <p className={`profile-left-panel-item ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}
                            onClick={() => {
                                let privacyPolicy = `www.eurekarewards.com/privacy-policy`
                                window.open(privacyPolicy, '_blank') //to open new page
                            }}>Privacy Policy</p>
                        <p className={`profile-left-panel-item ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}
                            onClick={() => {
                                setShowDeleteAccountModal(true)
                            }}>Delete Account</p>
                        <p className={`profile-left-panel-item ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}
                            onClick={() => {
                                setShowLogOutModal(true)
                            }}>Log Out</p>
                    </div>
                </div>
            </div>
            <div className={`user-attributes-item-right`}>
                <p className={`user-attributes-title ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Profile</p>
                <div className={`user-attributes-item-right-content ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}`}>
                    {userAttributes.map(userAttribute => {
                        let { format } = userAttribute
                        if (format === SurveyQuestionFormat.MultipleChoice) {
                            return <UserAttributeMultipleChoiceCell userAttribute={userAttribute} setUpdatedUserAttribute={setUpdatedUserAttribute} />
                        } else if (format === SurveyQuestionFormat.Checkbox) {
                            return <UserAttributeCheckboxCell userAttribute={userAttribute} setUpdatedUserAttribute={setUpdatedUserAttribute} />
                        } else if (format === SurveyQuestionFormat.Date) {
                            return <div /> //TODO: fix date cell to prefil with text
                            return <UserAttributeDateCell userAttribute={userAttribute} setUpdatedUserAttribute={setUpdatedUserAttribute} />
                        }
                    })}
                </div>
            </div>
        </div >
    )
}

export default Profile

interface UserAttributeMultipleChoiceCellProp {
    userAttribute: UserAttributesProxy
    setUpdatedUserAttribute: React.Dispatch<React.SetStateAction<UserAttributesProxy | undefined>>
}

const UserAttributeMultipleChoiceCell: React.FC<UserAttributeMultipleChoiceCellProp> = (props) => {
    let { userAttribute, setUpdatedUserAttribute } = props
    let { attributeName, responses, responseOptions } = userAttribute

    responses = responses || []
    let selectedOptions = responses.map(option => {
        return { value: option, label: option, color: `#2AD273` }
    })

    let options = responseOptions.map(option => {
        return { value: option, label: option, color: `#FED51E` }
    })

    let onChange = (selectedOption: string) => {
        if (userAttribute.responses === [selectedOption]) { return }
        userAttribute.responses = [selectedOption]
        setUpdatedUserAttribute(userAttribute)
    }

    return (
        <div className='user-attribute-cell'>
            <p className={`user-attribute-cell-label ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}>{attributeName}</p>
            <div className='user-attribute-cell-grow'></div>
            <div className='user-attribute-cell-selection'>
                <Select className={`${StandardFonts.SmallText}`}
                    options={options}
                    defaultValue={selectedOptions}
                    onChange={(e => {
                        onChange(e.value)
                    })}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#F1F6FD',
                            primary: '#00D767',
                        },
                    })} />
            </div>
        </div >
    )
}

interface UserAttributeCheckboxCellProp {
    userAttribute: UserAttributesProxy
    setUpdatedUserAttribute: React.Dispatch<React.SetStateAction<UserAttributesProxy | undefined>>
}

const UserAttributeCheckboxCell: React.FC<UserAttributeCheckboxCellProp> = (props) => {
    let { userAttribute, setUpdatedUserAttribute } = props
    let { attributeName, responses, responseOptions } = userAttribute

    responses = responses || []
    let selectedOptions = responses.map(option => {
        return { value: option, label: option, color: `#2AD273` }
    })

    let options = responseOptions.map(option => {
        return { value: option, label: option, color: `#FED51E` }
    })

    let onChange = (selectedOptions: string[]) => {
        console.log(selectedOptions)
        userAttribute.responses = selectedOptions
        setUpdatedUserAttribute(userAttribute)
    }

    return (
        <div className='user-attribute-cell'>
            <p className={`user-attribute-cell-label ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}>{attributeName}</p>
            <div className='user-attribute-cell-grow'></div>
            <div className='user-attribute-cell-selection'>
                <Select className={`${StandardFonts.SmallText}`}
                    options={options}
                    defaultValue={selectedOptions}
                    isMulti={true}
                    onChange={(e => {
                        onChange(e.map(option => { return option.value }))
                    })}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#F1F6FD',
                            primary: '#00D767',
                        },
                    })} />
            </div>
        </div >
    )
}

interface UserAttributeDateCellProp {
    userAttribute: UserAttributesProxy
    setUpdatedUserAttribute: React.Dispatch<React.SetStateAction<UserAttributesProxy | undefined>>
}

const UserAttributeDateCell: React.FC<UserAttributeDateCellProp> = (props) => {
    let { userAttribute, setUpdatedUserAttribute } = props
    let { attributeName, responses } = userAttribute

    responses = responses || []

    let onChange = (selectedOption: string) => {
        console.log('UPDATING!!!')
        console.log(selectedOption)
        userAttribute.responses = [selectedOption]
        setUpdatedUserAttribute(userAttribute)
    }

    return (
        <div className='user-attribute-cell'>
            <p className={`user-attribute-cell-label ${StandardFonts.SmallText} ${StandardTextColors.TextGrey}`}>{attributeName}</p>
            <div className='user-attribute-cell-grow'></div>
            <div className='user-attribute-cell-selection'>
                <TextField
                    placeholder='Birthday'
                    value={responses[0]}
                    isDate={true}
                    // showError={showErrors && birthday.length === 0}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        console.log(`Text changed! ${event.target.value}`)
                        let formattedBirthday = event.target.value.split('-').join('/')
                        var dateCast = moment(formattedBirthday).format('YYYY/MM/DD');
                        console.log(dateCast)
                        var dateFormatted = moment(formattedBirthday).format('MM/DD/YYYY');
                        console.log(dateFormatted)
                        console.log(formattedBirthday)
                        var now = moment()
                        var diff = now.diff(dateFormatted, 'years'); // calculates patient's age in years
                        console.log(diff)
                        if (diff >= 15 && diff <= 100) {
                            console.log('Valid format!')
                            onChange(dateFormatted)
                        }
                    }} />
            </div>
        </div >
    )
}