
import { LoginActions, LOGIN_FAILURE, LOGIN_SUBMIT, LOGIN_SUCCESS, LOG_IN_FORGOT_PASSWORD_SEND, LOG_IN_FORGOT_PASSWORD_SHOW } from './Actions'
import { LogInState } from './Login'

export const reducer = (state: LogInState, action: LoginActions): LogInState => {
    switch (action.type) {
        case LOGIN_SUBMIT:
            return {
                isLoading: true,
                showForgotPassword: false,
                forgotPasswordEmail: state.forgotPasswordEmail,
            }
        case LOGIN_SUCCESS:
            return {
                isLoading: true,
                showForgotPassword: false,
                forgotPasswordEmail: state.forgotPasswordEmail,
            }
        case LOGIN_FAILURE:
            return {
                isLoading: true,
                showForgotPassword: false,
                forgotPasswordEmail: state.forgotPasswordEmail,
            }
        case LOG_IN_FORGOT_PASSWORD_SHOW:
            return {
                isLoading: true,
                showForgotPassword: action.show,
                forgotPasswordEmail: state.forgotPasswordEmail,
            }
        case LOG_IN_FORGOT_PASSWORD_SEND:
            return {
                isLoading: true,
                showForgotPassword: false,
            }
    }
}