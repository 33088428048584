import { SweepstakeSurveyProxy } from '../../sharedModels/SweepstakeSurveyProxy'
import { QuickSurveyTemplateProxy } from '../../sharedModels/QuickSurveyProxy'

export const OFFERWALL_SURVEY_REQUEST = 'OFFERWALL_SURVEY_REQUEST'
export const OFFERWALL_SURVEY_SUCCESS = 'OFFERWALL_SURVEY_SUCCESS'
export const OFFERWALL_SURVEY_DID_START = 'OFFERWALL_SURVEY_DID_START' // TODO: kirby add SWEEPSTAKE
export const OFFERWALL_SURVEY_DID_UPDATE = 'OFFERWALL_SURVEY_DID_UPDATE' // TODO: kirby add SWEEPSTAKE
export const OFFERWALL_SURVEY_DID_COMPLETE = 'OFFERWALL_SURVEY_DID_COMPLETE' // TODO: kirby add SWEEPSTAKE
export const OFFERWALL_SURVEY_DID_INCOMPLETE = 'OFFERWALL_SURVEY_DID_INCOMPLETE'
export const OFFERWALL_QUICK_SURVEY_DID_START = 'OFFERWALL_QUICK_SURVEY_DID_START'
export const OFFERWALL_QUICK_SURVEY_DID_UPDATE = 'OFFERWALL_QUICK_SURVEY_DID_UPDATE'
export const OFFERWALL_QUICK_SURVEY_DID_COMPLETE = 'OFFERWALL_QUICK_SURVEY_DID_COMPLETE'

export interface OfferwallSurveyRequest {
    type: typeof OFFERWALL_SURVEY_REQUEST,
}

export interface OfferwallSurveySuccess {
    type: typeof OFFERWALL_SURVEY_SUCCESS,
    quickSurveys: QuickSurveyTemplateProxy[]
    sweepstakeSurveys: SweepstakeSurveyProxy[],
}

export interface OfferwallSurveyDidStart {
    type: typeof OFFERWALL_SURVEY_DID_START,
    sweepstakeSurvey: SweepstakeSurveyProxy,
}

export interface OfferwallSurveyDidUpdate {
    type: typeof OFFERWALL_SURVEY_DID_UPDATE,
    sweepstakeSurvey: SweepstakeSurveyProxy,
}

export interface OfferwallSurveyDidComplete {
    type: typeof OFFERWALL_SURVEY_DID_COMPLETE,
    sweepstakeSurvey: SweepstakeSurveyProxy,
}

export interface OfferwallSurveyDidIncomplete {
    type: typeof OFFERWALL_SURVEY_DID_INCOMPLETE,
    sweepstakeSurvey: SweepstakeSurveyProxy,
}

export interface OfferwallQuickSurveyDidStart {
    type: typeof OFFERWALL_QUICK_SURVEY_DID_START,
    quickSurvey: QuickSurveyTemplateProxy,
}

export interface OfferwallQuickSurveyDidUpdate {
    type: typeof OFFERWALL_QUICK_SURVEY_DID_UPDATE,
    quickSurvey: QuickSurveyTemplateProxy,
}

export interface OfferwallQuickSurveyDidComplete {
    type: typeof OFFERWALL_QUICK_SURVEY_DID_COMPLETE,
    quickSurvey: QuickSurveyTemplateProxy,
}

export type OfferwallSurveyActions = OfferwallSurveySuccess | OfferwallSurveyRequest | OfferwallSurveyDidStart | OfferwallSurveyDidUpdate | OfferwallSurveyDidComplete | OfferwallSurveyDidIncomplete | OfferwallQuickSurveyDidComplete | OfferwallQuickSurveyDidUpdate | OfferwallQuickSurveyDidStart