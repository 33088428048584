import firebase from 'firebase'
import { CASHOUT_SUCCESS, CASHOUT_SUBMITTED, CASHOUT_FAILURE } from './Actions'
import { CashoutState } from './Cashout'
import { UserProxy } from '../../sharedModels/UserProxy'
import { reducer } from './Reducer'
import { useReducer, useEffect, useState } from 'react'

let initialState: CashoutState = {
    isLoading: false,
    success: false,
    error: undefined
}

// Interface that gets returned from the hook. Usually its in an array format, but that makes it harder to type! So that's
// why this exists.
export interface CashoutHook {
    state: CashoutState,
    setCashoutEmail: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const useCashoutHook = (): CashoutHook => {

    let [state, dispatch] = useReducer(reducer, initialState)
    let [cashoutEmail, setCashoutEmail] = useState<string>()
    // Search for user
    useEffect(() => {
        let fetchData = async () => {
            console.log('About to createCashout')
            dispatch({ type: CASHOUT_SUBMITTED })

            let createCashoutAPI = firebase.functions().httpsCallable('web-functions-createCashout')
            let results = await createCashoutAPI({ payPalEmail: cashoutEmail })

            let success = results.data as boolean
            console.log(`Create cashout completed!`)
            console.log(success)
            if (success) {
                dispatch({
                    type: CASHOUT_SUCCESS
                })
            } else {
                dispatch({
                    type: CASHOUT_FAILURE
                })
            }
        }

        if (cashoutEmail) {
            fetchData()
        }

    }, [cashoutEmail])

    return {
        state: state,
        setCashoutEmail: setCashoutEmail
    }
}
