
export enum StandardFonts {
    H1 = 'h1',
    H2 = 'h2',
    LargeText = 'largeText',
    LargeTextBold = 'largeTextBold',
    SmallText = 'smallText',
    SmallTextBold = 'smallTextBold',
    Legal = 'legal',
    Number = 'number',
    SweepstakeNumber = 'sweepstakeNumber'
}

export enum StandardTextColors {
    Green = 'greenText',
    LightGreen = 'lightGreenText',
    Purple = 'purpleText',
    Yellow = 'yellowText',
    Red = 'redText',
    LightRed = 'lightRedText',
    BackgroundLightGrey = 'backgroundLightGreyText',
    BackgroundGrey = 'backgroundGreyText',
    BorderGrey = 'borderGreyText',
    TextGrey = 'textGreyText',
    Black = 'blackText',
}

export enum StandardBackgroundColors {
    Green = 'greenBackground',
    LightGreen = 'lightGreenBackground',
    Purple = 'purpleBackground',
    LightPurple = 'lightPurpleBackground',
    Yellow = 'yellowBackground',
    Red = 'redBackground',
    LightRed = 'lightRedBackground',
    BackgroundLightGrey = 'backgroundLightGreyBackground',
    BackgroundGrey = 'backgroundGreyBackground',
    BorderGrey = 'borderGreyBackground',
    TextGrey = 'textGreyBackground',
    Black = 'blackBackground',
    White = 'whiteBackground',
}

export enum StandardDropShadows {
    Normal = 'dropShadowNormal'
}

export enum StandardCornerRadii {
    Normal = 'cornerRadiusNormal',
    Small = 'cornerRadiusSmall',
    Medium = 'cornerRadiusMedium'
}

export enum StandardMargin {
    NormalAll = 'normalAll',
    NormalLeftRight = 'normalLeftRight',
    NormalLeft = 'normalLeft',
    NormalRight = 'normalRight',
    NormalBottom = 'normalBottom'
}

export let Clickable = 'clickable' 