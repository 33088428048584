import { CompletesActions, COMPLETES_REQUEST, COMPLETES_SNAPSHOT_UPDATE } from './Actions'
import { CompletesState } from './Completes'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

export const reducer = (state: CompletesState, action: CompletesActions): CompletesState => {
    switch (action.type) {
        case COMPLETES_REQUEST:
            return {
                isLoading: true,
                completes: []
            }
        case COMPLETES_SNAPSHOT_UPDATE:
            let uniqueSweepstakeSurveys = state.completes.concat(action.completes)
            uniqueSweepstakeSurveys = uniqueSweepstakeSurveys.filter((sweepstakeSurvey, index, self) =>
                index === self.findIndex((v) => (
                    v.firestoreId === sweepstakeSurvey.firestoreId
                ))
            )
            let completes = uniqueSweepstakeSurveys.sort((a, b) => {
                let aTs = a.endTimestamp || 0
                let bTs = b.endTimestamp || 0
                return bTs - aTs
            })
            return {
                isLoading: false,
                completes: completes
            }
    }
}