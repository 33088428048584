
export const CASHOUT_SUBMITTED = 'CASHOUT_SUBMITTED'
export const CASHOUT_SUCCESS = 'CASHOUT_SUCCESS'
export const CASHOUT_FAILURE = 'CASHOUT_FAILURE'

export interface CashoutSubmitted {
    type: typeof CASHOUT_SUBMITTED,
}

export interface CashoutSuccess {
    type: typeof CASHOUT_SUCCESS,
}

export interface CashoutFailure {
    type: typeof CASHOUT_FAILURE,
}

export type CashoutActions = CashoutSubmitted | CashoutSuccess | CashoutFailure