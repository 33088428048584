import React, { createContext } from 'react'
import firebase from 'firebase'
import moment from 'moment'
import { AUTH_DELETE_ACCOUNT, AUTH_DELETE_ACCOUNT_FAILURE, AUTH_DELETE_ACCOUNT_SUCCESS, AUTH_LOG_OUT, AUTH_LOG_OUT_FAILURE, AUTH_LOG_OUT_SUCCESS, AUTH_SIGN_UP_FAILURE, AUTH_SIGN_UP_SUBMIT, AUTH_SIGN_UP_SUCCESS, AUTH_INITIALIZING, AUTH_FIREBASE_UPDATE_USER, AUTH_UPDATE_USER, AUTH_LOG_IN, AUTH_LOG_IN_FAILURE, AUTH_LOG_IN_SUCCESS, AUTH_SIGN_IN_ANONYMOUSLY, AUTH_FIREBASE_UPDATE_USER_FAILURE, AUTH_UPDATE_FAILURE } from './Actions'
import { QuerySnapshot, QueryDocumentSnapshot, DocumentSnapshot } from '@firebase/firestore-types'
import { UserProxy } from '../sharedModels/UserProxy'
import { reducer } from './Reducer'
import { useState, useReducer, useEffect, useContext } from 'react'
const https = require('https');
const request = require('request');
const axios = require('axios');

const Timestamp = firebase.firestore.Timestamp

export interface AuthState {
    firebaseAuthUser?: firebase.User,
    firebaseAuthUserInitializing: boolean

    eurekaUser?: UserProxy,
    eurekaUserInitializing: boolean

    userIsLoggedIn: boolean

    // Log In Fields
    loginLoading: boolean
    loginError?: string

    // Sign Up Fields
    signUpLoading: boolean
    signUpError?: string

    // Log Out fields
    logOutLoading: boolean
    logOutLoadingSuccessful: boolean
    logOutError?: string

    // Delete Account fields
    deleteAccountLoading: boolean
    deleteAccountLoadingSuccessful: boolean
    deleteAccountError?: string
}

let initialState: AuthState = {
    firebaseAuthUser: undefined,
    eurekaUser: undefined,
    firebaseAuthUserInitializing: true,
    eurekaUserInitializing: true,
    userIsLoggedIn: false,
    loginLoading: false,
    loginError: undefined,
    signUpLoading: false,
    signUpError: undefined,
    logOutLoading: false,
    logOutLoadingSuccessful: false,
    logOutError: undefined,
    deleteAccountLoading: false,
    deleteAccountLoadingSuccessful: false,
    deleteAccountError: undefined
}

export const userContext = React.createContext<AuthState>(initialState)

export interface LoginCredentials {
    email: string,
    password: string
}

export interface OnboardingUserModel {
    email: string,
    password: string,
    gender: string,
    birthday: string
    zipCode: string,

    clientVersion?: string
    createdHumanReadableDate?: string
    deviceType?: string
    userId?: string
}

export interface AuthHook {
    state: AuthState,
    setLoginCredentials: React.Dispatch<React.SetStateAction<LoginCredentials | undefined>>
    setSignUpCredentials: React.Dispatch<React.SetStateAction<OnboardingUserModel | undefined>>
    setDeleteAccount: React.Dispatch<React.SetStateAction<boolean | undefined>>
    setLogOut: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export const useSession = (): AuthState => {
    return useContext(userContext)
}

export const userSnapshotOnUpdate = (authUserId: string, observer: {
    next?: (snapshot: DocumentSnapshot) => void
    error?: (error: Error) => void
    complete?: () => void
}) => {
    return firebase.firestore()
        .collection('users')
        .doc(authUserId)
        .onSnapshot(observer)
}

export const useAuth = (): AuthHook => {

    let [loginCredentials, setLoginCredentials] = useState<LoginCredentials>()
    let [signUpCredentials, setSignUpCredentials] = useState<OnboardingUserModel>()
    let [deleteAccount, setDeleteAccount] = useState<boolean>()
    let [logOut, setLogOut] = useState<boolean>()

    let [state, dispatch] = useReducer(reducer, initialState)
    let { firebaseAuthUser } = state

    // Load the cached firebase user
    useEffect(() => {
        // listen for auth state changes
        const unsubscribe = firebase.auth().onAuthStateChanged((firebaseAuthUser: firebase.User | null) => {
            if (firebaseAuthUser === null) {
                console.log(`FirebaseAuthUser Skipped!`)
                dispatch({
                    type: AUTH_FIREBASE_UPDATE_USER_FAILURE
                })
                return
            }
            console.log(`Update firebase user`)
            console.log(firebaseAuthUser)
            dispatch({
                type: AUTH_FIREBASE_UPDATE_USER,
                firebaseUser: firebaseAuthUser
            })
        })
        // unsubscribe to the listener when unmounting
        return () => unsubscribe()
    }, [])

    // Attach snapshot listener to the authenticated firebase user
    useEffect(() => {
        console.log(`Attaching snapshot to eureka user`)
        if (firebaseAuthUser === undefined) {
            console.log('Snapshot listener failed')
            dispatch({
                type: AUTH_UPDATE_FAILURE
            })
            return
        }
        let userId = firebaseAuthUser.uid
        console.log(`userId => ${userId}`)
        const unsubscribe = userSnapshotOnUpdate(userId, {
            next: async (documentSnapshot) => {
                const updatedUser = <UserProxy>documentSnapshot.data()
                console.log('User updated!')
                console.log(documentSnapshot.data())
                if (updatedUser === undefined) {
                    console.log('User is undefined!!!!')
                    return
                }
                dispatch({
                    type: AUTH_UPDATE_USER,
                    user: updatedUser
                })

                let { lastActiveTimestamp } = updatedUser
                let thrityMinsAgo = Timestamp.now().seconds - 60 * 1 // new session every 1 minute
                if (lastActiveTimestamp < thrityMinsAgo) {
                    console.log('Need to update ip and sessions')
                    let updateUserSessionsAPI = firebase.functions().httpsCallable('web-functions-updateUserSessions')
                    let sessionAPIResult = await updateUserSessionsAPI({
                        clientVersion: '0.0.1'
                    })
                    console.log('Done updating session!')
                    console.log(sessionAPIResult)

                    console.log('Making GET!')
                    var config = {
                        method: 'get',
                        headers: {
                            "accept": "application/json",
                            "content-type": "application/json"
                        }
                    };

                    console.log('before call')
                    const url = 'https://freegeoip.app/jsonasdf'
                    const response = await axios.get(url, config);
                    console.log(response);
                    console.log('after call')
                }


            }
        })
        return unsubscribe
    }, [firebaseAuthUser])

    // Log in
    useEffect(() => {
        let fetchData = async () => {
            if (loginCredentials === undefined) return
            let { email, password } = loginCredentials
            console.log('Logging in:')
            console.log(`${email} + ${password}`)
            dispatch({ type: AUTH_LOG_IN })
            let authResult = firebase.auth()
                .signInWithEmailAndPassword(email, password)
                .then(async function (firebaseUser) {
                    // Success
                    if (firebaseUser.user === null) {
                        dispatch({
                            type: AUTH_LOG_IN_FAILURE,
                            error: 'Sorry, something went wrong.'
                        })
                        return
                    }
                    console.log("Log in Success!")
                    firebaseUser.user.getIdToken()
                        .then(async function (idToken) {
                            console.log(idToken) // It shows the Firebase token now
                            console.log('Syncing with DB')
                            let authenticateUserAPI = firebase.functions().httpsCallable('web-functions-authenticateUser')
                            let results = await authenticateUserAPI({
                                idToken: idToken
                            })
                            console.log(results)
                            dispatch({
                                type: AUTH_LOG_IN_SUCCESS
                            })
                        })
                })
                .catch(function (error) {
                    // Error Handling
                    console.log("Log in Failure!")
                    console.log(error.message)
                    dispatch({
                        type: AUTH_LOG_IN_FAILURE,
                        error: error.message
                    })
                })
        }

        if (loginCredentials !== undefined) {
            fetchData()
        }
    }, [loginCredentials])


    // Create account
    useEffect(() => {

        let fetchData = async () => {
            if (signUpCredentials === undefined) return
            signUpCredentials.deviceType = 'Web'
            signUpCredentials.createdHumanReadableDate = moment().format("DD/MM/YYYY hh:mm:ss")
            signUpCredentials.clientVersion = '0.0.1' // TODO: kirby create globals
            console.log(signUpCredentials)
            let { email, password } = signUpCredentials
            console.log('Creating')
            console.log(`${email} + ${password}`)
            dispatch({ type: AUTH_SIGN_UP_SUBMIT })

            // Auth create user
            let auth = firebase.auth()
                .createUserWithEmailAndPassword(email, password)
                .then(async result => {
                    let user = result.user
                    if (user === null) { return }
                    let { uid } = user
                    console.log(`Firebase auth created ${uid}`)
                    let createUserAPI = firebase.functions().httpsCallable('web-functions-createUser')
                    let results = await createUserAPI({
                        ...signUpCredentials,
                        userId: uid
                    })

                    console.log(results)
                    dispatch({
                        type: AUTH_SIGN_UP_SUCCESS,
                    })
                })
                .catch(function (error) {
                    // Error Handling
                    console.log(error.message)
                    dispatch({
                        type: AUTH_SIGN_UP_FAILURE,
                        error: error.message
                    })
                })
        }

        if (signUpCredentials !== undefined) {
            fetchData()
        }
    }, [signUpCredentials])

    // Log Out
    useEffect(() => {
        let fetchData = async () => {
            console.log('Logging out')
            if (firebaseAuthUser === undefined) { return }
            let userId = firebaseAuthUser.uid

            dispatch({ type: AUTH_LOG_OUT })

            let authResult = firebase.auth()
                .signOut()
                .then(async function (e) {
                    console.log("Log out local user Success!")
                    let unauthenticateUserAPI = firebase.functions().httpsCallable('web-functions-unauthenticateUser')
                    let results = await unauthenticateUserAPI({
                        userId: userId
                    })
                    console.log("Database sync Success!")
                    console.log(results)
                    dispatch({
                        type: AUTH_LOG_OUT_SUCCESS
                    })
                })
                .catch(function (error) {
                    // Error Handling
                    console.log("Log out Failure!")
                    console.log(error.message)
                    dispatch({
                        type: AUTH_LOG_OUT_FAILURE,
                        error: error.message
                    })
                })
        }

        if (logOut !== undefined && logOut === true) {
            fetchData()
        }

    }, [logOut])

    return {
        state: state,
        setLoginCredentials: setLoginCredentials,
        setSignUpCredentials: setSignUpCredentials,
        setDeleteAccount: setDeleteAccount,
        setLogOut: setLogOut
    }
}