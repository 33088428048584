import '../SignUp/SignUp.css'
import './Login.css'
import Button, { ButtonType, ButtonTextAlignment } from '../Button/Button'
import Modal from '../Modal/Modal'
import React, { useState } from 'react'
import Spinner from '../Spinner/Spinner'
import TextField from '../TextField/TextField'
import { Redirect } from 'react-router-dom'
import { StandardDropShadows, StandardCornerRadii, StandardBackgroundColors, StandardFonts, StandardTextColors } from '../Styles/index'
import { useAuth } from '../../Authentication/Hook'
import { useLoginHook } from './Hook'

interface LoginProps { }

export interface LogInState {
    isLoading: boolean
    showForgotPassword?: boolean
    forgotPasswordEmail?: string
}

export let Login: React.FC<LoginProps> = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordForgot, setPasswordForgot] = useState('')

    let { state, setLoginCredentials } = useAuth()
    let { firebaseAuthUser, eurekaUser, loginLoading, loginError, eurekaUserInitializing, firebaseAuthUserInitializing } = state

    let { state: logInState, setForgotPasswordEmail, setShowForgotPassword } = useLoginHook()
    let { showForgotPassword, forgotPasswordEmail } = logInState

    if (eurekaUserInitializing === false && firebaseAuthUserInitializing === false && eurekaUser) {
        console.log('Log in success! Redirecting to /surveys')
        return <Redirect to='/surveys' />
    }
    return (
        <div className='sign-up-container'>
            {showForgotPassword &&
                <Modal onClick={() => {
                    setShowForgotPassword(false)
                }}>
                    Enter Password
                <TextField
                        placeholder='Email'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPasswordForgot(event.target.value)
                        }} />
                    <Button
                        title='Send Reset Link'
                        type={ButtonType.Purple}
                        textAlignment={ButtonTextAlignment.Center}
                        onClick={() => {
                            setForgotPasswordEmail(passwordForgot)
                        }}
                    />
                </Modal>}
            <div className='sign-up-left'>
                {loginLoading && <Spinner absolutePosition={true} />}
                <div className='sign-up-input-container'>
                    <p className={`sign-up-welcome-label ${StandardFonts.LargeTextBold} ${StandardTextColors.Black}`}>Welcome Back!</p>
                    <TextField
                        placeholder='Email'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(event.target.value)
                        }}
                    />
                    <TextField
                        placeholder='Password'
                        isPassword={true}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value)
                        }}
                    />

                    {loginError && <p className={`sign-up-legal ${StandardFonts.Legal} ${StandardTextColors.Red}`}>{loginError}</p>}

                    <Button
                        title='Log In'
                        type={ButtonType.Purple}
                        textAlignment={ButtonTextAlignment.Center}
                        onClick={() => {
                            setLoginCredentials({
                                email: email,
                                password: password
                            })
                        }}
                    />
                    <p
                        className={`sign-up-forgot-password ${StandardFonts.Legal} ${StandardTextColors.TextGrey}`}
                        onClick={() => {
                            console.log("CLICKING!")
                            setShowForgotPassword(true)
                        }}
                    >
                        Forgot Password
                    </p>
                </div>
            </div>
            <div className={`sign-up-right`}>
                <div className='sign-up-right-text-container'>
                    <img src='landing.svg'></img>
                    <p className={`${StandardFonts.H1} ${StandardTextColors.Black}`}>Surveys with cash payouts</p>
                    <p className={`${StandardFonts.LargeText} ${StandardTextColors.Black}`}>Earn cash rewards and enter sweepstakes for completing surveys</p>
                </div>
            </div>
        </div>
    )
}

export default Login
