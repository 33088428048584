import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

export const USER_EXITS_REQUEST = 'USER_EXIT_REQUEST'
export const USER_EXITS_SNAPSHOT_UPDATE = 'USER_EXIT_SNAPSHOT_UPDATE'

export interface UserExitsRequest {
    type: typeof USER_EXITS_REQUEST,
}
export interface UserExitsSnapshotUpdate {
    type: typeof USER_EXITS_SNAPSHOT_UPDATE,
    sweepstakeSurveys: SweepstakeSurveyProxy[]
}


export type UserExitsActions = UserExitsRequest | UserExitsSnapshotUpdate 