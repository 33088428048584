import './Cashouts.css'
import CashoutsTable from '../Tables/CashoutsTable'
import React, { useRef } from 'react'
import { useResize } from '../Tables/Table'
import { CashoutRequestProxy } from '../../../sharedModels/CashoutRequestsProxy'
import { Spinner } from 'react-bootstrap'
import { useCashoutsHook } from './Hook'

interface CashoutProps { }

export interface CashoutState {
    isLoading: boolean
    cashouts: CashoutRequestProxy[],
}

export let Cashouts: React.FC<CashoutProps> = (props) => {

    let { state, setShouldSendFirestoreId, setShouldManualFirestoreId, setShouldSkipFirestoreId } = useCashoutsHook()
    let { isLoading, cashouts } = state
    const componentRef = useRef(null)
    const { width, height } = useResize(componentRef)

    return (
        <div className='admin-cashouts-container' ref={componentRef}>
            {isLoading ?
                <Spinner animation='border' variant='primary' />
                :
                <CashoutsTable cashouts={cashouts} width={width} height={height} allowButtons={true} setShouldSendFirestoreId={setShouldSendFirestoreId} setShouldManualFirestoreId={setShouldManualFirestoreId} setShouldSkipFirestoreId={setShouldSkipFirestoreId} />}
        </div>
    )
}

export default Cashouts
