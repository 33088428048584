
export interface LucidSurveyTemplateProxy {
    accountName: string
    clientState: LucidSurveyTemplateState
    conversion: number
    epcCents: number
    epcmCents: number
    lucidSurveysCompleted: number
    lucidSurveysConversion: number
    lucidSurveysCreated: number
    lucidSurveysExpired: number
    lucidSurveysFailedScreener: number
    lucidSurveysFailedScreenerQuota: number
    lucidSurveysOverQuoted: number
    lucidSurveysQualityTerminated: number
    lucidSurveysScreenersCreated: number
    lucidSurveysStarted: number
    lucidSurveysTerminated: number
    lucidSurveysUserExited: number
    revenueCents: number
    score?: number
    mobileScore?: number
    surveyNumber: number
    terminationLoiSeconds: number
    topic: string
    totalCompleted: number
    totalEntrants: number
    totalRevenueCents: number
    totalSupply: number
    userEstimatedDurationSeconds: number
    lucidSurveysExpiredQuota: number
    // Field set when refresh needed
    lastUpdateTimestamp: number
    // Fields set on refresh
    allocationRemaining?: number
    completesPerMinute?: number
    lucidLinkSurveysCompleted?: number
    // epcCents?: number
    hedgeRemaining?: number
    mobileConversion?: number
    // Fields set on Allocated
    liveLink?: string
    testLink?: string
    // Field set on Live
    liveTimestamp?: number
    // Fields set on Killed, Inactive
    endTimestamp?: number
    // Set automatically
    createdTimestamp?: number
    firestoreId?: string
}

// Created: Killed, Testing
// Testing: Live, Killed, Inactive
// Live: Killed, Inactive
export enum LucidSurveyTemplateState {
    Created = 'Created',
    Inactive = 'Inactive',
    Killed = 'Killed',
    Live = 'Live',
    Testing = 'Testing'
}

export interface LucidSurveyTemplateQualQuestion {
    logicalOperator: string
    precodes: string[]
    qualifyingPrecodesMap: { [key: string]: boolean }
    qualifyingResponsesMap: { [key: string]: string }
    questionId: number
    questionText: string
}