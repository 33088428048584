import { UserExitsActions, USER_EXITS_REQUEST, USER_EXITS_SNAPSHOT_UPDATE } from './Actions'
import { UserExitsState } from './UserExits'

export const reducer = (state: UserExitsState, action: UserExitsActions): UserExitsState => {
    switch (action.type) {
        case USER_EXITS_REQUEST:
            return {
                isLoading: true,
                sweepstakeSurveys: []
            }
        case USER_EXITS_SNAPSHOT_UPDATE:
            let uniqueSweepstakeSurveys = state.sweepstakeSurveys.concat(action.sweepstakeSurveys)
            uniqueSweepstakeSurveys = uniqueSweepstakeSurveys.filter((sweepstakeSurvey, index, self) =>
                index === self.findIndex((v) => (
                    v.firestoreId === sweepstakeSurvey.firestoreId
                ))
            )
            return {
                isLoading: false,
                sweepstakeSurveys: uniqueSweepstakeSurveys
            }
    }
}