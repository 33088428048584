import { UserProxy } from '../../../sharedModels/UserProxy'

// Define all actions that can occur on the UserLookup Component
export const FRAUD_REQUEST = 'FRAUD_REQUEST'
export const FRAUD_SNAPSHOT_UPDATE = 'FRAUD_SNAPSHOT_UPDATE'


export interface FraudRequest {
    type: typeof FRAUD_REQUEST,
}
export interface FraudSnapshotUpdate {
    type: typeof FRAUD_SNAPSHOT_UPDATE,
    users: UserProxy[]
}


export type FraudActions = FraudRequest | FraudSnapshotUpdate 