import { DailyPollProxy } from '../../sharedModels/DailyPollProxy'

export const DAILY_POLL_REQUEST = 'DAILY_POLL_REQUEST'
export const DAILY_POLL_SUCCESS = 'DAILY_POLL_SUCCESS'
export const DAILY_POLL_SUBMIT = 'DAILY_POLL_SUBMIT'
export const DAILY_POLL_SUBMIT_SUCCESS = 'DAILY_POLL_SUBMIT_SUCCESS'

export interface DailyPollRequest {
    type: typeof DAILY_POLL_REQUEST,
}

export interface DailyPollSuccess {
    type: typeof DAILY_POLL_SUCCESS,
    dailyPoll: DailyPollProxy
}

export interface DailyPollSubmit {
    type: typeof DAILY_POLL_SUBMIT,
    dailyPollId: string,
    responseText: string
}

export interface DailyPollSubmitSuccess {
    type: typeof DAILY_POLL_SUBMIT_SUCCESS,
}

export type DailyPollActions = DailyPollRequest | DailyPollSuccess | DailyPollSubmit | DailyPollSubmitSuccess