import { CashoutRequestProxy } from '../../sharedModels/CashoutRequestsProxy'
import { Survey } from '../../sharedModels/SurveyProxy'

export const COMPLETED_SURVEYS_REQUEST = 'COMPLETED_SURVEYS_REQUEST'
export const COMPLETED_SURVEYS_SUCCESS = 'COMPLETED_SURVEYS_SUCCESS'

export interface CompletedSurveysHookRequest {
    type: typeof COMPLETED_SURVEYS_REQUEST,
}
export interface CompletedSurveysHookSuccess {
    type: typeof COMPLETED_SURVEYS_SUCCESS,
    completedSurveys: Survey[],
    cashouts: CashoutRequestProxy[]
}

export type CompletedSurveysActions = CompletedSurveysHookSuccess | CompletedSurveysHookRequest 