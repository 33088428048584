import firebase from 'firebase'
import { LUCID_INVENTORY_SNAPSHOT_UPDATE, LUCID_INVENTORY_ENGLISH_QUALS, LUCID_INVENTORY_RESET_ENGLISH_QUALS } from './Actions'
import { LucidLiveInventoryState } from './LucidLiveInventory'
import { LucidSurveyTemplateProxy, LucidSurveyTemplateState, LucidSurveyTemplateQualQuestion } from '../../../sharedModels/LucidSurveyTemplateProxy'
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { reducer } from './Reducer'
import { useReducer, useEffect, useState } from 'react'

let initialState: LucidLiveInventoryState = {
    isLoading: true,
    lucidSurveyTemplates: [],
    englishQualifications: undefined
}

export interface CompletesHook {
    state: LucidLiveInventoryState,
    reloadCount: number
    setReloadCount: React.Dispatch<React.SetStateAction<number>>
    setLaunchSurvey: React.Dispatch<React.SetStateAction<number>>
    setExpireSurvey: React.Dispatch<React.SetStateAction<number>>
    englishQualificationsForSurvey: number
    setEnglishQualificationsForSurvey: React.Dispatch<React.SetStateAction<number>>
}

export const useLucidInventoryHook = (clientState: LucidSurveyTemplateState): CompletesHook => {
    let [reloadCount, setReloadCount] = useState(0)
    let [launchSurvey, setLaunchSurvey] = useState(0)
    let [expireSurvey, setExpireSurvey] = useState(0)
    let [englishQualificationsForSurvey, setEnglishQualificationsForSurvey] = useState(0)
    let [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        let fetchData = async () => {
            let docs = await firebase.firestore()
                .collection('lucidSurveyTemplate')
                .where('clientState', '==', clientState)
                .orderBy('createdTimestamp', 'desc')
                .limit(8000)
                .get()
            const updatedLucidTemplates = docs.docs.map((docSnapshot: QueryDocumentSnapshot) => <LucidSurveyTemplateProxy>docSnapshot.data())
            dispatch({ type: LUCID_INVENTORY_SNAPSHOT_UPDATE, lucidSurveyTemplates: updatedLucidTemplates })
        }

        fetchData()
    }, [reloadCount])

    useEffect(() => {
        let fetchData = async () => {
            let launchSurveyAPI = firebase.functions().httpsCallable('admin-functions-launchLucidSurveys')
            let results = await launchSurveyAPI({ surveyNumber: launchSurvey })
            console.log(results)
        }

        if (launchSurvey === 0) return
        fetchData()
        console.log(`Launching ${launchSurvey}!`)
    }, [launchSurvey])


    useEffect(() => {
        let fetchData = async () => {
            let expireSurveyAPI = firebase.functions().httpsCallable('admin-functions-expireLucidSurveys')
            let results = await expireSurveyAPI({ surveyNumber: expireSurvey })
            console.log(results)
        }

        if (expireSurvey === 0) return
        fetchData()
        console.log(`Expiring ${expireSurvey}!`)
    }, [expireSurvey])


    useEffect(() => {
        let fetchData = async () => {
            let getLucidSurveyQuals = firebase.functions().httpsCallable('admin-functions-getLucidSurveyQuals')
            let results = await getLucidSurveyQuals({ surveyNumber: englishQualificationsForSurvey })
            let englishQuals = results.data as LucidSurveyTemplateQualQuestion[]
            dispatch({
                type: LUCID_INVENTORY_ENGLISH_QUALS,
                englishQualifications: englishQuals
            })
        }

        if (englishQualificationsForSurvey === 0) {
            dispatch({
                type: LUCID_INVENTORY_RESET_ENGLISH_QUALS
            })
            return
        }
        fetchData()
        console.log(`Getting English for  ${englishQualificationsForSurvey}`)
    }, [englishQualificationsForSurvey])

    return {
        state: state,
        reloadCount: reloadCount,
        setReloadCount: setReloadCount,
        setLaunchSurvey: setLaunchSurvey,
        setExpireSurvey: setExpireSurvey,
        englishQualificationsForSurvey: englishQualificationsForSurvey,
        setEnglishQualificationsForSurvey: setEnglishQualificationsForSurvey
    }
}
