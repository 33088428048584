
import { AuthActions, AUTH_DELETE_ACCOUNT, AUTH_DELETE_ACCOUNT_FAILURE, AUTH_DELETE_ACCOUNT_SUCCESS, AUTH_LOG_OUT, AUTH_LOG_OUT_FAILURE, AUTH_LOG_OUT_SUCCESS, AUTH_FIREBASE_UPDATE_USER_FAILURE, AUTH_UPDATE_FAILURE, AUTH_SIGN_UP_SUCCESS, AUTH_SIGN_UP_SUBMIT, AUTH_SIGN_UP_FAILURE, AUTH_INITIALIZING, AUTH_UPDATE_USER, AUTH_LOG_IN, AUTH_LOG_IN_FAILURE, AUTH_LOG_IN_SUCCESS, AUTH_SIGN_IN_ANONYMOUSLY, AUTH_FIREBASE_UPDATE_USER } from './Actions'
import { AuthState } from './Hook'

export const reducer = (state: AuthState, action: AuthActions): AuthState => {
    switch (action.type) {
        case AUTH_INITIALIZING:
            return {
                firebaseAuthUser: undefined,
                firebaseAuthUserInitializing: true,
                eurekaUser: undefined,
                eurekaUserInitializing: true,
                userIsLoggedIn: false,
                loginLoading: false,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_FIREBASE_UPDATE_USER:
            return {
                firebaseAuthUser: action.firebaseUser,
                firebaseAuthUserInitializing: false,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: true,
                userIsLoggedIn: false,
                loginLoading: false,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_FIREBASE_UPDATE_USER_FAILURE:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: false,
                eurekaUser: undefined,
                eurekaUserInitializing: false,
                userIsLoggedIn: false,
                loginLoading: false,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_UPDATE_USER:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: action.user,
                eurekaUserInitializing: false,
                userIsLoggedIn: true,
                loginLoading: false,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_UPDATE_FAILURE:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: false,
                userIsLoggedIn: true,
                loginLoading: false,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_LOG_IN:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: true,
                loginLoading: true,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_LOG_IN_FAILURE:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: false,
                loginLoading: false,
                loginError: action.error,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_LOG_IN_SUCCESS:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: true,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: true,
                userIsLoggedIn: true,
                loginLoading: false,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_SIGN_IN_ANONYMOUSLY:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: true,
                loginLoading: false,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_SIGN_UP_SUBMIT:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: true,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: true,
                userIsLoggedIn: true,
                loginLoading: false,
                signUpLoading: true,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_SIGN_UP_SUCCESS:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: true,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: true,
                loginLoading: false,
                signUpLoading: false,
                signUpError: undefined,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_SIGN_UP_FAILURE:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: true,
                loginLoading: false,
                signUpLoading: false,
                signUpError: action.error,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_LOG_OUT:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: state.userIsLoggedIn,
                loginLoading: state.loginLoading,
                signUpLoading: state.signUpLoading,
                logOutLoading: true,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_LOG_OUT_SUCCESS:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: state.userIsLoggedIn,
                loginLoading: state.loginLoading,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: true,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_LOG_OUT_FAILURE:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: state.userIsLoggedIn,
                loginLoading: state.loginLoading,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                logOutError: action.error,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_DELETE_ACCOUNT:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: state.userIsLoggedIn,
                loginLoading: state.loginLoading,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: true,
                deleteAccountLoadingSuccessful: false,
            }
        case AUTH_DELETE_ACCOUNT_SUCCESS:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: state.userIsLoggedIn,
                loginLoading: state.loginLoading,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: true,
            }
        case AUTH_DELETE_ACCOUNT_FAILURE:
            return {
                firebaseAuthUser: state.firebaseAuthUser,
                firebaseAuthUserInitializing: state.firebaseAuthUserInitializing,
                eurekaUser: state.eurekaUser,
                eurekaUserInitializing: state.eurekaUserInitializing,
                userIsLoggedIn: state.userIsLoggedIn,
                loginLoading: state.loginLoading,
                signUpLoading: state.signUpLoading,
                logOutLoading: false,
                logOutLoadingSuccessful: false,
                deleteAccountLoading: false,
                deleteAccountLoadingSuccessful: false,
                deleteAccountError: action.error
            }
    }
}