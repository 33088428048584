import './StagingCard.css'
import Button, { ButtonType } from '../Button/Button'
import React from 'react'
import { StandardFonts, StandardTextColors, StandardCornerRadii, StandardBackgroundColors, StandardDropShadows } from '../Styles/index'
import { SweepstakeSurveyProxy } from '../../sharedModels/SweepstakeSurveyProxy'

interface ClosedCardProps {
    sweepstakeSurvey: SweepstakeSurveyProxy
    onClick: () => void
}

const ClosedCard = (props: ClosedCardProps) => {
    let { sweepstakeSurvey, onClick } = props

    return (
        <div className='staging-card-container'>
            <p className={`staging-card-title-text ${StandardFonts.LargeTextBold} ${StandardTextColors.Purple}`}>Survey Closed</p>
            <div className='staging-card-content-closed'>
                <img className='staging-card-detail-image' src='surveyClosed.svg' />
                <p className={`staging-card-info-text-closed ${StandardFonts.Legal} ${StandardTextColors.Black}`}>Sorry this survey is no longer available. Don’t worry, we’ll find you another survey.</p>
                <div className='staging-card-button-row'>
                    <div className='staging-card-button-padding' />
                    <Button className='staging-card-button-padding'
                        title={`Close`}
                        type={ButtonType.Yellow}
                        onClick={onClick} />
                    <div className='staging-card-button-padding' />
                </div>
            </div>
        </div>
    )
}

export default ClosedCard