
// Define all actions that can occur on the UserLookup Component

export const LOGIN_SUBMIT = 'LOGIN_SUBMIT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOG_IN_FORGOT_PASSWORD_SHOW = 'AUTH_LOG_IN_FORGOT_PASSWORD_SHOW'
export const LOG_IN_FORGOT_PASSWORD_SEND = 'AUTH_LOG_IN_FORGOT_PASSWORD_SEND'

export interface LoginSubmit {
    type: typeof LOGIN_SUBMIT,
}

export interface LoginSucces {
    type: typeof LOGIN_SUCCESS,
}

export interface LoginFailure {
    type: typeof LOGIN_FAILURE,
    error: string
}

export interface LogInShowForgotPassword {
    type: typeof LOG_IN_FORGOT_PASSWORD_SHOW,
    show: boolean
}

export interface LogInSendForgotPassword {
    type: typeof LOG_IN_FORGOT_PASSWORD_SEND,
}

export type LoginActions = LoginSubmit | LoginSucces | LoginFailure | LogInShowForgotPassword | LogInSendForgotPassword