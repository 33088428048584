import { UserProxy } from '../../../sharedModels/UserProxy'
import { CashoutRequestProxy } from '../../../sharedModels/CashoutRequestsProxy'
import { TransactionProxy } from '../../../sharedModels/TransactionProxy'


// Define all actions that can occur on the UserLookup Component

export const USER_LOOKUP_REQUEST = 'USER_LOOKUP_REQUEST'
export const USER_LOOKUP_SUCCESS = 'USER_LOOKUP_SUCCESS'
export const USER_LOOKUP_FAILURE = 'USER_LOOKUP_FAILURE'
export const USER_LOOKUP_INCREMENT_BALANCE = 'USER_LOOKUP_INCREMENT_BALANCE'

export interface UserLookupRequest {
    type: typeof USER_LOOKUP_REQUEST,
}
export interface UserLookupSuccess {
    type: typeof USER_LOOKUP_SUCCESS,
    user: UserProxy,
    cashouts: CashoutRequestProxy[],
    transactions: TransactionProxy[]
}

export interface UserLookupFailure {
    type: typeof USER_LOOKUP_FAILURE,
    error: string
}

export interface UserLookupIncrementBalance {
    type: typeof USER_LOOKUP_INCREMENT_BALANCE,
}

export type UserLookUpActions = UserLookupSuccess | UserLookupRequest | UserLookupFailure | UserLookupIncrementBalance