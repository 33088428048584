
import { DailyPollActions, DAILY_POLL_REQUEST, DAILY_POLL_SUCCESS, DAILY_POLL_SUBMIT, DAILY_POLL_SUBMIT_SUCCESS } from './Actions'
import { DailyPollState } from './DailyPoll'

export const reducer = (state: DailyPollState, action: DailyPollActions): DailyPollState => {
    switch (action.type) {
        case DAILY_POLL_REQUEST:
            return {
                isLoading: true,
                dailyPoll: undefined,
                incrementBalance: false
            }
        case DAILY_POLL_SUCCESS:
            let newDailyPoll = action.dailyPoll
            if (newDailyPoll === undefined) {
                return {
                    ...state
                }
            }
            let { totalResponseCount } = newDailyPoll
            for (let response of newDailyPoll.responseObjectsOrdered) {
                response.percentage = 100 * (response.count / totalResponseCount)
            }
            return {
                isLoading: false,
                dailyPoll: newDailyPoll,
                incrementBalance: false
            }
        case DAILY_POLL_SUBMIT:
            let dailyPoll = state.dailyPoll
            if (dailyPoll === undefined) {
                return {
                    ...state
                }
            }
            dailyPoll.responseText = action.responseText
            return {
                isLoading: false,
                dailyPoll: dailyPoll,
                incrementBalance: false
            }
        case DAILY_POLL_SUBMIT_SUCCESS:
            return {
                ...state,
                incrementBalance: true
            }
    }
}