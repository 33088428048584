
import { ProfileActions, PROFILE_SUCCESS, PROFILE_REQUEST, PROFILE_SET_ATTRIBUTE } from './Actions'
import { ProfileState } from '../../Components/Profile/Profile'

export const reducer = (state: ProfileState, action: ProfileActions): ProfileState => {
    switch (action.type) {
        case PROFILE_REQUEST:
            return {
                isLoading: true,
                userAttributes: state.userAttributes
            }
        case PROFILE_SUCCESS:
            return {
                isLoading: false,
                userAttributes: action.userAttributes.sort((a, b) => {
                    return a.priority - b.priority
                })
            }
        case PROFILE_SET_ATTRIBUTE:
            // update the new user attribute
            let updatedUserAttribute = action.updatedUserAttribute
            let userAttribtues = state.userAttributes.map(userAttribute => {
                if (userAttribute.firestoreId === updatedUserAttribute.firestoreId) {
                    return updatedUserAttribute
                }
                return userAttribute
            })
            return {
                isLoading: false,
                userAttributes: state.userAttributes
            }
    }
}