import './SweepstakeSurveyOfferwall.css'
import Button, { ButtonType } from '../Button/Button'
import ClosedCard from '../SweepstakeSurvey/ClosedCard'
import CompletedCard from '../SweepstakeSurvey/CompletedCard'
import StartedCard from '../SweepstakeSurvey/StartedCard'
import React, { useState } from 'react'
import Spinner from '../Spinner/Spinner'
import StagingCard from '../SweepstakeSurvey/StagingCard'
import TerminatedCard from '../SweepstakeSurvey/TerminatedCard'
import firebase from 'firebase'
import { StandardFonts, StandardTextColors, StandardBackgroundColors, StandardCornerRadii, StandardDropShadows, StandardMargin } from '../Styles/index'
import { SweepstakeSurveyProxy, SweepstakeSurveyState } from '../../sharedModels/SweepstakeSurveyProxy'
const Timestamp = firebase.firestore.Timestamp

type Optional<T> = T | undefined // TODO: Use this everywhere `?` is used

interface SweepstakeSurveyOfferwallProps {
    sweepstakeSurveys: SweepstakeSurveyProxy[],
    activeSweepstakeSurvey: SweepstakeSurveyProxy | undefined
    setSweepstakeSurvey: React.Dispatch<React.SetStateAction<SweepstakeSurveyProxy | undefined>>
    setReload: React.Dispatch<React.SetStateAction<number>>
    setAnimateUserBalance: React.Dispatch<React.SetStateAction<boolean>>
}

const SweepstakeSurveyOfferwall: React.FC<SweepstakeSurveyOfferwallProps> = (props) => {

    let { sweepstakeSurveys, setSweepstakeSurvey, setReload, activeSweepstakeSurvey, setAnimateUserBalance } = props
    let [selectedSweepstakeSurvey, setSelectedSweepstakeSurvey] = useState<SweepstakeSurveyProxy>()
    let [showLoading, setShowLoading] = useState<boolean>(true)
    let surveyInProgress = activeSweepstakeSurvey !== undefined
    if (sweepstakeSurveys.length === 0) { return <div /> }
    if (selectedSweepstakeSurvey === undefined) {
        setSelectedSweepstakeSurvey(sweepstakeSurveys[0])
        return <div />
    }

    if (showLoading) {
        //wait 0.25 second to turn off
        let duration = Math.random() * (750 - 350) + 350;
        setTimeout(() => {
            setShowLoading(false)
        }, duration)
    }

    console.log(`${surveyInProgress} is in progress?`)
    activeSweepstakeSurvey = activeSweepstakeSurvey || selectedSweepstakeSurvey!
    console.log(`Active survey ${activeSweepstakeSurvey.firestoreId} client state is ${activeSweepstakeSurvey.clientState}`)

    let card: Optional<JSX.Element> = undefined
    let fakeState = [SweepstakeSurveyState.Completed, SweepstakeSurveyState.Terminated, SweepstakeSurveyState.Started, SweepstakeSurveyState.OverQuoted, SweepstakeSurveyState.Live][Math.floor(Math.random() * 5)]
    switch (activeSweepstakeSurvey.clientState) {
        case SweepstakeSurveyState.Completed:
            card = <CompletedCard
                sweepstakeSurvey={activeSweepstakeSurvey}
                onClick={() => {
                    setAnimateUserBalance(true)
                    setTimeout(() => {
                        setReload(Timestamp.now().seconds)
                    }, 3000)
                }}
            />
            break
        case SweepstakeSurveyState.Terminated:
            card = <TerminatedCard
                sweepstakeSurvey={activeSweepstakeSurvey}
                onClick={() => {
                    setAnimateUserBalance(true)
                    setTimeout(() => {
                        setReload(Timestamp.now().seconds)
                    }, 3000)
                }} />
            break
        case SweepstakeSurveyState.Started:
            card = <StartedCard
                sweepstakeSurvey={activeSweepstakeSurvey}
                onClick={() => {
                    setReload(Timestamp.now().seconds)
                }}
            />
            break
        case SweepstakeSurveyState.OverQuoted:
        case SweepstakeSurveyState.QualityTerminated:
        case SweepstakeSurveyState.FailedScreener:
        case SweepstakeSurveyState.FailedScreenerQuota:
            card = <ClosedCard
                sweepstakeSurvey={activeSweepstakeSurvey}
                onClick={() => {
                    setReload(Timestamp.now().seconds)
                }} />
            break
        default:
            card = <StagingCard
                sweepstakeSurvey={activeSweepstakeSurvey}
                onClick={() => {
                    let { firestoreId, entryURL } = activeSweepstakeSurvey!
                    console.log(`Starting ${firestoreId}`)
                    setSweepstakeSurvey(activeSweepstakeSurvey)
                    window.open(entryURL, '_blank') //to open new page
                }} />
            break
    }

    let sweepstakeSurveyContainerClassName = surveyInProgress ? 'sweepstake-survey-table-view-in-progress' : 'sweepstake-survey-table-view'
    return (
        <div className='sweepstake-surveys-super-container'>
            {surveyInProgress && <div className='sweepstake-surveys-modal' />}
            <div className='sweepstake-surveys-container'>
                <p className={`sweepstake-surveys-title ${StandardFonts.SmallTextBold} ${StandardTextColors.Black}`}>Sweepstake Surveys</p>
                <div className={`sweepstake-survey-pane-container`}>
                    <div className={sweepstakeSurveyContainerClassName}>
                        {sweepstakeSurveys.map(sweepstakeSurvey => {
                            return <SweepstakeSurveyCell sweepstakeSurvey={sweepstakeSurvey} setActiveSweepstakeSurvey={setSelectedSweepstakeSurvey} setShowLoading={setShowLoading} isActive={sweepstakeSurvey === selectedSweepstakeSurvey} />
                        })}
                    </div>
                    <div className={`sweepstake-survey-detail-container ${StandardBackgroundColors.White} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal} ${StandardMargin.NormalLeft}`}>
                        {showLoading ? <Spinner /> : card}
                    </div>
                </div>
            </div >
        </div>
    )
}

export default SweepstakeSurveyOfferwall

interface SweepstakeSurveyCellProps {
    sweepstakeSurvey: SweepstakeSurveyProxy
    setActiveSweepstakeSurvey: React.Dispatch<React.SetStateAction<SweepstakeSurveyProxy | undefined>>
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>
    isActive: boolean
}

const SweepstakeSurveyCell: React.FC<SweepstakeSurveyCellProps> = (props) => {
    let [imageUrl, setImageUrl] = useState('')

    let { sweepstakeSurvey, setActiveSweepstakeSurvey, setShowLoading, isActive } = props
    let { userSweepstakesString, backgroundImageUrl, firestoreId, userEarningsString, userEstimatedDurationString, topic, source, lucidSurveyNumber, entryURL } = sweepstakeSurvey

    let storage = firebase.storage()
    storage.ref(backgroundImageUrl).getDownloadURL().then(url => {
        setImageUrl(url)
    })

    return (
        <div className={`sweepstake-survey-cell ${isActive ? 'sweepstake-survey-cell-active' : 'sweepstake-survey-cell-inactive'} ${isActive ? StandardBackgroundColors.White : StandardBackgroundColors.BackgroundGrey} ${StandardCornerRadii.Normal} ${StandardDropShadows.Normal}`}
            onClick={() => {
                setActiveSweepstakeSurvey(sweepstakeSurvey)
                setShowLoading(true)
            }}>
            <div className={`sweepstake-survey-cell-prize`} style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
            }}>
                <p className={`sweepstake-survey-cell-prize-amount ${StandardTextColors.Yellow} ${StandardFonts.SweepstakeNumber}`}>{userSweepstakesString}</p>
                <p className={`${StandardTextColors.BackgroundGrey} ${StandardFonts.Legal}`}>sweepstake</p>
            </div>
            <div className='sweepstake-survey-cell-stats'>
                <div className='quick-survey-cell-stat-item'>
                    <p className={`${StandardTextColors.Green} ${StandardFonts.LargeTextBold}`}>{userEarningsString}</p>
                    <p className={`${StandardTextColors.Green} ${StandardFonts.SmallText}`}>reward</p>
                </div>
                <div className='quick-survey-cell-stat-item'>
                    <p className={`${StandardTextColors.Black} ${StandardFonts.LargeTextBold}`}>{userEstimatedDurationString}</p>
                    <p className={`${StandardTextColors.Black} ${StandardFonts.SmallText}`}>mins</p>
                </div>
            </div>
        </div >
    )
}