import React, { Component } from 'react'

import './Legal.css'

function TermsOfService() {
    return (
        <div className='container'>
            <div className='textContainer'>

                <p className='sectionTitle'>Terms of Service</p>
                <p className='subsectionText'>The following are terms and conditions by which you may use or otherwise access the web sites of SocialLoop LLC (“Company”) at www.socialloop.io and the app Eureka – Cash for Feedback (collectively, the “Site”).  By visiting the Site, you consent and agree to be bound by these Terms of Use as may be amended from time to time (“Terms”).  If you do not agree with the Terms, please do not visit, use or otherwise access the Site or the services provided by the Company (“Service”).  The Company may modify, amend, or otherwise alter the Terms at any time by posting such changes to the Site.  You are bound by the Terms and any modifications thereto.  You further agree to the Company’s collection, use and disclosure practices, and other activities as described in Company’s Privacy Policy www.socialloop.io/privacy.  Your continued use of the Site and/or the Service will constitute your acceptance of and agreement to the Terms and Privacy Policy, as amended from time to time.</p>
                <p className='subsectionText'>The Company is a consumer insights platform where hundreds of thousands of users can make money by taking online surveys sourced from third parties (i.e., third party survey providers).</p>
                <p className='subsectionText'>As part of the Service, you may be able to receive payment for participating in surveys conducted by third parties. These third party services are not owned or controlled by us, and we are not responsible with respect to them or the actions of our third party survey providers.  For further information on third party services and third party survey providers, please see Section 'Third Party Sites below'.</p>

                <p className='sectionTitle'>Surveys</p>
                <p className='subsectionText'>Upon creation of an account with Company, you create a profile based upon the information you provide to Company through the Site.  The Company provides you (a user) with a selection of surveys that the you may voluntarily take.  The surveys are provided by third party survey providers and the Company is not responsible for the contents of the survey or if you are able to successfully complete a survey.  Each survey is provided by a third party survey provider and subject to such third parties’ terms of service and privacy policies and the Company encourages you to review each such terms and policies before utilizing the third party survey provider’s services.  Be aware that a third party survey provider may terminate your access to a survey for any reason whatsoever prior to its successful completion.  If you are unable to successfully complete a survey for any reason whatsoever, you are not eligible for compensation and Company is not responsible or liable for any surveys that are prematurely terminated.</p>
                <p className='subsectionText'>You are eligible to receive a reward or other compensation for the successful completion of each survey.  Such payments will accrue in your account. Once your account balance reaches a minimum of $10 USD, you may redeem your balance via PayPal or a variety of gift cards available at the time you redeem.</p>
                <p className='subsectionText'>Company reserves the right to suspend or otherwise refuse your reward at any time. </p>

                <p className='subsectionTitle'>You Must Be Over The Age of Thirteen (13) To Access The Website</p>
                <p className='subsectionText'>You must be over the age of thirteen (13) to access the Website in compliance with the Children’s Online Privacy Protection Act.  You must be over the age of eighteen (18) to create an account, participate in any third party surveys, or otherwise utilize the Company’s services. The Site (and links that may be available through the Site), are not intended for persons under the age of eighteen (18). We do not knowingly collect information from such persons.</p>

                <p className='subsectionTitle'>Consequences For Violation of Company Terms</p>
                <p className='subsectionText'>The Company reserves the right to deactivate accounts, cancel surveys, suspend or otherwise refuse to issue rewards, revoke payments, and/or refuse to accept requests for surveys or payment if you violate the Terms or for any other reason, as solely determined by the Company.</p>

                <p className='subsectionTitle'>Intellectual Property</p>
                <p className='subsectionText'>The entire contents of the Site, including but not limited to, images, designs, information, text, names, likenesses, graphics, formats, code and software, technical drawings, sketches, configurations, audio and video files, links, interactive features and other material and files, excluding any contents or features on the Site from third parties, used on or incorporated into the Site or with the Service, and the selection, arrangement and/or integration of all such contents and its software are owned by the Company or the Company is authorized to use such contents on the Site and in the Service.  These contents are protected by various intellectual property protections and regulations, including trademark, copyright, and trade dress or used under license or otherwise.  You are permitted, for personal use only, to download or print out individual areas of the Site without removing the copyright notices or other legally protected designations for personal use only.    </p>
                <p className='subsectionText'>You may not reproduce, use, copy, distribute, download, modify, license, sell, crop, re-size, remove, delete, deactivate,  transmit, create derivative works of, display, disseminate, cache, rearrange, alter, adapt, or in any way exploit or make commercial use of any material obtained through the Site, including, without limitation, the intellectual property, in whole or in part, directly or indirectly, without the prior written consent of the Company, except as expressly permitted herein.  You agree not to infringe upon the Company’s intellectual property rights in any manner.</p>


                <p className='subsectionTitle'>Modifications to Service/Website</p>
                <p className='subsectionText'>The Company reserves the right at any time, in its sole discretion, to modify or discontinue the Site and the Service provided (or any part thereof). The Company shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Site or the Service.</p>

                <p className='subsectionTitle'>Third Party Sites</p>
                <p className='subsectionText'>The Site may provide links to third party websites, including advertisers and third party survey providers.  Your interaction with any links or third party websites found on or through the Site, including through promotions, are at your own risk and you, solely, are subject to any third party terms or policies.  You should be familiar with the applicable terms and privacy policies of any website you may access.  Company is not liable for any content, materials, advertising, products, malware, promotions or any other services you may access by any link or third party website found on or through the Site or the Service.  </p>

                <p className='subsectionTitle'>Disclaimer of Warranties</p>
                <p className='subsectionText'>YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE AND THE SERVICE ARE AT YOUR SOLE RISK. COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY MAKES NO WARRANTY THAT (i) THE SITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SITE OR THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE SITE OR THE SERVICE WILL BE ACCURATE OR RELIABLE, AND (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL ACCESSED OR OBTAINED BY YOU THROUGH THE SITE, OR THE SERVICE, WILL MEET YOUR EXPECTATIONS. NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM COMPANY OR THROUGH THE SITE OR THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.</p>

                <p className='subsectionTitle'>Limitation of Liability</p>
                <p className='subsectionText'>YOU EXPRESSLY UNDERSTAND AND AGREE THAT COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF ANY FUNDS, TANGIBLE OR INTANGIBLE, LOSS OF PROFITS, GOODWILL, USE, DATA OR ANY OTHER LOSSES RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SITE OR THE SERVICE (ii) THE USE OR PARTICIPATION IN ANY THIRD PARTY PRODUCT, SURVEY, OR SERVICE OR OTHER WEBSITES (iii) STATEMENTS OR CONDUCT OF ANY THIRD PARTY YOU MAY ACCESS THROUGH THE SITE OR THE SERVICE OR (iv) ANY OTHER MATTER RELATING TO THE SITE.</p>

                <p className='subsectionTitle'>Indemnity</p>
                <p className='subsectionText'>You agree to release, indemnify, defend, and hold Company and its subsidiaries, affiliates, agents, vendors, officers, assigns, partners, employees, and independent contractors, harmless from and against any and all claims actions or demands, liabilities, losses, expenses, damages and costs, including without limitation reasonable legal and accounting fees, in any way arising from, related to or in connection with your use of the Site or the Service, your violation of the Terms or the posting or transmission of any materials on or through the Site by you, including, but not limited to, any third party claim that any information or materials you provide infringes any third party proprietary right.</p>

                <p className='subsectionTitle'>Restrictions On Use</p>
                <p className='subsectionText'>Except as expressly permitted, you agree not to use the Site or the Service for sale, trade or other commercial purposes, and, you may not modify, copy, publish, display, transmit, adapt or in any way exploit the items contained on or accessed through the Site.</p>
                <p className='subsectionText'>You represent, warrant and agree not to use the Site or the Service to:</p>
                <ul className='subsectionText'>
                    <li>provide information which is incomplete, false, inaccurate and not your own </li>
                    <li>engage in criminal activity or otherwise give rise to conduct that may violate any and all applicable law or fail to comply with accepted internet protocol </li>
                    <li>attempt to interfere in any way with the Site or the Site’s security or to use the Service to gain unauthorized access to any computer or other system </li>
                    <li>distribute, house, process, store or otherwise handle any content or material that may create a risk of any loss or damage to any person or property, or that Company deems, in our sole discretion, to be objectionable whether or not such material is unlawful</li>
                    <li>permit or otherwise enable unauthorized users to access and/or use the Service</li>
                    <li>use the Service to export software or data in violation of applicable laws or regulations</li>
                    <li>sell, copy, duplicate, rent, lease, loan, distribute, transfer, or sublicense the Service, or otherwise permit any third party to use or have access to the Service for any purpose (except as expressly permitted by us in writing) or decompile, reverse engineer, disassemble, modify, create a derivative work of, display in human readable form, attempt to discover any source code, or otherwise use any software that enables or comprises any part of the Service</li>
                    <li>remove any copyright, trademark, patent or other proprietary notices from the Service or any Content on the Service</li>
                    <li>distribute, publish, exhibit, or otherwise use the Service, in any manner and for any purpose not expressly permitted under this Agreement</li>
                    <li>exploit the Service or collect any data incorporated in the Service in any automated manner through the use of bots, metaspiders, crawlers or any other automated means</li>
                    <li>register as a user of the Service by providing false, inaccurate, or misleading information</li>
                    <li>impersonate any person or entity, including, without limitation, an employee of ours, or falsely state or otherwise misrepresent your affiliation with a person or entity</li>
                    <li>collect personally identifiable information about other users of the Service for commercial or any other purposes</li>
                    <li>attempt to gain unauthorized access to Company’s computer systems or engage in any activity that disrupts, diminishes the quality of, probes for vulnerability, interferes with the performance of, or impairs the functionality of, the Service (or the servers and networks which are connected to the Service)</li>
                    <li>create or attempt to create multiple user accounts</li>
                    <li>access or use the Service if you have been previously removed from the Service by us or</li>
                    <li>use the Service for any commercial purpose or for the benefit of any third party, except as otherwise explicitly permitted to you by Company.</li>
                </ul>


                <p className='subsectionTitle'>General Information</p>
                <p className='subsectionText'>The Terms and the Privacy Policy constitute the entire agreement between you and Company, superseding any other agreements or understandings between you and Company.  If any provision of the Terms is held to be unlawful, void or for any reason unenforceable, then such provision shall be deemed severable from the Terms and shall not affect the validity and enforceability of any remaining provisions. You agree that the Terms and all incorporated agreements may be assigned by Company, in its sole discretion, to a third party.</p>
                <p className='subsectionText'>The Terms and the relationship between you and Company shall be governed by the laws of the State of New York without regard to its conflicts of laws rules, and you irrevocably consent, in connection with any action to enforce the Terms or arising out of your use of the Site or the Service, to submit to the exclusive jurisdiction of the federal and state courts located in New York, New York. No action arising out of your use of the Site or the Service, regardless of form, may be brought by a user more than one (1) year after the cause of action has arisen.</p>

                <p className='subsectionTitle'>Accessibility</p>
                <p className='subsectionText'>Please email support@eurekarewards.com for assistance navigating the Site.</p>

            </div>

            {/* 
                <p className='sectionTitle'>SectionTitle</p>
                <p className='subsectionTitle'>title</p>
                <p className='subsectionText'>text</p>
                <ul className='subsectionText'>
                    <li>Item1</li>
                    <li>Item2</li>
                </ul> 
                */}
        </div>
    )
}

export default TermsOfService
