// Define all actions that can occur on the UserLookup Component

import { UserProxy } from "../sharedModels/UserProxy"
import firebase from 'firebase'

export const AUTH_INITIALIZING = 'AUTH_INITIALIZING'
export const AUTH_FIREBASE_UPDATE_USER = 'AUTH_FIREBASE_UPDATE_USER'
export const AUTH_FIREBASE_UPDATE_USER_FAILURE = 'AUTH_FIREBASE_UPDATE_USER_FAILURE'
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER'
export const AUTH_UPDATE_FAILURE = 'AUTH_UPDATE_FAILURE'
export const AUTH_SIGN_IN_ANONYMOUSLY = 'AUTH_SIGN_IN_ANONYMOUSLY'

export const AUTH_LOG_IN = 'AUTH_LOG_IN'
export const AUTH_LOG_IN_SUCCESS = 'AUTH_LOG_IN_SUCCESS'
export const AUTH_LOG_IN_FAILURE = 'AUTH_LOG_IN_FAILURE'

export const AUTH_SIGN_UP_SUBMIT = 'AUTH_SIGN_UP_SUBMIT'
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS'
export const AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE'

export const AUTH_LOG_OUT = 'AUTH_LOG_OUT'
export const AUTH_LOG_OUT_SUCCESS = 'AUTH_LOG_OUT_SUCCESS'
export const AUTH_LOG_OUT_FAILURE = 'AUTH_LOG_OUT_FAILURE'

export const AUTH_DELETE_ACCOUNT = 'AUTH_DELETE_ACCOUNT'
export const AUTH_DELETE_ACCOUNT_SUCCESS = 'AUTH_DELETE_ACCOUNT_SUCCESS'
export const AUTH_DELETE_ACCOUNT_FAILURE = 'AUTH_DELETE_ACCOUNT_FAILURE'


export interface AuthInitializing {
    type: typeof AUTH_INITIALIZING,
}

export interface AuthFirebaseUpdateUser {
    type: typeof AUTH_FIREBASE_UPDATE_USER,
    firebaseUser: firebase.User
}

export interface AuthFirebaseUpdateUserFailure {
    type: typeof AUTH_FIREBASE_UPDATE_USER_FAILURE,
}
export interface AuthUpdateUser {
    type: typeof AUTH_UPDATE_USER,
    user: UserProxy
}

export interface AuthUpdateUserFailure {
    type: typeof AUTH_UPDATE_FAILURE,
}

export interface AuthSignInAnonymously {
    type: typeof AUTH_SIGN_IN_ANONYMOUSLY,
}

// Log In
export interface AuthLogIn {
    type: typeof AUTH_LOG_OUT,
}

export interface AuthLogInSuccess {
    type: typeof AUTH_LOG_OUT_SUCCESS,
}

export interface AuthLogInFail {
    type: typeof AUTH_LOG_OUT_FAILURE,
    error: string
}

// Sign Up
export interface AuthSignUpSubmit {
    type: typeof AUTH_SIGN_UP_SUBMIT,
}

export interface AuthSignUpSuccess {
    type: typeof AUTH_SIGN_UP_SUCCESS,
}

export interface AuthSignUpFail {
    type: typeof AUTH_SIGN_UP_FAILURE,
    error: string
}

// Log Out
export interface AuthLogOut {
    type: typeof AUTH_LOG_IN,
}

export interface AuthLogOutSuccess {
    type: typeof AUTH_LOG_IN_SUCCESS,
}

export interface AuthLogOutFail {
    type: typeof AUTH_LOG_IN_FAILURE,
    error: string
}

// Log Out
export interface AuthDeleteAccount {
    type: typeof AUTH_DELETE_ACCOUNT,
}

export interface AuthDeleteAccountSuccess {
    type: typeof AUTH_DELETE_ACCOUNT_SUCCESS,
}

export interface AuthDeleteAccountFail {
    type: typeof AUTH_DELETE_ACCOUNT_FAILURE,
    error: string
}

export type AuthActions = AuthInitializing | AuthFirebaseUpdateUser | AuthFirebaseUpdateUserFailure | AuthUpdateUser | AuthUpdateUserFailure | AuthSignInAnonymously | AuthLogIn | AuthLogInSuccess | AuthLogInFail | AuthSignUpSubmit | AuthSignUpSuccess | AuthSignUpFail | AuthLogOut | AuthLogOutFail | AuthLogOutSuccess | AuthDeleteAccount | AuthDeleteAccountFail | AuthDeleteAccountSuccess 