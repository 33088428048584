import { UserAttributesProxy } from '../../sharedModels/UserAttributesProxy'

export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_SET_ATTRIBUTE = 'PROFILE_SET_ATTRIBUTE'

export interface ProfileRequest {
    type: typeof PROFILE_REQUEST,
}

export interface ProfileSuccess {
    type: typeof PROFILE_SUCCESS,
    userAttributes: UserAttributesProxy[]
}

export interface ProfileSetAttribute {
    type: typeof PROFILE_SET_ATTRIBUTE,
    updatedUserAttribute: UserAttributesProxy
}

export type ProfileActions = ProfileSuccess | ProfileRequest | ProfileSetAttribute