import 'bootstrap/dist/css/bootstrap.min.css'
import AppRouter from './Router/index'
import React, { createContext } from 'react'
import firebase from './config/fbConfig'
import { AuthState, useAuth, userContext } from './Authentication/Hook'
let fb = firebase


const App: React.FC = () => {

  let { state } = useAuth()

  return (
    <userContext.Provider value={state}>
      <AppRouter />
    </userContext.Provider>
  )
}

export default App
