import React, { Component } from 'react'
import Rating from 'react-rating'
import MobileStoreButton from 'react-mobile-store-button'
import Fade from 'react-reveal/Fade'

class SleekMobileFirst extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let mobileImageCSS = {
            textAlign: 'center',
            flex: 1
        }

        let emptyCSS = {
            textAlign: 'left',
            flex: 1
        }

        let paragraphCSS = {
            textAlign: 'center',
            margin: 'auto',
            display: 'block',
            width: '50%',
            marginTop: '30px'
        }

        let headerColumnCSS = {
            textAlign: 'right',
            verticalAlign: 'top',
            color: 'white',
            'white-space': 'nowrap'
        }

        let infoColumnCSS = {
            textAlign: 'left',
            paddingLeft: '10px',
            color: '#92969c',
            verticalAlign: 'top'
        }

        let ratingsCSS = {}
        return (
            // stacked items
            <div
                id='mobileFirstContainer'
                style={{
                    background: 'black'
                }}>
                <div className='sleekMobileFirstContainer' style={{}}>
                    <div
                        className='hideOnMobile'
                        style={{
                            flex: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <div
                            style={{
                                position: 'relative',
                                height: '100%',
                                marginTop: '50px',
                                marginBottom: '50px'
                            }}>
                            <Fade bottom>
                                <video
                                    id='vid'
                                    style={{ width: '100%' }}
                                    controls
                                    autoplay
                                    loop
                                    muted
                                >
                                    <source
                                        src='https://firebasestorage.googleapis.com/v0/b/lancelot-fa22c.appspot.com/o/landing%2FbiggerWhite.m4v?alt=media&token=851febca-0b3f-4dd2-935e-e402f2ca0f8a'
                                        type='video/ogg'
                                    />
                                </video>
                            </Fade>
                        </div>
                    </div>

                    <div className='sleekSocialProofTextContainer'>
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}>
                            <p className='sleekMobileFirstTitleHeader'>
                                Surveys with a modern touch
                            </p>
                            <p className='mobileFirstSubtitle'>
                                Through the Eureka app, users can answer interesting survey questions and earn guaranteed payouts at the tip of their fingers.
                                 Making money just got a whole lot easier.
                            </p>
                            <div className='mobileCTA'>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                    <Rating
                                        emptySymbol='fa fa-star-o fa-2x'
                                        fullSymbol='fa fa-star fa-2x'
                                        fractions={4}
                                        initialRating={4.7}
                                        readonly
                                        style={{ color: '#ffd416' }}
                                        className='stars'
                                    />
                                    <p
                                        className='ratingFont'
                                        style={{ color: 'white' }}>
                                        45,000 ratings
                                    </p>
                                </div>

                                {/* link to appstore */}
                                <div className='appstoreCSS'>
                                    <MobileStoreButton
                                        store='ios'
                                        url={
                                            'https://apps.apple.com/us/app/eureka-cash-for-feedback/id1466346433'
                                        }
                                        linkProps={{
                                            title: 'iOS Store Button'
                                        }}
                                        style={{
                                            width: '150px',
                                            height: '50px'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default SleekMobileFirst