import { TransactionProxy } from '../../../sharedModels/TransactionProxy'
import { SweepstakeSurveyProxy } from '../../../sharedModels/SweepstakeSurveyProxy'

// Define all actions that can occur on the UserLookup Component
export const TERMINATES_REQUEST = 'TERMINATES_REQUEST'
export const TERMINATES_SNAPSHOT_UPDATE = 'TERMINATES_SNAPSHOT_UPDATE'


export interface TerminatesRequest {
    type: typeof TERMINATES_REQUEST,
}
export interface TerminatesSnapshotUpdate {
    type: typeof TERMINATES_SNAPSHOT_UPDATE,
    terminates: SweepstakeSurveyProxy[]
}


export type TerminatesActions = TerminatesRequest | TerminatesSnapshotUpdate 