import { OfferwallSurveyActions, OFFERWALL_QUICK_SURVEY_DID_COMPLETE, OFFERWALL_QUICK_SURVEY_DID_UPDATE, OFFERWALL_QUICK_SURVEY_DID_START, OFFERWALL_SURVEY_SUCCESS, OFFERWALL_SURVEY_REQUEST, OFFERWALL_SURVEY_DID_START, OFFERWALL_SURVEY_DID_UPDATE, OFFERWALL_SURVEY_DID_COMPLETE, OFFERWALL_SURVEY_DID_INCOMPLETE } from './Actions'
import { OfferwallState } from './Offerwall'

export const reducer = (state: OfferwallState, action: OfferwallSurveyActions): OfferwallState => {
    switch (action.type) {
        case OFFERWALL_SURVEY_REQUEST:
            return {
                isLoading: true,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
            }
        case OFFERWALL_SURVEY_SUCCESS:
            return {
                isLoading: false,
                quickSurveys: action.quickSurveys,
                sweepstakeSurveys: action.sweepstakeSurveys,
            }
        case OFFERWALL_SURVEY_DID_START:
            return {
                isLoading: false,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
                activeSweepstakeSurvey: action.sweepstakeSurvey
            }
        case OFFERWALL_SURVEY_DID_UPDATE:
            return {
                isLoading: false,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
                activeSweepstakeSurvey: action.sweepstakeSurvey
            }
        case OFFERWALL_SURVEY_DID_COMPLETE:
            return {
                isLoading: false,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
                activeSweepstakeSurvey: action.sweepstakeSurvey
            }
        case OFFERWALL_SURVEY_DID_INCOMPLETE:
            return {
                isLoading: false,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
                activeSweepstakeSurvey: action.sweepstakeSurvey
            }
        case OFFERWALL_QUICK_SURVEY_DID_START:
            return {
                isLoading: false,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
                activeSweepstakeSurvey: state.activeSweepstakeSurvey,
                activeQuickSurvey: action.quickSurvey
            }
        case OFFERWALL_QUICK_SURVEY_DID_UPDATE:
            return {
                isLoading: false,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
                activeSweepstakeSurvey: state.activeSweepstakeSurvey,
                activeQuickSurvey: action.quickSurvey
            }
        case OFFERWALL_QUICK_SURVEY_DID_COMPLETE:
            return {
                isLoading: false,
                quickSurveys: state.quickSurveys,
                sweepstakeSurveys: state.sweepstakeSurveys,
                activeSweepstakeSurvey: state.activeSweepstakeSurvey,
                activeQuickSurvey: action.quickSurvey
            }
    }
}