import firebase from 'firebase'
import { DAILY_POLL_REQUEST, DAILY_POLL_SUBMIT, DAILY_POLL_SUCCESS, DAILY_POLL_SUBMIT_SUCCESS } from './Actions'
import { DailyPollState } from './DailyPoll'
import { reducer } from './Reducer'
import { useState, useReducer, useEffect } from 'react'
import { UserProxy } from '../../sharedModels/UserProxy'
import { DailyPollProxy } from '../../sharedModels/DailyPollProxy'

let initialState: DailyPollState = {
    isLoading: false,
    dailyPoll: undefined,
    incrementBalance: false
}

// Interface that gets returned from the hook. Usually its in an array format, but that makes it harder to type! So that's
// why this exists.
export interface DailyPollHook {
    state: DailyPollState,
    setResponseText: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const useDailyPollHook = (eurekaUser: UserProxy): DailyPollHook => {

    let [responseText, setResponseText] = useState<string>()
    let [state, dispatch] = useReducer(reducer, initialState)
    let { dailyPoll } = state
    let { userId } = eurekaUser

    // Get user attributes
    useEffect(() => {
        let fetchData = async () => {

            dispatch({ type: DAILY_POLL_REQUEST })

            let getDailyPollAPI = firebase.functions().httpsCallable('web-functions-getDailyPoll')
            let results = await getDailyPollAPI({ userId: userId })

            let responseText: string = results.data.responseText
            let dailyPoll = results.data.dailyPoll as DailyPollProxy
            dailyPoll.responseText = responseText

            dispatch({
                type: DAILY_POLL_SUCCESS,
                dailyPoll: dailyPoll
            })
        }

        fetchData()
    }, [])


    // Set user attribute
    useEffect(() => {
        let fetchData = async () => {

            if (responseText === undefined || dailyPoll === undefined) return
            dispatch({
                type: DAILY_POLL_SUBMIT,
                responseText: responseText,
                dailyPollId: dailyPoll.firestoreId
            })
            let submitDailyPollResponseAPI = firebase.functions().httpsCallable('web-functions-submitDailyPollResponse')
            let { firestoreId } = dailyPoll as DailyPollProxy

            // Do not client block on this, optimistically update
            let results = await submitDailyPollResponseAPI({
                userId: userId,
                responseText: responseText,
                dailyPollId: firestoreId
            })
            console.log(results)
            dispatch({
                type: DAILY_POLL_SUBMIT_SUCCESS
            })
        }

        if (responseText !== undefined) {
            fetchData()
        }
    }, [responseText])

    return {
        state: state,
        setResponseText: setResponseText
    }
}
